// FlashcardSession.tsx
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { LearningAppApi } from "src/api/learning-app";
import BackToResources from "src/components/BackToResources";
import Flashcard from "src/components/Flashcard/Flashcard";
import { FaArrowRight, FaCheck } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import Switch from "react-switch";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import ResultsPage from "./ResultsPage";
import EmptyList from "src/components/Shared/EmptyList";
import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
import { LuUndo2 } from "react-icons/lu";
import Tippy from "@tippyjs/react";
import { is } from "date-fns/locale";

export default function FlashcardsSession() {
  const { userId, level, topic, order, topicId } = useParams();

  const [currentAnimation, setCurrentAnimation] = useState("");
  const [userFlashcardProgress, setUserFlashcardProgress] = useState<any>(null);
  const [vocabList, setVocabList] = useState<any>(null);
  const [sessionIndex, setSessionIndex] = useState(0);
  const [isTrackingProgress, setIsTrackingProgress] = useState(true);
  const [isSessionComplete, setIsSessionComplete] = useState(false);
  const [wordsStillLearning, setWordsStillLearning] = useState<any>([]);
  const [isRestartingSession, setIsRestartingSession] = useState(false);
  const [wordsLearned, setWordsLearned] = useState<any>([]);
  const [
    unlearnedWordsFromPreviousSession,
    setUnlearnedWordsFromPreviousSession,
  ] = useState([]);
  const [isSessionReady, setIsSessionReady] = useState(false);
  // New state to track if the current card has been answered.
  const [hasAnswered, setHasAnswered] = useState(false);

  const getTopicVocabList = useCallback(async () => {
    const vocabListResponse = await LearningAppApi.getTopicVocabList(
      topicId,
      userId
    );
    setVocabList(vocabListResponse.data.vocabList);
    setUserFlashcardProgress(vocabListResponse.data.userFlashcardProgress);
  }, [userId, topicId]);

  useEffect(() => {
    getTopicVocabList();
  }, [getTopicVocabList]);

  const getUnlearnedWords = useCallback(() => {
    if (!vocabList || !userFlashcardProgress) return;
    const unlearnedWords = vocabList.filter((item: any) =>
      userFlashcardProgress.some(
        (word: any) => word.wordId === item._id && word.isLearned === false
      )
    );
    setUnlearnedWordsFromPreviousSession(unlearnedWords);
    if (unlearnedWords.length === 0) {
      setIsSessionReady(true);
    }
  }, [vocabList, userFlashcardProgress]);

  useEffect(() => {
    getUnlearnedWords();
  }, [getUnlearnedWords]);

  const handleChangeIsTrackingProgress = () => {
    setIsTrackingProgress(!isTrackingProgress);
  };

  const handlePrevious = () => {
    if (sessionIndex > 0) {
      setSessionIndex(sessionIndex - 1);
      // Optionally: undo any answer for the card you’re leaving.
    }
  };

  const handleNext = () => {
    // Prevent multiple answers on the same card.
    if (hasAnswered) return;
    setHasAnswered(true);

    const currentIndex = sessionIndex;
    setCurrentAnimation("animate-bounce-once");
    const audio = new Audio(`${process.env.REACT_APP_ASSET_CDN}/success.wav`);
    audio.play().catch(() => {
      /* ignore errors */
    });
    setTimeout(() => {
      if (currentIndex < vocabList.length - 1) {
        setSessionIndex(currentIndex + 1);
        // Remove this card from wordsStillLearning if it was marked previously.
        setWordsStillLearning((prev: any[]) =>
          prev.filter((word: any) => word !== vocabList[currentIndex])
        );
      } else {
        setIsSessionComplete(true);
      }
    }, 1000);
  };

  const handleStillLearning = () => {
    // Prevent multiple answers on the same card.
    if (hasAnswered) return;
    setHasAnswered(true);

    const currentIndex = sessionIndex;
    setCurrentAnimation("animate-shake");
    const audio = new Audio(`${process.env.REACT_APP_ASSET_CDN}/error.mp3`);
    audio.play().catch(() => {
      /* ignore errors */
    });
    setWordsStillLearning((prev: any) => [...prev, vocabList[currentIndex]]);
    setTimeout(() => {
      if (currentIndex < vocabList.length - 1) {
        setSessionIndex(currentIndex + 1);
      } else {
        setIsSessionComplete(true);
      }
    }, 1000);
  };

  const handleContinueLearning = () => {
    setVocabList(unlearnedWordsFromPreviousSession);
    setSessionIndex(0);
    setIsSessionComplete(false);
    setIsSessionReady(true);
  };

  const handleStartNewSession = () => {
    setVocabList(vocabList);
    setSessionIndex(0);
    setIsSessionComplete(false);
    setIsSessionReady(true);
  };

  // Recalculate wordsLearned when the session completes.
  useEffect(() => {
    if (isSessionComplete) {
      setWordsLearned(
        vocabList.filter((word: any) => !wordsStillLearning.includes(word))
      );
    }
  }, [isSessionComplete, vocabList, wordsStillLearning]);

  const restartSession = async () => {
    console.log("restart session");
    // Re-fetch the vocab list and flashcard progress from the API,
    // which reflects the just completed session.
    setIsRestartingSession(true);

    await getTopicVocabList();
    getUnlearnedWords();
    setIsSessionReady(false);

    // Reset session-related state to their initial values.
    setSessionIndex(0);
    setIsSessionComplete(false);

    setWordsStillLearning([]);
    setWordsLearned([]);
    setCurrentAnimation("");
    setHasAnswered(false);
    setIsRestartingSession(false);
  };

  // When the sessionIndex changes (new card loads), reset the answer flag.
  useEffect(() => {
    setHasAnswered(false);
  }, [sessionIndex]);

  if (!vocabList || isRestartingSession) {
    return <LoadingBarScreen />;
  }
  if (vocabList.length === 0) {
    return <EmptyList message="Flashcards coming soon" />;
  }

  return (
    <>
      <div>
        <BackToResources isBetaVersion={true} sessionType={"Flashcards"} />
      </div>
      {!isSessionReady && unlearnedWordsFromPreviousSession.length > 0 && (
        <div>
          <h1 className="text-primary font-semibold mt-8">
            You have {unlearnedWordsFromPreviousSession.length} words to review
            from the previous session
          </h1>
          <div className="flex gap-4 mt-4">
            {/* Ask user to continue learning from previous session or start a new session */}
            <button
              className="p-3 border-2 border-primary rounded-lg"
              onClick={handleContinueLearning}
            >
              Continue from previous session
            </button>
            <button
              className="p-3 border-2 border-primary rounded-lg"
              onClick={handleStartNewSession}
            >
              Start a new session
            </button>
          </div>
        </div>
      )}
      {isSessionReady && !isSessionComplete && (
        <>
          <div className="mt-8">
            <Flashcard
              word={vocabList?.[sessionIndex]}
              currentAnimation={currentAnimation}
              setCurrentAnimation={setCurrentAnimation}
            />
          </div>
          <div className="justify-center flex gap-2 mt-8">
            {Array.from({ length: vocabList.length }).map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index === sessionIndex ? "bg-primary" : "bg-gray-300"
                }`}
              ></div>
            ))}
          </div>
          <div className="mt-4 flex gap-6 justify-between">
            {!isTrackingProgress && (
              <div className="flex gap-6">
                <button
                  className={
                    sessionIndex > 0
                      ? "p-3 border-2 border-primary rounded-lg"
                      : "p-3 border-2 border-gray-300 text-gray-300 rounded-lg"
                  }
                  onClick={handlePrevious}
                >
                  <FaArrowLeft />
                </button>
                <p className="py-3 pl-6 w-20">{`${sessionIndex + 1} / ${
                  vocabList.length
                }`}</p>
                <button
                  className="p-3 border-2 border-primary rounded-lg"
                  onClick={handleNext}
                >
                  <FaArrowRight />
                </button>
              </div>
            )}
            {isTrackingProgress && (
              <div className="flex gap-6 mx-auto">
                <Tippy content="Undo" delay={500} placement="top">
                  <button
                    className={
                      sessionIndex > 0
                        ? "p-3 border-2 border-primary rounded-lg"
                        : "p-3 border-2 border-gray-300 text-gray-300 rounded-lg"
                    }
                    onClick={handlePrevious}
                  >
                    <LuUndo2 />
                  </button>
                </Tippy>
                <Tippy content="Still Learning" delay={500} placement="top">
                  <button
                    disabled={hasAnswered}
                    className={`p-3 border-2 text-red-500 border-primary rounded-lg ${
                      hasAnswered ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={handleStillLearning}
                  >
                    <MdClose />
                  </button>
                </Tippy>
                <p className="py-3 pl-6 w-20">{`${sessionIndex + 1} / ${
                  vocabList.length
                }`}</p>
                <Tippy content="Learned" delay={500} placement="top">
                  <button
                    disabled={hasAnswered}
                    className={`p-3 border-2 text-green-500 border-primary rounded-lg ${
                      hasAnswered ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={handleNext}
                  >
                    <FaCheck />
                  </button>
                </Tippy>
              </div>
            )}
          </div>
        </>
      )}
      {isSessionReady && isSessionComplete && (
        <>
          <div>
            <ResultsPage
              wordsLearned={wordsLearned}
              wordsStillLearning={wordsStillLearning}
              restartSession={restartSession}
            />
          </div>
        </>
      )}
    </>
  );
}
