import React from "react";
import clsx from "clsx";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";
import { IDependantInfo } from "src/interfaces/student";

type ParentRewardsProps = {
  dependant: IDependantInfo;
};

export default function DependantRewards({ dependant }: ParentRewardsProps) {
  const { dependantId } = useParams();
  const location = useLocation();
  const [{ showMobileMenu }, dispatch] = useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const dashboardUrl = `${routes.STUDENT_DASHBOARD_REWARDS.url}/${dependant._id}`;
  const isContained = location.pathname.includes(dashboardUrl);

  return (
    <div>
      {" "}
      <NavLink to={`${routes.STUDENT_DASHBOARD_REWARDS.url}/${dependant._id}`}>
        <p
          className={clsx("submenu-inactive", {
            "submenu-active": isContained,
          })}
          onClick={closeMobileMenu}
        >
          {dependant.firstname}
        </p>
      </NavLink>
    </div>
  );
}
