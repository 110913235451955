import { useStateValue } from "src/context/StateProvider";
import MeetTutorCard from "./TutorCard";
import { Tutor } from "src/interfaces/direct-pay";

type TutorCardProps = {
  tutors: Tutor[] | undefined;
};

export default function MeetMatchedTutors({ tutors }: TutorCardProps) {
  const [{ clientIpCountry }] = useStateValue();

  if (!clientIpCountry) {
    return null;
  }

  return (
    <>
      <div className="container mt-6 pb-12 px-3 md:px-8  left-8 right-8">
        <div className="flex gap-6   md:justify-between  flex-wrap justify-center">
          {tutors &&
            tutors.length > 0 &&
            tutors.slice(0, 3).sort((a,b) => b.averageRating - a.averageRating).map((tutor, index) => (
              <div key={tutor._id}>
                <MeetTutorCard tutor={tutor} index={index} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
