import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import assets from "src/assets/assets";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";

export default function Referral() {
	const [{ showMobileMenu }, dispatch] = useStateValue();

	const closeMobileMenu = async () => {
		await dispatch({
			type: "SET_SHOW_MOBILE_MENU",
			showMobileMenu: !showMobileMenu,
		});
	};

	const location = useLocation();

	return (
		<div>
			<NavLink to={routes.STUDENT_REFERRAL.url}>
				<div
					className={clsx(
						{
							"menu-inactive":
								routes.STUDENT_REFERRAL.url !== location.pathname,
						},
						{
							"menu-active": routes.STUDENT_REFERRAL.url === location.pathname,
						}
					)}
					onClick={closeMobileMenu}
				>
					<div className="flex gap-4">
						<div className="flex gap-4 items-center">
							<img src={assets.sReferral} className="w-[30px]" />
							<div className="text-[16px]">{routes.STUDENT_REFERRAL.label}</div>
						</div>
					</div>
				</div>
			</NavLink>
		</div>
	);
}
