import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "src/Routes";
import assets from "src/assets/assets";
import { useStateValue } from "src/context/StateProvider";

export default function Rewards() {
  const [{ userNewRewards, showMobileMenu, user }, dispatch] = useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const location = useLocation();
  return (
    <div>
      <NavLink to={`${routes.STUDENT_DASHBOARD_REWARDS.url}/${user._id}`}>
        <div
          className={clsx(
            {
              "menu-inactive":
                `${routes.STUDENT_DASHBOARD_REWARDS.url}/${user._id}` !==
                location.pathname,
            },
            {
              "menu-active":
                `${routes.STUDENT_DASHBOARD_REWARDS.url}/${user._id}` ===
                location.pathname,
            }
          )}
          onClick={closeMobileMenu}
        >
          <div className="flex gap-4">
            <div className="flex gap-4 items-center">
              <div>
                {/* <GrAchievement className="text-lg" /> */}
                <img src={assets.sRewards} className="w-[30px]" />
              </div>
              <div className="text-[16px]">
                {routes.STUDENT_DASHBOARD_REWARDS.label}
              </div>
            </div>
            {userNewRewards && (
              <div
                className={clsx({
                  "bg-secondary hover:bg-secondary-light text-xxs w-auto px-2.5 py-1 text-white rounded-full flex justify-between":
                    userNewRewards?.length,
                  hidden: !userNewRewards?.length,
                })}
                style={{ width: 25, height: 25, paddingTop: 5 }}
              >
                {userNewRewards?.length}
              </div>
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
}
