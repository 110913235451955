import { useEffect, useState, useContext } from "react";

import moment from "moment-timezone";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "src/Routes";
import { PaymentApi } from "src/api/payment.api";
import { StudentApi } from "src/api/student.api";
import LoadingScreen from "src/components/LottieAnimations/SpinningRose";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import {
  LearningPackageLessonSchedule,
  LessonSchedule,
} from "src/interfaces/direct-pay";
import { updateLessonSchedulesForDpMoreLessons } from "src/utils/dateUtils";
import { sortLp } from "src/utils/helper/sortLearningPackage";
import { TrustpilotProvider } from "src/context/TrustpilotContext";

import { BillingApi } from "src/api/billing.api";
import useCurrency from "src/hooks/useCurrency";
import ChoosePackage from "./ChoosePackage";
import PaymentPlanCard from "./PaymentCard";
import { last } from "lodash";
import Counter from "src/components/Shared/Counter";

type PaymentPageProp = {
  setisPayment?: React.Dispatch<React.SetStateAction<boolean>>;
  proficiencyLevel?: string;
  request?: "old" | "new" | "invoice" | null;
  missingInfo?: boolean;
  handleMissingInfo?: () => void;
};

export interface IPaymentPlans {
  halfLesson?: IPackageType[];
  fullLesson?: IPackageType[];
}

export const lessonDurationOptions = [
  { value: 1, label: "55 minutes", default: false },
  { value: 0.5, label: "25 minutes", default: false },
];

export type IPackageType = {
  _id: string;
  type: string;
  perLessonRateUSD: string;
  perLessonRateCAD: string;
  perLessonRateGBP: string;
  plan: string;
  discount: number;
  transactionAmountUSD: number;
  transactionAmountGBP: number;
  transactionAmountCAD: number;
  numberOfLessons: number;
  lessonType: string;
  promo?: any;
};

export type ILandingPackageType = {
  type: string;
  perLessonRateUSD: string;
  perLessonRateCAD: string;
  perLessonRateGBP: string;
  totalLessonRateUSD: string;
  totalLessonRateCAD: string;
  totalLessonRateGBP: string;
  plan: string;
  discount: number;
  lessonType: string;
};

export default function PaymentPlan({
  setisPayment,
  proficiencyLevel,
  request,
  missingInfo,
  handleMissingInfo,
}: PaymentPageProp) {
  const {
    state: {
      lessonSchedule,
      onBoarding,
      isLoading,
      lessonFrequency,
      isBookingMoreLessons,
    },
    actions: {
      updateOnboarding,
      setLessonSchedule,
      setLessonFrequency,
      setIsLoading,
    },
  } = useContext(DirectPayContext);
  const [{ activeSubscription }] = useStateValue();
  const [{ user }] = useStateValue();
  const [result, setResult] = useState<IPackageType[]>([]);

  const [selectedPlan, setSelectedPlan] = useState<IPackageType>();
  const [recurringDates, setRecurringDates] = useState<LessonSchedule[]>([]);
  const [active, setActive] = useState("full");
  const [paymentPackages, setPaymentPackages] = useState<any>(null);
  const [numberOfUsers, setNumberOfUsers] = useState<any>(
    onBoarding.onboarding?.numberOfUsers?.[0] || 1
  );
  const currency = useCurrency();
  const navigate = useNavigate();

  const handleToggle = () => {
    setActive((prev) => (prev === "full" ? "half" : "full"));
  };

  useEffect(() => {
    setPaymentPackages(null);
    const getPackages = async () => {
      const response = await PaymentApi.getLearningPackages({
        numberOfUsers,
        isD2p: true,
      });
      setPaymentPackages(response.data);
    };
    getPackages();
  }, [numberOfUsers]);

  useEffect(() => {
    if (!paymentPackages) return; // Exit early if paymentPackages is not available

    let sortedArr: any[] = [];

    switch (proficiencyLevel) {
      case "Intermediate or Above":
        const intermediate =
          active === "full"
            ? paymentPackages?.intermediate?.["fullLesson"]
            : paymentPackages?.intermediate?.["halfLesson"];

        if (intermediate) {
          sortedArr = intermediate.sort(sortLp); // First, apply the sortLp function

          // if (request) {
          //   sortedArr = sortedArr.filter(
          //     (lp: IPackageType) =>
          //       lp?.type?.toLowerCase() !== "two taster lessons" &&
          //       !(lp?.type?.toLowerCase() === "monthly package" &&
          //       lp?.promo?.name == "RussTacular")
          //   );
          // }

          // if (!request) {
          //   sortedArr = sortedArr.filter(
          //     (lp: IPackageType) =>
          //       !(
          //         lp?.type?.toLowerCase() === "monthly package" &&
          //         lp?.promo?.name !== "RussTacular"
          //       )
          //   );
          // }

          // sortedArr = sortedArr.filter(
          //   (item: { isDiscounted: any; type: string }) =>
          //     !(
          //       item.isDiscounted === undefined &&
          //       item.type === "Monthly Package"
          //     )
          // );

          // Sort alphabetically by type
          sortedArr = sortedArr.sort((a, b) => {
            if (a.type && b.type) {
              return a.type.toLowerCase().localeCompare(b.type.toLowerCase());
            }
            return 0;
          });
        }
        break;

      default:
        const beginner =
          active === "full"
            ? paymentPackages?.beginner?.["fullLesson"]
            : paymentPackages?.beginner?.["halfLesson"];

        if (beginner) {
          sortedArr = beginner.sort(sortLp); // First, apply the sortLp function
          if (request) {
            sortedArr = sortedArr.filter(
              (lp: IPackageType) =>
                lp?.type?.toLowerCase() !== "two taster lessons"  &&
                lp?.promo?.name !== "RussTacular"
            );
          }

          if (!request) {
            sortedArr = sortedArr.filter(
              (lp: IPackageType) =>
                !(
                  lp?.type?.toLowerCase() === "monthly package" &&
                  lp?.promo?.name !== "RussTacular"
                )
            );
          }

          // sortedArr = sortedArr.filter(
          //   (item: { isDiscounted: any; type: string }) =>
          //     !(
          //       item.isDiscounted === undefined &&
          //       item.type === "Monthly Package"
          //     )
          // );

          // Sort alphabetically by type
          sortedArr = sortedArr.sort((a, b) => {
            if (a.type && b.type) {
              return b.type.toLowerCase().localeCompare(a.type.toLowerCase());
            }
            return 0;
          });
        }
        break;
    }

    setResult(sortedArr);
  }, [active, lessonFrequency, paymentPackages, proficiencyLevel, request]);

  //function to keep date even with daylight savings
  const incrementDateWithSameLocalTime = (
    startDate: string | Date,
    numberOfWeeks: number,
    timezone: string
  ) => {
    let initialDate = moment.tz(startDate, timezone);
    let initialHour = initialDate.hour();

    // Create a new date by adding weeks to the initial date
    let newDate = moment.tz(startDate, timezone).add(numberOfWeeks, "weeks");

    // Set the hour of the new date to match the initial hour, preserving local time
    newDate.hour(initialHour);

    return new Date(newDate.format());
  };
  const getCurrencyDetails = (
    lessonPackage: IPackageType,
    currencyCode: any
  ) => {
    const defaultRate = lessonPackage.perLessonRateUSD;
    const defaultTransaction = lessonPackage.transactionAmountUSD;

    const rates: any = {
      USD: {
        rate: lessonPackage.perLessonRateUSD,
        transaction: lessonPackage.transactionAmountUSD,
      },
      GBP: {
        rate: lessonPackage.perLessonRateGBP,
        transaction: lessonPackage.transactionAmountGBP,
      },
      CAD: {
        rate: lessonPackage.perLessonRateCAD,
        transaction: lessonPackage.transactionAmountCAD,
      },
    };

    return (
      rates[currencyCode] || {
        rate: defaultRate,
        transaction: defaultTransaction,
      }
    );
  };

  const selectPaymentBundle = async (lessonPackage: IPackageType) => {
    try {
      setRecurringDates(() => []);
      setSelectedPlan(lessonPackage);

      let newSchedule: any = [...lessonSchedule];
      let frequencyToUse =
        activeSubscription?.lessonFrequency || lessonFrequency;
      let subSchedule = lessonSchedule;
      if (isBookingMoreLessons) {
        if (activeSubscription?.lessonSchedule) {
          const scheduleByPackage = newRescheduledLessons(
            activeSubscription.lessonSchedule,
            Number(activeSubscription.numberOfLessons)
          );

          const lastLessonScheduleByFrequency =
            activeSubscription.lessonFrequency === 1
              ? scheduleByPackage.slice(-1)
              : scheduleByPackage.slice(-2);
          console.log(
            lastLessonScheduleByFrequency,
            "lastlessonScheduleByFrequency"
          );

          subSchedule = getNextLessonDates(lastLessonScheduleByFrequency);
        }
        setLessonSchedule(subSchedule);
        setLessonFrequency(frequencyToUse);
      }

      newSchedule = subSchedule.map((schedule: any) => ({
        ...schedule,
        duration: activeSubscription?.lessonSchedule
          ? activeSubscription.lessonSchedule.duration
          : active === "full"
          ? { ...lessonDurationOptions[0] }
          : { ...lessonDurationOptions[1] },
      }));
      console.log(newSchedule, "lessons scheudleeeeee");
      setLessonSchedule(newSchedule);

      const scheduleByPackage = newRescheduledLessons(
        newSchedule,
        Number(lessonPackage.numberOfLessons)
      );

      const { rate, transaction } = getCurrencyDetails(
        lessonPackage,
        currency?.currencyCode
      );

      setIsLoading(true);
      const subscription = await BillingApi.createSubscription({
        learningPackageId: lessonPackage._id,
        userId: onBoarding.onboarding?.userId ?? "",
        lessonFrequency,
        lessonSchedule: newSchedule,
        currency: currency?.currencyCode,
      });
      if (subscription) {
        await updateOnboarding(
          {
            learningPackage: {
              discount: lessonPackage.discount,
              lessonType: lessonPackage?.lessonType,
              type: lessonPackage.type,
              currencyCode: !["USD", "GBP", "CAD"].includes(
                currency!.currencyCode
              )
                ? "USD"
                : currency!.currencyCode,
              perLessonRate: rate,
              plan: lessonPackage.plan,
              numberOfLessons: lessonPackage.numberOfLessons,
              transactionAmount: transaction,
            },
            selectedLessonSchedule: [...scheduleByPackage],
          },
          onBoarding.onboarding!._id
        );
        navigate(
          `${routes.CHECKOUT.url}?subscriptionId=${subscription.data._id}`
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "bottom-right",
      });
    }
  };
  function getNextLessonDates(lessonSchedule: any[]) {
    const now = new Date();

    const updatedLessons = lessonSchedule.map((lesson) => {
      const lessonDate = new Date(lesson.date);
      const dayOfWeek = lessonDate.getDay(); // Original lesson weekday
      const lessonTime = lessonDate.getHours() * 60 + lessonDate.getMinutes(); // Minutes in day

      let nextLessonDate = new Date(lessonDate);

      // Move to the next occurrence of the same weekday
      while (nextLessonDate <= now) {
        nextLessonDate.setDate(nextLessonDate.getDate() + 7);
      }

      // Preserve the original time and format it back
      nextLessonDate.setHours(
        lessonDate.getHours(),
        lessonDate.getMinutes(),
        lessonDate.getSeconds(),
        0
      );
      const formattedDate = nextLessonDate.toISOString();

      return {
        date: formattedDate,
        time: formattedDate,
        utc: formattedDate,
        timeZone: lesson.timeZone,
        duration: lesson.duration,
      };
    });

    return updatedLessons;
  }
  function newRescheduledLessons(
    lessonSchedule: Array<LearningPackageLessonSchedule>,
    count: number
  ) {
    const newRescheduledLesson: Array<LearningPackageLessonSchedule> = [];
    let frequencyToUse = activeSubscription?.lessonFrequency || lessonFrequency;
    for (let i = 0; i < count; i++) {
      for (let j = 0; j < frequencyToUse; j++) {
        if (count === newRescheduledLesson.length) {
          break;
        }
        //attach new dates
        const currentSchedule = lessonSchedule[j];

        const newSchedule = {
          ...currentSchedule,
          date: incrementDateWithSameLocalTime(
            currentSchedule.date,
            i,
            currentSchedule.timeZone || ""
          ),
          utc: incrementDateWithSameLocalTime(
            currentSchedule.utc || new Date(),
            i,
            currentSchedule.timeZone || ""
          ),
          fullLessonDate: incrementDateWithSameLocalTime(
            currentSchedule.utc || new Date(),
            i,
            currentSchedule.timeZone || ""
          ),
          time: incrementDateWithSameLocalTime(
            currentSchedule.time || new Date(),
            i,
            currentSchedule.timeZone || ""
          ),
        };
        newRescheduledLesson.push(newSchedule);
      }
    }
    return newRescheduledLesson;
  }

  return (
    <TrustpilotProvider>
      <div>
        {!paymentPackages || !result || isLoading ? (
          <LoadingScreen />
        ) : (
          <div className="text-primary relative">
            {setisPayment && (
              <div className="container">
                <button
                  onClick={() => setisPayment(false)}
                  className="gray-button-2 flex items-center space-x-2 mt-4"
                >
                  <FaChevronLeft className="w-4 h-4" />
                  {/* <span className="hidden lg:block">Back to Tutor</span> */}
                </button>
              </div>
            )}
            {request !== "invoice" && <ChoosePackage request={request} />}
            <div className={`tab-content mt-2`} id="users-count">
              <h3 className="text-secondary font-semibold text-center text-xl mb-2">
                How many learners?
              </h3>
              <Counter
                val={numberOfUsers}
                setValue={setNumberOfUsers}
                max={4}
              />
            </div>
            <div className="container mt-20 px-3 md:px-8 pb-12 left-8 right-8 ">
              <div className="flex justify-center">
                <div>
                  <div className="flex gap-1 justify-center md:justify-start md:gap-6 mx-auto pb-8">
                    <div className="flex gap-2 items-center cursor-pointer">
                      <p
                        className={`
                    ${
                      active === "half" ? "text-secondary" : "text-primary"
                    } font-semibold text-lg md:font-bold md:text-xl  transition-transform duration-300 ease-in-out`}
                      >
                        25 minutes
                      </p>
                    </div>
                    <div className="flex items-center justify-center space-x-2">
                      <div
                        className={`${
                          active === "full" ? "bg-secondary" : "bg-secondary"
                        } relative w-[59px] h-7 md:h-8 rounded-full transition-all duration-300 py-[2px]`}
                        onClick={handleToggle}
                      >
                        <div
                          className={`${
                            active === "full"
                              ? "translate-x-full"
                              : "translate-x-0"
                          } absolute left-0 w-1/2 h-[24px] md:h-[28px] bg-white rounded-full transition-transform duration-300 ease-in-out`}
                        ></div>
                      </div>
                    </div>
                    <div className="flex gap-2 items-center cursor-pointer">
                      <p
                        className={`
                    ${
                      active === "full" ? "text-secondary" : "text-primary"
                    } font-semibold text-lg md:font-bold md:text-xl  transition-transform duration-300 ease-in-out`}
                      >
                        55 minutes
                      </p>
                    </div>
                  </div>

                  <div
                    className={`items-start grid grid-cols-1 sm:grid-cols-2  gap-7  md:gap-5  
                       ${request ? " xl:grid-cols-3 " : " xl:grid-cols-3 "}
                    `}
                  >
                    {result
                      ?.filter(
                        (p) => p?.type !== "Beginner & Elementary Package"
                      )
                      ?.map((payment: any, index: any) => (
                        <PaymentPlanCard
                          paymentPlans={payment}
                          selectPaymentBundle={selectPaymentBundle}
                          selected={selectedPlan?.type === payment.type}
                          key={index}
                          missingInfo={missingInfo}
                          handleMissingInfo={handleMissingInfo}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </TrustpilotProvider>
  );
}
