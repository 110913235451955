import React from "react";
import { BsShieldShaded } from "react-icons/bs";
import { FaBriefcase } from "react-icons/fa";
import StarRatings from "react-star-ratings";
import { ITutorSearchResult } from "src/interfaces/tutor";
import { constants } from "src/static-data/contants";
import VettedByTopsetBadge from "./VettedByTopsetBadge";

type CredentialStatsType = {
  tutorProfile: ITutorSearchResult;
  ratings: any;
  tutorRating : number | undefined
};

export default function CredentialStats({
  tutorProfile,
  ratings,
  tutorRating
}: CredentialStatsType) {
  const { MIN_NUMBER_OF_REVIEWS } = constants;
  return (
    <div className="flex flex-col justify-between">
      <div>
        <h3 className="text-primary text-xl md:text-2xl font-bold">{`${
          tutorProfile?.firstname
        } ${tutorProfile?.lastname.charAt(0)}`}</h3>
        <p className="text-primary font-semibold text-sm">
          {tutorProfile?.designation}
        </p>
        {ratings?.numberOfRatings > MIN_NUMBER_OF_REVIEWS ? (
          <div>
            <div className="flex gap-2 items-center">
              <StarRatings
                rating={tutorRating ?? 0}
                starHoverColor="#FEC108"
                starRatedColor="#FEC108"
                numberOfStars={5}
                name="rating"
                starDimension="20px"
                starSpacing="1px"
              />
              <p className="text-sm text-primary font-bold">
                {tutorRating?.toFixed(1)}
              </p>

              <p className="text-sm text-primary">{`(Based on ${ratings?.numberOfRatings} reviews)`}</p>
            </div>
            <div className="flex gap-3 items-center mt-1">
              <FaBriefcase className="text-light-purple-light" size={13} />{" "}
              <p className="text-primary font-semibold text-xxs md:text-sm mt-[2px]">
                {ratings?.completedLessons}{" "}
                <span className="font-normal">Completed Lessons</span>
              </p>
            </div>
          </div>
        ) : (
          <div className="flex gap-1 items-center mt-8">
            <BsShieldShaded className="text-secondary" size={13} />{" "}
            <p className="text-primary font-semibold text-xxs mt-[2px]">
              <span className="font-semibold">Lingawa Guarantee</span>
            </p>
          </div>
        )}
      </div>
      <div className="mt-4">
        <VettedByTopsetBadge />
      </div>
      <div className="text-secondary font-semibold text-xs mb-8 md:mb-0 pt-4 md:mt-2 md:pt-0 md:max-w-[430px]">
        Choose a date and time for your first lesson and embark on a path to
        discovery and growth.{" "}
      </div>
    </div>
  );
}
