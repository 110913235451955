import { triggerFacebookLeadAdEvent, triggerFacebookViewPageAdEvent } from "src/server/endpoints/facebookAd";
import { getTeacherRefferalDetails, getTeacherRefferalStatistics, postTeacherRefferalDetails } from "src/server/endpoints/teacher-referrer";

export class TeacherRefferalApi {
    static getTeacherRefferalDetails(referrerId?: string): Promise<any> {
        return getTeacherRefferalDetails(referrerId);
    }
    static postTeacherRefferalDetails(referrerId: string, data: any): Promise<any> {
        return postTeacherRefferalDetails(referrerId, data);
    }
    static getTeacherRefferalStatistics(referrerId?: string): Promise<any> {
        return getTeacherRefferalStatistics(referrerId);
    }
}