export const adjustPackagePriceByLessonFrequency = (
	lessonFrequency: number,
	learningPackageAmount?: number,
	learningPackageType?: string
) => {
	if (!learningPackageAmount) return;
	return learningPackageType === "Monthly Package"
		? learningPackageAmount * lessonFrequency
		: learningPackageAmount;
};

export function copyToClipboard(text: string, toast: any) {
	navigator.clipboard.writeText(text).then(
		function () {
			toast.success("Referral code copied to clipboard", {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		},
		function (err) {
			console.error("Could not copy text: ", err);
		}
	);
}

export function capitalizeFirstCharacter(str: string) {
	return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export function isVideoFile(file: File | string) {
	const allowedExtensions = [
		".mp4",
		".mov",
		".avi",
		".flv",
		".webm",
		".mkv",
		".gif",
		".3gp",
		".3g2",
	];
	let fileExtension;
	if (typeof file === "object") {
		fileExtension = file?.name
			?.toLowerCase()
			.slice(file?.name.lastIndexOf("."));
	} else {
		fileExtension = file?.toLowerCase().slice(file.lastIndexOf("."));
	}
	return allowedExtensions.includes(fileExtension);
}

const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // 100MB

export const isVideoLessThanLimit = (file: File) => {
	if (file && file.size > MAX_VIDEO_SIZE) {
		return false;
	}
	return true;
};

export function generateRandomHash(length: number): string {
	if (!length) length = 20;

	let result: string = "";
	const characters: string =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength: number = characters.length;

	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
}

export function assertStripeError(error: any, Swal: any) {
	switch (error.code) {
		case "generic_decline":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card was declined. Please try another card",
			});
			break;
		case "incomplete":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card details are incomplete. Please check and try again",
			});
			break;
		case "incomplete_number":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card number is incomplete. Please check and try again",
			});
			break;
		case "incomplete_cvc":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card CVC is incomplete. Please check and try again",
			});
			break;
		case "invalid_expiry_year_past":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card expiry year is in the past. Please try another card",
			});
			break;
		case "incomplete_expiry":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card expiry date is incomplete. Please check and try again",
			});
			break;
		case "incorrect_number":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card number is incorrect. Please check and try again",
			});
			break;
		case "expired_card":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card has expired. Please use another card",
			});
			break;
		case "incorrect_cvc":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card CVC is incorrect. Please check and try again",
			});
			break;
		case "incorrect_zip":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card zip code is incorrect. Please check and try again",
			});
			break;
		case "card_declined":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card was declined. Please try another card",
			});
			break;
		case "processing_error":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "An error occurred while processing your payment. Please try again later",
			});
			break;
		case "invalid_number":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Invalid card number. Please check and try again.",
			});
			break;
		case "invalid_expiry_month":
		case "invalid_expiry_year":
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Your card has expired. Please use a different card.",
			});
			break;
		default:
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error.code,
			});
			break;
	}
}
