import React, { useEffect } from "react";
import { HiOutlineArrowRight, HiOutlineLockClosed } from "react-icons/hi";
import { formatDistanceToNow } from "date-fns";
import { ReactComponent as Book } from "src/assets/icons/book.svg";
import { Topic } from "src/interfaces/resources";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "src/Routes";
import clsx from "clsx";
import { useStateValue } from "src/context/StateProvider";
import Swal from "sweetalert2";
interface ICard {
  topic: Topic;
  practice?: boolean;
  level?: string;
  topicId?: string;
  curriculumId?: string;
  parentDivisionId?: string;
  activeResource?: string;
}
export default function Card({
  topic,
  practice,
  level,
  topicId,
  parentDivisionId,
  curriculumId,
  activeResource,
}: ICard) {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { userId } = useParams();
  const [colorTheme, setColorTheme] = React.useState<string>("");
  const [{ user }] = useStateValue();

  const route =
    user?.type === "student"
      ? routes.STUDENT_DASHBOARD_LEARN.url
      : routes.TUTOR_DASHBOARD_LEARN.url;
  const handleClick = () => {
    if (topic?.isLocked) {
      Swal.fire({
        color: "#340d24",
        title: `<strong>Topic locked</strong>`,
        html: `
                <p>
                More topics are unlocked when your Tutor provides feedback on your next Lesson!
                </p>
                `,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `
                   Book a lesson now!
                `,
        confirmButtonColor: "#D33479",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(routes.STUDENT_DASHBOARD_BOOKINGS.url);
        }
      });
    } else {
      navigate(
        `${route}/${userId}/${level}/${topic.order}/${topic.title}/${topicId}`,
        {
          state: { curriculum: state?.curriculum, ageGroup: activeResource },
        }
      );
    }
  };

  useEffect(() => {
    const color =
      level === "Elementary"
        ? "lingawa-teal"
        : level === "Beginner"
        ? "lingawa-orange"
        : level === "Intermediate"
        ? "lingawa-primary"
        : level === "Special"
        ? "pink-500"
        : "lingawa-teal";

    setColorTheme(color);
  }, [level]);

  console.log("colorTheme", colorTheme);

  return (
    <div
      // className={`w-full border-[1px] border-b-4 rounded-xl hover:border-${colorTheme} py-2 px-3 cursor-pointer  transition-all duration-200 flex flex-col justify-between ${
      //   topic.isLocked && "pointer-events-none"
      // }`}
      className={clsx({
        "pointer-events-none w-full border-[1px] border-b-4 rounded-xl  py-2 px-3 cursor-pointer  transition-all duration-200 flex flex-col justify-between":
          topic.isLocked,
        "w-full border-[1px] border-b-4 rounded-xl hover:border-lingawa-teal hover:bg-pale-teal py-2 px-3 cursor-pointer  transition-all duration-200 flex flex-col justify-between":
          level === "Elementary" && !topic.isLocked,
        "w-full border-[1px] border-b-4 rounded-xl hover:border-lingawa-orange hover:bg-pale-orange py-2 px-3 cursor-pointer  transition-all duration-200 flex flex-col justify-between":
          level === "Beginner" && !topic.isLocked,
        "w-full border-[1px] border-b-4 rounded-xl hover:border-lingawa-primary hover:bg-pale-primary py-2 px-3 cursor-pointer  transition-all duration-200 flex flex-col justify-between":
          level === "Intermediate" && !topic.isLocked,
        "w-full border-[1px] border-b-4 rounded-xl hover:border-pink-500 hover:bg-pink-100 py-2 px-3 cursor-pointer  transition-all duration-200 flex flex-col justify-between":
          level === "Special" && !topic.isLocked,
      })}
      onClick={handleClick}
    >
      <div className="flex gap-2 items-center justify-between">
        {/* Image container: make it non-shrinkable */}
        <div className="flex-none">
          {!topic.isLocked && (
            <img
              src={
                topic?.imageUrl ||
                `${process.env.REACT_APP_ASSET_CDN}/lingawa-icon.png`
              }
              alt={topic.title}
              className={clsx(
                "h-10 w-10 object-cover border-2 rounded-xl", // Fixed size
                {
                  "border-gray-300": topic.isLocked,
                  "border-lingawa-teal":
                    level === "Elementary" && !topic.isLocked,
                  "border-lingawa-orange":
                    level === "Beginner" && !topic.isLocked,
                  "border-lingawa-primary":
                    level === "Intermediate" && !topic.isLocked,
                  "border-pink-500": level === "Special" && !topic.isLocked,
                }
              )}
            />
          )}
          {topic.isLocked && (
            <button className="h-10 w-10 border-2 rounded-xl flex items-center justify-center">
              <HiOutlineLockClosed className={`text-${colorTheme}`} />
            </button>
          )}
        </div>

        {/* Title container: allow it to grow */}
        <h2 className="flex-1 font-semibold text-center md:text-sm">
          {`${topic.order}. ${topic.title}`}
        </h2>
      </div>
      {/* {practice ? (
        <button className="flex gap-2 items-center rounded-[9px] bg-primary-pale py-3 px-2">
          <Book />
          <p className="text-primary font-medium">Practice Lesson</p>
        </button>
      ) : (
        <button className="border rounded-[9px] p-2 self-start">
          {topic?.isLocked ? <HiOutlineLockClosed /> : <HiOutlineArrowRight />}
        </button>
      )} */}
    </div>
  );
}

function LastPracticedTime({ lastPracticedDate, practice }: any) {
  if (!lastPracticedDate)
    return (
      <p
        className={`${
          practice ? "text-primary-gray" : "text-secondary"
        } font-medium `}
      >
        Practice!
      </p>
    );

  const timeAgo = formatDistanceToNow(new Date(lastPracticedDate), {
    addSuffix: true,
  });
  return (
    <p
      className={`${
        practice ? "text-primary-gray" : "text-secondary"
      } font-medium `}
    >
      Practiced {timeAgo}
    </p>
  );
}
