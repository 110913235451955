import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "src/Routes";

interface IChatBubble {
  children: ReactNode;
}
const ChatBubble = ({ children }: IChatBubble) => {
  const { pathname } = useLocation();
  const isD2P = pathname.includes(routes.DIRECT_TO_PAY_WELCOME.url);
  return (
    <div
      className={`${
        isD2P ? "block" : "hidden"
      } z-50 fixed bottom-8 right-8 bg-green-500 rounded-full w-10 h-10 flex justify-center items-center cursor-pointer shadow-xl`}
    >
      {children}
    </div>
  );
};

export default ChatBubble;
