export const ageGroupOptions = [
    {
        label: "Child (5-12)",
        value: "Child",
    },
    {
        label: "Teen (13-18)",
        value: "Teen",
    },
    {
        label: "Adult (18-Above)",
        value: "Adult",
    },
];

export const levelOptions = [
    {
        label: "Level 1: Beginner",
        value: "1",
    },
    {
        label: "Level 2: Elementary",
        value: "2",
    },
    {
        label: "Level 3: Intermediate",
        value: "3",
    },
];

export const partsOfSpeechOptions = [
    {
        label: "Noun",
        value: "Noun",
    },
    {
        label: "Verb",
        value: "Verb",
    },
    {
        label: "Adjective",
        value: "Adjective",
    },
    {
        label: "Adverb",
        value: "Adverb",
    },
    {
        label: "Pronoun",
        value: "Pronoun",
    },
    {
        label: "Other",
        value: "Other",
    },
];

export const yorubaChlidTopicOptions = [
    {
        "label": "1. Greetings",
        "value": "Greetings"
    },
    {
        "label": "2. Introductions",
        "value": "Introductions"
    },
    {
        "label": "3. Manners",
        "value": "Manners"
    },
    {
        "label": "4. Core Family",
        "value": "Core Family"
    },
    {
        "label": "5. Close Family",
        "value": "Close Family"
    },
    {
        "label": "6. Friends",
        "value": "Friends"
    },
    {
        "label": "7. School",
        "value": "School"
    },
    {
        "label": "8. Around the house",
        "value": "Around the house"
    },
    {
        "label": "9. Colours",
        "value": "Colours"
    },
    {
        "label": "10. Clothing",
        "value": "Clothing"
    },
    {
        "label": "11. Numbers",
        "value": "Numbers"
    },
    {
        "label": "12. Parts of the face",
        "value": "Parts of the face"
    },
    {
        "label": "13. Food",
        "value": "Food"
    },
    {
        "label": "14. Animals",
        "value": "Animals"
    },
    {
        "label": "15. Hobbies",
        "value": "Hobbies"
    },
]

export const igboChlidTopicOptions = [
    {
        "label": "1. Introduction & Manners",
        "value": "Introduction & Manners"
    },
    {
        "label": "2. Greetings",
        "value": "Greetings"
    },
    {
        "label": "3. Introductions",
        "value": "Introductions"
    },
    {
        "label": "4. Emotions",
        "value": "Emotions"
    },
    {
        "label": "5. Core Family",
        "value": "Core Family"
    },
    {
        "label": "6. Close Family",
        "value": "Close Family"
    },
    {
        "label": "7. Friends",
        "value": "Friends"
    },
    {
        "label": "8. School",
        "value": "School"
    },
    {
        "label": "9. Around the house",
        "value": "Around the house"
    },
    {
        "label": "10. Colours",
        "value": "Colours"
    },
    {
        "label": "11. Clothing",
        "value": "Clothing"
    },
    {
        "label": "12. Numbers",
        "value": "Numbers"
    },
    {
        "label": "13. Parts of the face",
        "value": "Parts of the face"
    },
    {
        "label": "14. Food",
        "value": "Food"
    },
    {
        "label": "15. Animals",
        "value": "Animals"
    },
    {
        "label": "16. Daily Activities",
        "value": "Daily Activities"
    },
]

// topicOptions.sort((a, b) => {
//     // Convert both labels to lowercase for case-insensitive sorting
//     const labelA = a.label.toLowerCase();
//     const labelB = b.label.toLowerCase();

//     if (labelA < labelB) {
//         return -1; // If labelA should come before labelB, return -1
//     }
//     if (labelA > labelB) {
//         return 1; // If labelA should come after labelB, return 1
//     }
//     return 0; // If labels are equal, return 0
// });