// CheckpointComponent.tsx
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import * as animatedCheckmark from "src/assets/animations/animated-checkmark.json";

interface CheckpointComponentProps {
  visible: boolean; // show/hide the overlay
  onSaveAndClose: () => Promise<void>;
  onClose: () => void; // called when user clicks "Continue"
  message?: string;
  score?: number;
}

const CheckpointComponent: React.FC<CheckpointComponentProps> = ({
  visible,
  onSaveAndClose,
  onClose,
  message = "Checkpoint Reached!",
  score,
}) => {
  // track save status
  const [isSaving, setIsSaving] = useState<boolean>(true);

  useEffect(() => {
    if (!visible) return;

    // 1) Play checkpoint audio
    const audio = new Audio(
      `${process.env.REACT_APP_ASSET_CDN}/checkpoint.mp3`
    );
    audio.play().catch(() => {});

    // 2) Immediately call onSaveAndClose() to auto-save
    setIsSaving(true);
    onSaveAndClose()
      .then(() => {
        console.log("Checkpoint auto-saved successfully");
      })
      .catch((err) => {
        console.error("Failed to auto-save checkpoint", err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [visible]);

  // Prevent body scrolling while checkpoint is visible
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  // If not visible, don't render
  if (!visible) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 -mt-4 flex flex-col items-center justify-center bg-black bg-opacity-50">
      {/* <Confetti /> */}

      <div className="bg-white md:w-[450px] p-6 rounded-lg shadow-lg text-center">
        <div className="flex justify-center">
          <Lottie
            animationData={animatedCheckmark}
            // loop={false}
            style={{
              height: 100,
              width: 110,
            }}
          />
        </div>
        <h2 className="text-2xl font-bold text-lingawa-primary mb-2">
          {message}
        </h2>
        {/* {score !== undefined && (
          <p className="text-gray-700 mb-4">Your current score: {score}</p>
        )} */}

        {isSaving ? (
          <p className="text-sm text-gray-600 mb-4">
            Saving your progress automatically...
          </p>
        ) : (
          <p className="text-sm text-gray-600 mb-4">Progress saved!</p>
        )}

        {/* "Continue" button for the user to manually close */}
        <button
          onClick={onClose}
          className="bg-orange-600 border-[2px] border-primary border-l-4 border-b-4 font-semibold text-white px-6 py-2 rounded-xl hover:bg-orange-700"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default CheckpointComponent;
