import axios from "axios";
import {
	IAutoBookLesson,
	LessonRewardReq,
	SendLessonFeedback,
} from "src/interfaces/lesson";
import {
	autoBookLesson,
	getGroupLessonFeedbackLink,
	getGroupLessonInstances,
	getLesson,
	getLessonResources,
	getLessonRewards,
	getLessonRewardsByLesson,
	getStudentLessonDetails,
	getStudentLessonFeedback,
	getStudentNewRewards,
	getTutorLessonFeedback,
	getTutorZoomLink,
	getVirtualImmersions,
	markFeedbackAsRead,
	postAssignLessonReward,
	postBulkAssignLessonReward,
	postCancelStudentInVirtualImmersion,
	postEnrollStudentInVirtualImmersion,
	postLessonViaAdminRequest,
	postLessonViaAdminRequestNoAuth,
	postRescheduleConfirmedLessonViaAdmin,
	postRescheduleGroupLessonViaAdmin,
	postStudentLessonFeedback,
	postStudentLessonFeedbackComment,
	rescheduleDpLessons,
	sendFeedback,
	updateLessonProgress,
} from "src/server/endpoints/lesson";

export class LessonApi {
	static postLessonViaAdminRequestNoAuth(payload: any): Promise<any> {
		return postLessonViaAdminRequestNoAuth(payload);
	}
	static postLessonViaAdminRequest(payload: any): Promise<any> {
		return postLessonViaAdminRequest(payload);
	}
	static postAssignLessonReward(reward: any, state: any): Promise<any> {
		return postAssignLessonReward(reward, state);
	}
	static postBulkAssignLessonReward(rewards: LessonRewardReq[]): Promise<any> {
		return postBulkAssignLessonReward(rewards);
	}
	static getLessonRewardsByLesson(lessonId: any): Promise<any> {
		return getLessonRewardsByLesson(lessonId);
	}
	static postEnrollStudentInVirtualImmersion(payload: any): Promise<any> {
		return postEnrollStudentInVirtualImmersion(payload);
	}
	static postCancelStudentInVirtualImmersion(payload: any): Promise<any> {
		return postCancelStudentInVirtualImmersion(payload);
	}
	static postRescheduleConfirmedLessonViaAdmin(
		newSchedule: any,
		confirmedLessonrequestId: string,
		user?: any
	): Promise<any> {
		return postRescheduleConfirmedLessonViaAdmin(
			newSchedule,
			confirmedLessonrequestId,
			user
		);
	}
	static postRescheduleGroupLessonViaAdmin(
		newSchedule: any,
		confirmedLessonrequestId: string,
		user?: any
	): Promise<any> {
		return postRescheduleGroupLessonViaAdmin(
			newSchedule,
			confirmedLessonrequestId,
			user
		);
	}
	static getTutorZoomLink(
		tutorId: string,
		groupLessonId: string,
		user?: any,
		lesson?: any
	): Promise<any> {
		return getTutorZoomLink(tutorId, groupLessonId, user, lesson);
	}
	static getGroupLessonFeedbackLink(
		lessonId: string,
		url?: string
	): Promise<any> {
		return getGroupLessonFeedbackLink(lessonId, url);
	}
	static getGroupLessonInstances(groupLessonId: string): Promise<any> {
		return getGroupLessonInstances(groupLessonId);
	}
	static sendLessonFeedback(body: SendLessonFeedback): Promise<any> {
		return sendFeedback(body);
	}
	static getLesson(lessonId?: string): Promise<any> {
		return getLesson(lessonId);
	}
	static getVirtualImmersions(subject: string, userId: string): Promise<any> {
		return getVirtualImmersions(subject, userId);
	}
	static postStudentLessonFeedback(
		lessonId: string,
		rating: number,
		lesson?: any
	): Promise<any> {
		return postStudentLessonFeedback(lessonId, rating, lesson);
	}
	static postStudentLessonFeedbackComment(
		lessonId: string,
		lesson?: any,
		comment?: string
	): Promise<any> {
		return postStudentLessonFeedbackComment(lessonId, lesson, comment);
	}
	static getStudentLessonFeedback(studentId: string): Promise<any> {
		return getStudentLessonFeedback(studentId);
	}
	static getStudentLessonDetails(studentId: string): Promise<any> {
		return getStudentLessonDetails(studentId);
	}
	static getStudentNewRewards(studentId: string): Promise<any> {
		return getStudentNewRewards(studentId);
	}
	static getLessonRewards(): Promise<any> {
		return getLessonRewards();
	}
	static getTutorLessonFeedback(tutorId: string): Promise<any> {
		return getTutorLessonFeedback(tutorId);
	}
	static markFeedbackAsRead(lessonId: string): Promise<any> {
		return markFeedbackAsRead(lessonId);
	}
	static autoBookLesson(body: IAutoBookLesson): Promise<any> {
		return autoBookLesson(body);
	}
	static rescheduleDpLessons(
		studentId: string,
		subscriptionId: string
	): Promise<any> {
		return rescheduleDpLessons(studentId, subscriptionId);
	}
	static updateLessonProgress(studentId: string, body: any): Promise<any> {
		return updateLessonProgress(studentId, body);
	}
	static getLessonResources(params: {
		studentId?: string;
		tutorId?: string;
	}): Promise<any> {
		return getLessonResources(params);
	}

	static async getTopics(studentId: string): Promise<any | undefined[]> {
		try {
			let response = await axios.get(
				`${process.env.REACT_APP_V2_SERVER_URL}/lessons/most-recent-topic-unlocked-and-next/${studentId}`
			);
			return response.data;
		} catch (error) {
			console.log("caught error: ", error);
			throw error;
		}
	}
}
