import Header from "src/layouts/DashboardHeader";
import Wordsfilter from "./WordsFilter";
import { useState } from "react";
import { ageGroupOptions } from "./data/selectOptions";
import { WordRecord, getRandomWords } from "./data/getWords";
import WordList from "./WordList";
import { LearningAppApi } from "src/api/learning-app";
import { useParams } from "react-router-dom";

export default function WordGenerator() {
  const { languageId } = useParams();
  const [ageGroup, setAgeGroup] = useState(ageGroupOptions[0].value);
  const [level, setLevel] = useState("1");
  const [topics, setTopics] = useState<string[]>([]);
  const [numberOfWords, setNumberOfWords] = useState(3);
  const [partsOfSpeech, setPartsOfSpeech] = useState<string[]>([]);
  const [words, setWords] = useState<WordRecord[] | null>(null);

  const handleGetWords = async () => {
    const response = await LearningAppApi.getRandomWords(
      ageGroup,
      partsOfSpeech,
      parseInt(level),
      numberOfWords,
      topics,
      languageId
    );
    setWords(response.data);
  };

  return (
    <>
      <Header />
      <div className="p-4">
        <WordList words={words} />
      </div>
      <div className="p-4">
        <Wordsfilter
          language={languageId}
          ageGroup={ageGroup}
          setAgeGroup={setAgeGroup}
          level={level}
          setLevel={setLevel}
          partsOfSpeech={partsOfSpeech}
          setPartsOfSpeech={setPartsOfSpeech}
          topics={topics}
          setTopics={setTopics}
          numberOfWords={numberOfWords}
          setNumberOfWords={setNumberOfWords}
          handleGetWords={handleGetWords}
        />
      </div>
    </>
  );
}
