import React, { useState, useEffect } from "react";
import { FlashcardProps } from "./types";
import { HiMiniSpeakerWave } from "react-icons/hi2";

export default function Flashcard({
  word,
  currentAnimation,
  setCurrentAnimation,
}: FlashcardProps) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [justLoaded, setJustLoaded] = useState(false); // controls fade-in

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    setIsFlipped(false);
    setCurrentAnimation("");
    setJustLoaded(true);
    const timer = setTimeout(() => {
      setJustLoaded(false);
    }, 700);
    return () => clearTimeout(timer);
  }, [word]);

  useEffect(() => {
    if (isFlipped && word.audioUrl) {
      const audio = new Audio(word.audioUrl);
      audio.play();
    }
  }, [isFlipped]);

  // Return the border color class based on success/failure animation
  const getBorderColor = () => {
    if (currentAnimation === "animate-bounce-once") {
      return "border-4 border-green-400 rounded-2xl";
    }
    if (currentAnimation === "animate-shake") {
      return "border-4 border-red-400 rounded-2xl";
    }
    return "";
  };

  console.log("word", word);

  return (
    <div className="perspective">
      <div
        className={`
          large_flashcard  ${isFlipped ? "flipped" : ""}
          ${getBorderColor()}
          ${justLoaded ? "animate-fade-in" : ""}
        `}
        onClick={handleFlip}
      >
        <div className="front bg-lingawa-primary p-6 shadow-lg rounded-lg cursor-pointer">
          {word.imageUrl ? (
            <div className="grid grid-cols-1 md:grid-cols-2 h-full grid-rows-[1fr_1fr] md:grid-rows-1">
              <div className="order-2 md:order-1 flex items-center justify-center pr-2">
                <p className="text-white font-semibold md:text-3xl text-xl text-center">
                  {word.englishTranslation}
                </p>
              </div>
              <div className="order-1 md:order-2 flex items-center justify-center overflow-hidden">
                <img
                  src={word.imageUrl}
                  alt={word.word}
                  className="w-full h-full object-contain rounded-lg"
                />
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-white font-semibold md:text-3xl text-xl">
                {word.englishTranslation}
              </p>
            </div>
          )}
        </div>
        <div className="back bg-lingawa-primary flex-col p-6 shadow-lg rounded-lg cursor-pointer relative">
          {word.audioUrl && (
            <HiMiniSpeakerWave
              className="text-white cursor-pointer absolute top-6 right-6 transition-transform transform hover:scale-110 hover:text-lingawa-teal"
              onClick={(e) => {
                e.stopPropagation(); // Prevents the card flip
                const audio = new Audio(word.audioUrl);
                audio.play();
              }}
              size={25}
            />
          )}
          <p
            className={`text-white font-semibold md:text-3xl text-xl ${currentAnimation}`}
          >
            {word.word}
          </p>
        </div>
      </div>
    </div>
  );
}
