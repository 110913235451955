// RemoveWordModal.tsx
import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";

interface RemoveWordModalProps {
  visible: boolean; // show/hide the modal
  onConfirm: () => void; // user confirms => remove word
  onCancel: () => void; // user cancels => close modal
  wordId?: string | null; // optional: show which word is being removed
}

const RemoveWordModal: React.FC<RemoveWordModalProps> = ({
  visible,
  onConfirm,
  onCancel,
  wordId,
}) => {
  if (!visible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="bg-white min-h-[300px] p-6 rounded-xl shadow-lg"
        style={{
          backgroundImage: `url("${process.env.REACT_APP_ASSET_CDN}/popup-gb.png")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex mt-16 gap-6 mx-auto justify-center">
          <div>
            <img
              src={`${process.env.REACT_APP_ASSET_CDN}/already-know-this-image.png`}
              alt="Remove word"
              className="md:h-20"
            />
          </div>
          <div className="w-[150px] md:w-[300px]">
            <h2 className="font-semibold text-lingawa-primary">
              This word will be marked as known and removed from the rest of the
              session.
            </h2>
            <p className="mb-4 text-xs mt-2 text-gray-500 ">
              Would you like to proceed?
            </p>
          </div>
        </div>

        <div className="flex items-center justify-end space-x-4 mt-4">
          <button
            onClick={onCancel}
            className="bg-white flex gap-2 items-center font-semibold text-lg text-slate-700 px-4 py-2 border-[1px] border-l-4 border-b-4 border-gray-400 rounded-lg hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-orange-600 flex gap-2 items-center font-semibold text-lg text-white px-4 py-2 border-[1px] border-l-4 border-b-4 border-lingawa-primary rounded-lg hover:bg-orange-700"
          >
            Confirm
            <FaRegCheckCircle size={23} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveWordModal;
