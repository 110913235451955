import clsx from "clsx";
import { useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { FaUserCircle } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";

export default function ProfileIcon() {
	const [{ user }] = useStateValue();
	const navigate = useNavigate();
	const [showMenu, setShowMenu] = useState(false);
	const [avatar, setAvatar] = useState("");

	const handleShowMenu = () => {
		setShowMenu(!showMenu);
	};

	const handleHideMenu = () => {
		setShowMenu(false);
	};

	const handleLogout = () => {
		window.localStorage.removeItem("accessToken");
		window.open(`${process.env.REACT_APP_SERVER_URL}/logout`, "_self");
	};

	const handleNavigateToProfile = () => {
		if (user.type === "student") {
			if (!user?.phone_number) {
				navigate(routes.STUDENT_DASHBOARD_BIO.url);
			} else {
				navigate(routes.STUDENT_DASHBOARD_OVERVIEW.url);
			}
		}
		if (user.type === "tutor") {
			navigate(routes.TUTOR_DASHBOARD_UPCOMING_LESSONS.url);
		}
		if (user.type === "teacher_referrer") {
			navigate(routes.TEACHER_REFERRAL_PROGRAM_HOME.url);
		}
	};

	useEffect(() => {
		if (user) {
			if (user.type === "student") {
				setAvatar(user.tutoringAppAvatar);
			}
			if (user.type === "tutor") {
				setAvatar(user.avatar);
			}
		}
	}, [user]);

	return (
		user && (
			<div className="relative">
				<div
					className="hover:scale-105 cursor-pointer"
					data-profileid="profile-id"
					onMouseEnter={handleShowMenu}
					// onMouseLeave={handleHideMenu}
					onClick={handleShowMenu}
				>
					{avatar ? (
						<img
							className="w-10 h-10 rounded-full object-cover"
							src={avatar}
							alt=""
							referrerPolicy="no-referrer"
						/>
					) : (
						<FaUserCircle size={40} className="text-lingawa-teal" />
					)}
				</div>

				<div
					className={clsx({
						block: showMenu,
						hidden: !showMenu,
					})}
					//onMouseEnter={handleShowMenu}
					onMouseLeave={handleHideMenu}
					onClick={handleShowMenu}
				>
					<div className="absolute bg-white w-max rounded-2xl px-8 py-4 shadow-xl top-10 right-0">
						<div>
							<h2 className="text-primary font-bold mb-4">{`Hi, ${user.firstname}`}</h2>
						</div>
						<div
							className="text-primary flex gap-3 items-center hover:text-primary-medium cursor-pointer pb-2"
							onClick={handleNavigateToProfile}
						>
							<CgProfile />
							<h3 className="text-sm font-semibold ">My Dashboard</h3>
						</div>
						<div
							className="text-red-500 flex gap-3 items-center hover:text-red-700 cursor-pointer"
							onClick={handleLogout}
						>
							<RiLogoutBoxRLine />
							<h3 className="text-sm font-semibold ">Logout</h3>
						</div>
					</div>
				</div>
			</div>
		)
	);
}
