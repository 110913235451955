import React from "react";

interface ProgressBarProps {
  currentIndex: number;
  numberOfQuestions: number;
  stroke?: string;
}

export default function ProgressBar({
  currentIndex,
  numberOfQuestions,
  stroke = "bg-lingawa-primary",
}: ProgressBarProps) {
  const progressPercentage = (currentIndex / numberOfQuestions) * 100;

  return (
    <div className="w-full bg-purple-100 rounded-full h-2 dark:bg-pulingawa-primary">
      <div
        className={`${stroke} h-2 rounded-full`}
        style={{ width: `${progressPercentage}%` }}
      ></div>
    </div>
  );
}
