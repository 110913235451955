import { useContext, useEffect, useState } from "react";
import HeaderText from "./HeaderText";
import {
  Context as DirectPayContext,
} from "src/context/DirectPayContext";
import NewAvailability from "src/components/NewAvailability";
import { LessonSchedule } from "src/interfaces/direct-pay";
import {
  defaultAvailability,
  tutorBookings,
} from "src/static-data/Availability";
import { formatDateToNeutral } from "src/utils/dateUtils";
export default function ScheduleForm() {
  const {
    state: {lessonSchedule },
    actions: {
      setLessonSchedule,
    },
  } = useContext(DirectPayContext);
  const [active, setActive] = useState("full");

  const [lessonScheduleFromCalendar, setLessonScheduleFromCalendar] =
    useState<LessonSchedule>();
  useEffect(() => {
    if (lessonScheduleFromCalendar) {
      lessonSchedule[0] = lessonScheduleFromCalendar;
      setLessonSchedule(lessonSchedule);
    }
    document
      ?.querySelectorAll(`#duration-card`)
      ?.forEach((el) =>
        el?.scrollIntoView({ behavior: "smooth", block: "center" })
      );
    // scrollToBookLesson();
  }, [lessonScheduleFromCalendar]);
  const handleRadioChange = (event: any) => {
    setActive(event.target.value);
  };

  return (
    <div className="mt-12">
      <section className="max-w-2xl mx-auto">
        <HeaderText>When would you love to have your first lesson</HeaderText>
        <p className="mt-3 font-semibold text-secondary">
          This helps us match you with the perfect available tutor!
        </p>
      </section>

      <div className="mt-6  flex justify-center">
        <NewAvailability
          tutorAvailability={{ availability: defaultAvailability }}
          tutorBookings={tutorBookings}
          scrollToBookLesson={null}
          setLessonScheduleFromCalendar={setLessonScheduleFromCalendar}
          selectedLessonScheduleId={0}
        />
      </div>
      <div id="duration-card">
      {lessonSchedule[0]?.date && (
        <div >
          <p className="mt-3 text-center pt-4 font-semibold text-primary">
            Select lesson duration{" "}
          </p>
          <div className="flex gap-6 justify-center md:justify-center mt-4 mx-auto pb-4">
            {/* 25 minutes option */}
            <div className="flex items-center gap-2">
              <input
                type="radio"
                id="half"
                value="half"
                checked={active === "half"}
                onChange={handleRadioChange}
                className="w-5 h-5  accent-orange-500 focus:ring-2 focus:ring-offset-2 cursor-pointer"
              />
              <label
                htmlFor="half"
                className={`cursor-pointer font-semibold text-normal md:font-bold md:text-normal transition-colors duration-300 ease-in-out ${
                  active === "half" ? "text-secondary" : "text-primary"
                }`}
              >
                25 minutes
              </label>
            </div>

            {/* 55 minutes option */}
            <div className="flex items-center gap-2">
              <input
                type="radio"
                id="full"
                value="full"
                checked={active === "full"}
                onChange={handleRadioChange}
                className="w-5 h-5 accent-orange-500 focus:ring-2 focus:ring-offset-2 cursor-pointer"
              />
              <label
                htmlFor="full"
                className={`cursor-pointer font-semibold text-normal md:font-bold md:text-normal transition-colors duration-300 ease-in-out ${
                  active === "full" ? "text-secondary" : "text-primary"
                }`}
              >
                55 minutes
              </label>
            </div>
          </div>
        </div>
      )}
      </div>
      <div
        style={{
          borderColor: "#F4F4F4",
          backgroundColor: "#F2F2F280",
        }}
        className="mt-3 h-16 rounded-3xl border-2 py-5 px-4 inline-flex items-center justify-between cursor-pointer"
      >
        <p className="text-md text-primary font-semibold">
          First Lesson Date:{" "}
          {lessonSchedule[0]?.date &&
            formatDateToNeutral(
              lessonSchedule[0]?.date,
              lessonSchedule[0]?.time
            )}
        </p>
      </div>
     
    
    </div>
  );
}
