import { FiPhoneCall } from "react-icons/fi";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useEffect, useState } from "react";
import { IPhoneNumber } from "src/interfaces/contact";
import { useStateValue } from "src/context/StateProvider";
import { getContactDetails } from "src/services/contact";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { ReactComponent as ContactPerson } from "src/assets/icons/contact-person.svg";
import ContactUsModal from "../../ContactUsModal";

export default function ContactIcon({
  colorVariant,
}: {
  colorVariant: string;
}) {
  const [{ clientIpCountry }] = useStateValue();
  const [contactNumber, setContactNumber] = useState<IPhoneNumber>({
    label: "0203 2898 195",
    phone: "+442032898195",
  });
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (clientIpCountry) {
      setContactNumber(getContactDetails(clientIpCountry));
    }
  }, [clientIpCountry]);

  if (!contactNumber) return null;

  return (
    <Tippy content="Contact us" delay={1000}>
      <>
        <ContactUsModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />

        <ContactPerson
          className="w-[25px] cursor-pointer"
          onClick={() => setIsOpen(true)}
        />
      </>
    </Tippy>
  );
}
