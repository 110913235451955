export const getEmojiForStatus = (status: number) => {
    switch (status) {
        case 0:
            return `${process.env.REACT_APP_ASSET_CDN}/pl-1.png`;
        case 1:
            return `${process.env.REACT_APP_ASSET_CDN}/pl-2.png`;
        case 2:
            return `${process.env.REACT_APP_ASSET_CDN}/pl-3.png`;
        case 3:
            return `${process.env.REACT_APP_ASSET_CDN}/pl-4.png`;
        case 4:
            return `${process.env.REACT_APP_ASSET_CDN}/pl-5.png`;
        default:
            return "";
    }
}