import { NavLink, useNavigate } from "react-router-dom";

import "react-phone-input-2/lib/style.css";
import { BsArrowLeftShort } from "react-icons/bs";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StudentEmailSignupForm from "./StudentEmailSignupForm";
import { routes } from "src/Routes";
import { Helmet } from "react-helmet";
import AuthOptions from "src/components/Shared/AuthOptions";
import { useState } from "react";
import { AuthType } from "src/views/Authentication/Login";

const logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-logo-light.png`;
toast.configure();

export default function StudentEmailSignup({
  directPayUser = false,
  handleNavigate,
}: any) {
  const navigate = useNavigate();
  const [authType, setAuthType] = useState<AuthType | null>(null);

  // const googleAuth = () => {
  //   window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, "_self");
  // };

  // const facebookAuth = () => {
  //   window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, "_self");
  // };

  return (
    <>
      <Helmet>
        <title>Student sign up</title>
        <meta name="description" content="Online learning" />
        <meta name="keywords" content="Online learning tutoring" />
      </Helmet>
      <div className="bg-white md:bg-pale-purple-bg">
        {/* Centralise Div */}
        <div className="container md:grid md:place-items-center min-h-screen">
          <div className="flex flex-col gap-2 pt-8 md:pt-0">
            {!directPayUser && (
              <div
                className="cursor-pointer flex items-center justify-center"
                onClick={() => navigate("/")}
              >
                <img src={logo} alt="" width={171} height={135} />
              </div>
            )}
            {/* White Card */}
            <div className="bg-white md:p-6 rounded-2xl md:shadow-md w-auto md:w-[28rem]">
              {/* Top Nav */}
              <div className="flex justify-between items-center">
                <BsArrowLeftShort
                  className="text-light-purple cursor-pointer"
                  size={30}
                  onClick={() => {
                    if (directPayUser) {
                      handleNavigate();
                    } else {
                      if (authType) {
                        setAuthType(null);
                        return;
                      }
                      navigate(-1);
                    }
                  }}
                />
                <h2 className="text-primary font-bold md:text-2xl">
                  Get Started
                </h2>
                <NavLink to={routes.LOGIN.url}>
                  <h3 className="text-secondary text-xs cursor-pointer">
                    Log in
                  </h3>
                </NavLink>
              </div>
              {/* PreSignup */}
              {!authType && (
                <AuthOptions flow="signup" setAuthType={setAuthType} />
              )}
              {/* Form */}
              {authType === "email" && (
                <StudentEmailSignupForm directPayUser={directPayUser} />
              )}
              {/* Email Signup Button */}
              {/* Menu */}
              {/* Button One */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
