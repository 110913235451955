import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "src/Routes";
import assets from "src/assets/assets";
import { useStateValue } from "src/context/StateProvider";

export default function Notifications() {
	const [{ unreadNotifications, showMobileMenu }, dispatch] = useStateValue();

	const closeMobileMenu = async () => {
		await dispatch({
			type: "SET_SHOW_MOBILE_MENU",
			showMobileMenu: !showMobileMenu,
		});
	};

	const location = useLocation();
	return (
		<div>
			<NavLink to={routes.STUDENT_DASHBOARD_NOTIFICATIONS.url}>
				<div
					className={clsx(
						{
							"menu-inactive":
								routes.STUDENT_DASHBOARD_NOTIFICATIONS.url !==
								location.pathname,
						},
						{
							"menu-active":
								routes.STUDENT_DASHBOARD_NOTIFICATIONS.url ===
								location.pathname,
						}
					)}
					onClick={closeMobileMenu}
				>
					<div
						className="grid gap-4"
						style={{
							gridTemplateColumns: "1fr auto",
						}}
					>
						<div className="flex gap-4 items-center">
							<div>
								<img src={assets.sNoti} className="w-[30px]" />
							</div>

							<div className="text-[16px] truncate max-w-[120px]">
								{routes.STUDENT_DASHBOARD_NOTIFICATIONS.label}
							</div>
						</div>

						{unreadNotifications && (
							<div
								className={clsx({
									"bg-secondary hover:bg-secondary-light text-xxs w-auto text-white grid place-items-center":
										unreadNotifications?.length,
									hidden: !unreadNotifications?.length,
								})}
								style={{
									width: 25,
									height: 25,
									borderRadius: 25,
								}}
							>
								{unreadNotifications?.length}
							</div>
						)}
					</div>
				</div>
			</NavLink>
		</div>
	);
}
