import React, { useEffect, useState } from "react";
import WordBank from "./Wordbank";
import SentenceArea from "./SentenceArea";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import clsx from "clsx";
import { LuForward } from "react-icons/lu";

toast.configure();

export default function SentenceBuilder({ question, handleSubmit }: any) {
  const [sentence, setSentence] = useState<any[]>([]);
  const [targetSentences, setTargetSentences] = useState<string[]>([]);
  const [sourceSentence, setSourceSentence] = useState<string>();
  const [wordBank, setWordBank] = useState<any[]>([]);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true);

  useEffect(() => {
    if (!question) return;
    setTargetSentences(question.targetSentences);
    setSourceSentence(question.sourceSentence);
    setWordBank(question.wordBank);
    setSentence([]);
  }, [question]);

  useEffect(() => {
    if (sentence.length === 0) {
      setIsSubmitButtonDisabled(true);
      return;
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [sentence]);

  const handleWordBankUpdate = (word: any, add: boolean) => {
    if (add) {
      // Add the word back to the word bank
      const wordExists = wordBank.find((w) => w._id === word._id);
      if (wordExists) return;
      setWordBank((prevWordBank) => [...prevWordBank, word]);
    } else {
      // Remove the word from the word bank by _id
      setWordBank((prevWordBank) =>
        prevWordBank.filter((w) => w._id !== word._id)
      );
    }
  };

  const handleWordClick = (word: any) => {
    // Remove the word from the word bank by _id
    setWordBank((prevWordBank) =>
      prevWordBank.filter((w) => w._id !== word._id)
    );
    // Add the word to the sentence
    setSentence((prevSentence) => [...prevSentence, word]);
  };

  const handleAddWordToBank = (word: any) => {
    handleWordBankUpdate(word, true); // Re-add the word to the word bank
  };

  return (
    <div>
      <div>
        <h2 className="text-l font-bold text-center text-lingawa-primary mt-6 mb-4">
          Translate the sentence below into {question?.targetLanguage}
        </h2>
      </div>
      <div className="px-12 pb-12">
        <h2 className="text-3xl font-bold text-lingawa-blue text-center">
          {sourceSentence}
        </h2>
      </div>
      <SentenceArea
        sentence={sentence}
        setSentence={setSentence}
        onWordRemove={handleWordBankUpdate}
      />
      {/* divider */}
      <div className="flex justify-center items-center mt-4">
        <div className="w-full h-[1px] mt-6 mb-2 bg-gray-200"></div>
      </div>
      <p className="text-xxs text-gray-300 text-center mb-8">
        Drag the words to the spaces above
      </p>
      <div className="mt-4">
        <WordBank
          words={wordBank}
          onDragStart={(word) => handleWordBankUpdate(word, false)}
          onDrop={handleAddWordToBank}
          onWordClick={handleWordClick}
        />
      </div>

      <div className="w-full lg:fixed lg:bottom-0 lg:left-64 lg:w-[calc(100%-16rem)] lg:bg-white lg:p-6 lg:border-t">
        <div className="flex justify-center">
          <button
            onClick={() =>
              handleSubmit(question.type, { sentence, targetSentences })
            }
            disabled={isSubmitButtonDisabled}
            className={`
                py-2 px-4 w-full md:w-[30%] rounded-xl font-semibold border-[1px] border-b-4 border-l-4 transition-transform transform 
                ${
                  !isSubmitButtonDisabled
                    ? "bg-orange-600 text-white hover:bg-orange-700 border-primary"
                    : "bg-gray-200 text-gray-400 cursor-not-allowed"
                }
              `}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
