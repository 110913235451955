import { IClientCurrencyData } from "src/interfaces/currency";
import { ITutorSearchResult } from "src/interfaces/tutor";
import { useState, useContext } from "react";

import AvatarPrice from "./AvatarPrice";
import CredentialStats from "./CredentialStats";
import VideoAndBookingCard from "./VideoAndBookingCard";
import { NavLink } from "react-router-dom";
import { routes } from "src/Routes";
import { FaChevronLeft } from "react-icons/fa";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
type TopInfoSectionType = {
  tutorProfile: ITutorSearchResult;
  currency: IClientCurrencyData | null;
  bookLessonRef: React.RefObject<HTMLDivElement>;
  setisPayment: React.Dispatch<React.SetStateAction<boolean>>;
  ratings: any;
  tutorRating : number | undefined
};

export default function TopInfoSection({
  tutorProfile,
  currency,
  tutorRating,
  bookLessonRef,
  setisPayment,
  ratings,
}: TopInfoSectionType) {
  const [, setLoginIsOpen] = useState<boolean>(false);
  const {
    actions: { setLessonSchedule },
  } = useContext(DirectPayContext);
  const clearLessonSchedule = () => {
    setLessonSchedule([]);
  };
  return (
    <>
      <NavLink to={`${routes.DIRECT_TO_PAY_MEET_TUTORS.url}`}>
        <div className="mt-4">
          <button
            onClick={() => {
              clearLessonSchedule();
            }}
            className="gray-button-2 flex  items-center space-x-2 -mt-12"
          >
            <FaChevronLeft className="w-4 h-4" />
            {/* <span className="hidden lg:block">Back to Tutors</span> */}
          </button>
        </div>
      </NavLink>
      <div className="flex flex-col md:flex-row justify-between relative mt-4">
        <div className="flex gap-10 flex-col md:flex-row">
          <AvatarPrice tutorProfile={tutorProfile} currency={currency} />
          <CredentialStats tutorProfile={tutorProfile} tutorRating={tutorRating} ratings={ratings} />
        </div>
        <div ref={bookLessonRef} className="block md:hidden">
          <VideoAndBookingCard
            tutor={tutorProfile}
            currency={currency}
            setIsOpen={setLoginIsOpen}
            setisPayment={setisPayment}
          />
        </div>
        <div className="hidden md:block">
          <div className="hidden md:absolute md:block md:-translate-x-[20.5rem] md:origin-right">
            <VideoAndBookingCard
              tutor={tutorProfile}
              currency={currency}
              setIsOpen={setLoginIsOpen}
              setisPayment={setisPayment}
            />
          </div>
        </div>
      </div>
    </>
  );
}
