/* eslint-disable import/prefer-default-export,class-methods-use-this */

import axios from "axios";
import {
	ICreateSubscription,
	ILearningPackagesReq,
	IUpdateOrder,
	IUpdateSubscription,
} from "src/interfaces/payment";
import {
	chargeSavedPaymentMethodForGroupLesson,
	chargeSavedPaymentMethodForOneOnOneLesson,
	createChargeVerification,
	createGroupLessonOrder,
	createOneOnOneLessonOrder,
	createRevolutOrder,
	createSubscription,
	getLearningPackage,
	getLearningPackages,
	getPaymentOrder,
	getSubscription,
	postApplyDiscountCode,
	refundChargeVerification,
	savePaymentMethodToUserAccount,
	sendPaymentConfirmation,
	updateRevolutOrder,
	updateSubscription,
} from "src/server/endpoints/payment";

export class PaymentApi {
	static createGroupLessonOrder(
		user: any,
		groupLessonId: string,
		lessonPrice: { value: number; currencyCode: string }
	): Promise<any> {
		return createGroupLessonOrder(user, groupLessonId, lessonPrice);
	}
	static chargeSavedPaymentMethodForGroupLesson(
		user: any,
		groupLessonId: string,
		lessonPrice: { value: number; currencyCode: string }
	): Promise<any> {
		return chargeSavedPaymentMethodForGroupLesson(
			user,
			groupLessonId,
			lessonPrice
		);
	}
	static chargeSavedPaymentMethodForOneOnOneLesson(
		user: any,
		bookingDetails: any
	): Promise<any> {
		return chargeSavedPaymentMethodForOneOnOneLesson(user, bookingDetails);
	}
	static sendPaymentConfirmation(
		user: any,
		revolutPublicId: string
	): Promise<any> {
		return sendPaymentConfirmation(user, revolutPublicId);
	}
	static createChargeVerification(user: any): Promise<any> {
		return createChargeVerification(user);
	}
	static savePaymentMethodToUserAccount(user: any): Promise<any> {
		return savePaymentMethodToUserAccount(user);
	}
	static refundChargeVerification(orderId: string): Promise<any> {
		return refundChargeVerification(orderId);
	}
	static createOneOnOneLessonOrder(
		user: any,
		bookingDetails: any
	): Promise<any> {
		return createOneOnOneLessonOrder(user, bookingDetails);
	}
	static createRevolutOrder(data: any): Promise<any> {
		return createRevolutOrder(data);
	}
	static getPaymentOrder(data: any): Promise<any> {
		return getPaymentOrder(data);
	}
	static getPayment(data: any): Promise<any> {
		return getPaymentOrder(data);
	}
	async updatePayment(data: any): Promise<any> {
		try {
			const response = await axios.patch(
				`${process.env.REACT_APP_V2_SERVER_URL}/payment/update`,
				{
					paymentId: data.paymentId,
					state: data.state,
				}
			);

			return response.data;
		} catch (error) {
			console.log("caught error: ", error);
		}
	}
	static updateRevolutOrder(body: IUpdateOrder): Promise<any> {
		return updateRevolutOrder(body);
	}
	static createSubscription(body: ICreateSubscription): Promise<any> {
		return createSubscription(body);
	}
	static updateSubscription(body: IUpdateSubscription): Promise<any> {
		return updateSubscription(body);
	}
	static getSubscription(data: any): Promise<any> {
		return getSubscription(data);
	}
	static getLearningPackages(params: ILearningPackagesReq): Promise<any> {
		return getLearningPackages(params);
	}
	static getLearningPackage(id: string): Promise<any> {
		return getLearningPackage(id);
	}
	static postApplyDiscountCode(
		code: string,
		subscriptionId: string
	): Promise<any> {
		return postApplyDiscountCode(code, subscriptionId);
	}

	static async checkoutError(paymentId: string, error: any): Promise<any> {
		try {
			const response = await axios.patch(
				`${process.env.REACT_APP_V2_SERVER_URL}/payment/error`,
				{
					paymentId,
					error: JSON.stringify(error),
				}
			);

			return response.data;
		} catch (error) {
			console.log("caught error: ", error);
		}
	}
}
