import React from "react";
import { FaChevronRight } from "react-icons/fa6";
import { MdOutlineClose, MdOutlineKeyboardBackspace } from "react-icons/md";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import BetaTag from "src/components/BetaTag";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";

export default function SessionBreadcrumbs({
  isBetaVersion,
  sessionType,
}: {
  isBetaVersion?: boolean;
  sessionType?: string;
}) {
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const { userId, level, topic, order, topicId } = useParams();
  const { state } = useLocation();
  const route =
    user?.type === "student"
      ? routes.STUDENT_DASHBOARD_LEARN.url
      : routes.TUTOR_DASHBOARD_LEARN.url;

  console.log("userId", userId);
  console.log("order", order);

  console.log("level", level);
  console.log("topic", topic);

  return (
    <div className="flex gap-4 items-center">
      <div
        className="text-primary cursor-pointer  items-center flex gap-2"
        onClick={() => navigate(-1)}
      >
        <div>
          <MdOutlineClose size={27} />
        </div>
      </div>
      <NavLink
        to={`${route}/${userId}`}
        state={{ curriculum: state?.curriculum, ageGroup: state?.ageGroup }}
      >
        <div className="text-orange-600 font-bold cursor-pointer flex-grow items-center flex gap-2">
          <p className="hidden text-center md:block">{level}</p>
          <p className="text-center md:hidden">...</p>
        </div>
      </NavLink>
      <div className="text-gray-300 cursor-pointer  items-center flex gap-2">
        <div>
          <FaChevronRight />
        </div>
      </div>
      <NavLink
        to={`${route}/${userId}/${level}/${order}/${topic}/${topicId}`}
        state={{ curriculum: state?.curriculum, ageGroup: state?.ageGroup }}
      >
        <div className="text-orange-600 font-bold cursor-pointer flex-grow items-center flex gap-2">
          <p className="text-center md:block">{topic}</p>
        </div>
      </NavLink>
      <div className="text-gray-300 cursor-pointer  items-center flex gap-2">
        <div>
          <FaChevronRight />
        </div>
      </div>
      <div className="text-primary font-semibold text-center  text-sm md:text-xl">
        <div className="text-sm text-center">
          {sessionType === "Learn Session" ? "Vocab Drills" : sessionType}
        </div>
      </div>
      {isBetaVersion && (
        <div className="max-w-[150px] hidden md:block">
          <BetaTag />
        </div>
      )}
    </div>
  );
}
