// EndOfSession.tsx
import React, { useEffect } from "react";
import Confetti from "react-confetti";

export default function EndOfSession({
  sessionWords,
  handleGoToSummary,
}: {
  sessionWords: any;
  handleGoToSummary?: any;
}) {
  useEffect(() => {
    // Play victory jingle on mount
    const audio = new Audio(
      `${process.env.REACT_APP_ASSET_CDN}/happy-african.mp3`
    );
    audio.play();
  }, []);

  const imageUrl = `${process.env.REACT_APP_ASSET_CDN}/mascot-excited-badge.png`;

  return (
    <div className="flex flex-col items-center justify-center min-h-[78vh] rounded-2xl bg-purple-100">
      <Confetti />
      {/* Image */}
      <div>
        <img src={imageUrl} alt="Success" className="h-32 w-32 mb-4" />
      </div>
      <h1 className="text-4xl text-center font-extrabold text-lingawa-primary mb-4">
        Session Completed!
      </h1>
      <p className="text-center text-primary font-semibold text-sm">
        {`${sessionWords.length} ${
          sessionWords.length > 1 ? "words" : "word"
        } learned`}
      </p>
      <button
        className="mt-6 px-6 py-3 bg-lingawa-primary text-white rounded-md hover:bg-primary"
        onClick={handleGoToSummary}
      >
        Session Summary
      </button>
    </div>
  );
}
