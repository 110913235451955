import clsx from "clsx";
import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import assets from "src/assets/assets";
import useResponsive from "src/hooks/useResponsive";
import BaseCard, { BaseCardText } from "./BaseCard";

const LeaderboardCard = ({ leaderboard, user, createdAt, className }: any) => {
	const currentMonth = () => {
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		return months[new Date(createdAt).getMonth()];
	};

	const [luser, setLuser] = useState<any>();

	const LEADERBOARD_MAX = 15;

	useEffect(() => {
		let usr;

		if (leaderboard?.length > 0) {
			usr = leaderboard?.find((e: any) => e.id == user._id);
		}

		if (!usr)
			usr = {
				firstName: user?.firstname,
				lastName: user?.lastname,
				avatar: user?.avatar,
				id: user?._id,
				cowries: 0,
				totalCowries: 0,
				rank: LEADERBOARD_MAX + 1,
			};

		if (!user?.totalCowries) user.totalCowries = user.cowries;

		setLuser(usr);
	}, [leaderboard]);

	const _mob = useResponsive(360, 740);

	return (
		<BaseCard padding={15} className={clsx("relative", className)}>
			<div className="grid place-items-center">
				<img src={assets.dLeaderboardGem} className="w-[60px]" />
				<BaseCardText
					t={`${currentMonth() ?? ""} Leaderboard`}
					lg
					bold
					className="mt-2"
				/>
			</div>

			<div className={clsx(luser?.rank > LEADERBOARD_MAX ? "pb-[65px]" : "")}>
				{leaderboard?.slice(0, LEADERBOARD_MAX)?.map((e: any) => (
					<LeaderboardEntry
						key={e?.rank}
						e={e}
						leaderboard={leaderboard}
						me={e?.userId == user?._id}
						isMob={_mob}
					/>
				))}
			</div>

			{luser?.rank > LEADERBOARD_MAX && (
				<div
					className={clsx(
						"absolute bottom-0 left-0 w-full h-[80px] rounded-[15px] shadow-md bg-[white] border border-black-100 grid items-center",
						"px-4"
					)}
				>
					<LeaderboardEntry
						e={luser}
						leaderboard={leaderboard}
						me
						pinned
						isMob={_mob}
					/>
				</div>
			)}
		</BaseCard>
	);
};

export default LeaderboardCard;

function LeaderboardEntry({
	e,
	leaderboard,
	me,
	pinned,
	isMob,
}: {
	e: any;
	leaderboard: any;
	me?: boolean;
	pinned?: boolean;
	isMob?: boolean;
}) {
	const fontProp = {
		...(isMob ? { sm: true } : { md: true }),
	};
	return (
		<div
			className={clsx(
				`flex ${
					e?.rank == leaderboard?.length - 1 ? "" : "border-b"
				} border-black-100 px-5 justify-between items-center`
			)}
			style={{
				backgroundColor: me && !pinned ? "#FFE8DA" : "",
				borderRadius: 5,
			}}
		>
			<div className="flex my-[17px] my-[10px] items-center w-[100%]">
				<div className="w-10">
					<BaseCardText t={`${e?.rank}.`} {...fontProp} bold />
				</div>

				{e.avatar ? (
					<img
						src={e.avatar}
						className={"w-[50px] h-[50px] bg-gray-100 mr-[10px] rounded-md"}
						style={{
							width: isMob ? 10 : 50,
						}}
					/>
				) : (
					<div
						className={`w-[${isMob ? "10px" : "50px"}] h-[${
							isMob ? "10px" : "50px"
						}] bg-gray-100 mr-[10px] grid place-items-center`}
						style={{
							width: isMob ? 10 : 50,
						}}
					>
						<FaUser
							size={isMob ? 10 : 40}
							className={`w-[${isMob ? "10px" : "35px"}] h-[${
								isMob ? "10px" : "35px"
							}] bg-gray-100 text-light-purple`}
						/>
					</div>
				)}

				<div className="w-[50%]">
					<BaseCardText
						t={`${e?.firstName ?? ""} ${e?.lastName?.[0]?.toUpperCase() ?? ""}`}
						className={clsx(
							`w-[${
								isMob ? 58 : 120
							}px] font-[600] truncate whitespace-nowrap overflow-hidden text-ellipsis`
						)}
						{...fontProp}
					/>
				</div>
			</div>

			<div className={clsx("flex items-center", me ? "pr-5" : "")}>
				<img
					src={assets.dCowry}
					className={clsx(`w-[${isMob ? 8 : "10px"}] mr-2`)}
					style={{
						width: isMob ? 10 : 20,
					}}
				/>

				<p className="whitespace-nowrap font-bold text-md">
					<span>{e?.cowries?.toString()}</span>

					{me && (
						<>
							<span className="mx-[6px]" style={{ fontSize: 12 }}>
								out of
							</span>
							<span>
								{e?.totalCowries?.toString() ?? e?.cowries?.toString()}
							</span>
						</>
					)}
				</p>
			</div>
		</div>
	);
}
