import React, { useState } from "react";
import { Prompt } from "./types";
import MultipleChoicePrompt from "../TestYourKnowledge/MultipleChoicePrompt";

/**
 * The props your parent might pass in.
 */
interface MultipleChoiceSectionProps {
  /**
   * The array of multiple-choice prompts.
   */
  prompts: Prompt[];

  /**
   * Called when all prompts (and any re-queued fails) are eventually completed.
   */
  onFinish: () => void;

  /**
   * If the user chooses "I already know this word,"
   * remove that prompt from the session.
   */
  handleRequestRemoveWord: () => void;

  /**
   * Called each time a user answers a prompt (correct or not).
   * Typically used to show feedback or track progress in the parent.
   */
  onShowFeedback: (isCorrect: boolean, prompt: Prompt) => void;

  /**
   * A setter from the parent that merges newly "completed" (passed) word IDs.
   * Usually typed: React.Dispatch<React.SetStateAction<string[]>>.
   * If your parent doesn't track these, you can remove this prop.
   */
  setCompletedWordIds?: React.Dispatch<React.SetStateAction<string[]>>;
  setCurrentWordId?: React.Dispatch<React.SetStateAction<string | null>>;
}

const MultipleChoiceSection: React.FC<MultipleChoiceSectionProps> = ({
  prompts,
  onFinish,
  handleRequestRemoveWord,
  onShowFeedback,
  setCompletedWordIds,
  setCurrentWordId,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [failedQueue, setFailedQueue] = useState<Prompt[]>([]);

  /**
   * Called by each prompt in the main pass.
   */
  const handleNext = (isCorrect: boolean) => {
    const currentPrompt = prompts[currentIndex];

    // Let the parent show feedback (score, animations, etc.)
    onShowFeedback(isCorrect, currentPrompt);

    // If user fails => move that prompt to 'failedQueue'
    if (!isCorrect) {
      setFailedQueue((prev) => [...prev, currentPrompt]);
    } else {
      // If user passes => optionally store the word ID in completed
      if (currentPrompt.word?._id && setCompletedWordIds) {
        setCompletedWordIds((prev) =>
          prev.includes(currentPrompt.word!._id)
            ? prev
            : [...prev, currentPrompt.word!._id]
        );
      }
    }

    // Move on to the next prompt in the main pass
    setTimeout(() => {
      setCurrentIndex((prev) => prev + 1);
    }, 2000);
  };

  // If we've finished the main pass
  if (currentIndex >= prompts.length) {
    // If any fails => handle indefinite re-queue
    if (failedQueue.length > 0) {
      return (
        <ReQueueMultipleChoice
          queue={failedQueue}
          onDone={onFinish}
          handleRequestRemoveWord={handleRequestRemoveWord}
          onShowFeedback={onShowFeedback}
          setCompletedWordIds={setCompletedWordIds}
          setCurrentWordId={setCurrentWordId}
        />
      );
    } else {
      // no fails => done
      onFinish();
      return null;
    }
  }

  // Otherwise, show the next prompt in the main pass
  const currentPrompt = prompts[currentIndex];
  setCurrentWordId?.(currentPrompt.word?._id || null);

  return (
    <div>
      {/* 
        If you want a "I already know this word" button for the main pass:
        <button onClick={() => onRemovePrompt(currentPrompt._id)}>
          I already know this
        </button>
      */}

      <MultipleChoicePrompt
        prompt={currentPrompt}
        onNext={handleNext}
        handleRequestRemoveWord={handleRequestRemoveWord}
      />
    </div>
  );
};

/**
 * The indefinite re-queue sub-component that keeps cycling fails
 * until the user eventually passes them.
 */
interface ReQueueProps {
  queue: Prompt[];
  onDone: () => void;
  handleRequestRemoveWord: () => void;
  onShowFeedback: (isCorrect: boolean, prompt: Prompt) => void;
  setCompletedWordIds?: React.Dispatch<React.SetStateAction<string[]>>;
  setFailedWordIds?: React.Dispatch<React.SetStateAction<string[]>>;
  setCurrentWordProficiency?: React.Dispatch<React.SetStateAction<number>>;
  setCurrentWordId?: React.Dispatch<React.SetStateAction<string | null>>;
}

const ReQueueMultipleChoice: React.FC<ReQueueProps> = ({
  queue: initialQueue,
  onDone,
  handleRequestRemoveWord,
  onShowFeedback,
  setCompletedWordIds,
  setFailedWordIds,
  setCurrentWordId,
}) => {
  // We'll store the re-queue in local state
  const [queue, setQueue] = useState<Prompt[]>(initialQueue);
  const [index, setIndex] = useState<number>(0);

  /**
   * Called by each prompt in the re-queue phase.
   */
  const handleNext = (isCorrect: boolean) => {
    const currentPrompt = queue[index];

    // Let the parent show feedback again
    onShowFeedback(isCorrect, currentPrompt);

    if (isCorrect) {
      if (currentPrompt.word?._id && setCompletedWordIds) {
        setCompletedWordIds((prev) =>
          prev.includes(currentPrompt.word!._id)
            ? prev
            : [...prev, currentPrompt.word!._id]
        );
      }
      // remove from the queue
      setTimeout(() => {
        setQueue((prev) => {
          const newArr = [...prev];
          newArr.splice(index, 1);
          return newArr;
        });
      }, 2000);
    } else {
      // re-queue at the end
      setTimeout(() => {
        setQueue((prev) => {
          const newArr = [...prev];
          const item = newArr[index];
          newArr.splice(index, 1);
          newArr.push(item);

          // Optionally track fails
          if (currentPrompt.word?._id && setFailedWordIds) {
            setFailedWordIds((prevFails) => [
              ...prevFails,
              currentPrompt.word!._id as string,
            ]);
          }
          return newArr;
        });
      }, 2000);
    }

    // Force index to reset after manipulation
    setIndex(0);
  };

  // If queue is empty => all eventually passed
  if (queue.length === 0) {
    onDone();
    return null;
  }

  // If index >= queue.length => reset to 0
  if (index >= queue.length) {
    setIndex(0);
    return null;
  }

  const prompt = queue[index];
  if (setCurrentWordId) setCurrentWordId(prompt.word?._id);

  return (
    <div>
      {/* 
        If you want a "I already know this" button for re-queue:
        <button onClick={() => onRemovePrompt(prompt._id)}>
          I already know this
        </button>
      */}
      <MultipleChoicePrompt
        prompt={prompt}
        onNext={handleNext}
        handleRequestRemoveWord={handleRequestRemoveWord}
      />
    </div>
  );
};

export default MultipleChoiceSection;
