import clsx from "clsx";
import { useEffect, useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { routes } from "src/Routes";

import assets from "src/assets/assets";
import { IDependantInfo } from "src/interfaces/student";
import MyRewards from "./MyRewards";
import DependantRewards from "./DependantRewards";

interface IParentRewards {
  dependants: [];
}
export default function ParentRewards({ dependants }: IParentRewards) {
  const location = useLocation();
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  useEffect(() => {
    if (location.pathname.includes(routes.STUDENT_DASHBOARD_REWARDS.url)) {
      setShowProfileMenu(true);
    } else {
      setShowProfileMenu(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        className={clsx("parent-menu-inactive", {
          "parent-menu-active": location.pathname.includes(
            routes.STUDENT_DASHBOARD_REWARDS.url
          ),
        })}
        onClick={toggleProfileMenu}
      >
        <div className="flex gap-4 items-center">
          <div>
            {/* <FaRegEnvelope className="text-lg" /> */}
            <img src={assets.sRewards} className="w-[30px]" />
          </div>
          <div className="text-[16px]">Rewards</div>
        </div>
        <div className="flex items-center">
          <div
            className={clsx({
              hidden: !showProfileMenu,
              block: showProfileMenu,
            })}
          >
            <BiChevronUp size={23} />
          </div>
          <div
            className={clsx({
              block: !showProfileMenu,
              hidden: showProfileMenu,
            })}
          >
            <BiChevronDown size={23} />
          </div>
        </div>
      </div>
      {/* Sub Menu */}
      <div
        className={clsx({
          hidden: !showProfileMenu,
          "block bg-gray-50 rounded-lg py-4 mb-2": showProfileMenu,
        })}
      >
        <ul>
          <li>
            <MyRewards />
          </li>
          {dependants.map((dependant: IDependantInfo) => (
            <li key={dependant._id}>
              <DependantRewards dependant={dependant} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
