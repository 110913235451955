import React, { useCallback, useEffect, useState } from "react";
import { LearningAppApi } from "src/api/learning-app";
import { useNavigate, useParams } from "react-router-dom";
import Confetti from "react-confetti";
import { routes } from "src/Routes";

interface LeaderboardEntry {
  userId: string;
  firstName: string;
  lastName: string;
  userAvatar?: string;
  bestTime: number;
}

interface LeaderboardProps {
  userId: string;
  topicId: string;
  mode: "normal" | "beast";
  currentScore: number;
  onPlayAgain: () => void;
  onReturnToTopicMenu: () => void;
}

const Leaderboard: React.FC<LeaderboardProps> = ({
  userId,
  topicId,
  mode,
  currentScore,
  onPlayAgain,
  onReturnToTopicMenu,
}) => {
  const { level, order, topic } = useParams();
  const [scores, setScores] = useState<LeaderboardEntry[]>([]);
  const [showScoreModal, setShowScoreModal] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);

  const navigate = useNavigate();

  const getMatchingGameLeaderboard = useCallback(async () => {
    const response = await LearningAppApi.getMatchingGameLeaderboard(
      mode,
      topicId
    );
    console.log("Leaderboard response", response);
    setScores(response.data);
  }, [mode, topicId]);

  useEffect(() => {
    getMatchingGameLeaderboard();
  }, [getMatchingGameLeaderboard]);

  useEffect(() => {
    // Play a sound when the leaderboard component loads
    const audio = new Audio(
      `${process.env.REACT_APP_ASSET_CDN}/checkpoint.mp3`
    );
    audio.play().catch((err) => console.log("Audio play error:", err));
  }, []);

  // Find the current user's leaderboard entry
  const userEntry = scores.find((score) => score.userId === userId);
  const userBestTime = userEntry ? userEntry.bestTime : null;

  let message = "Great attempt!";
  if (userBestTime !== null) {
    if (currentScore === userBestTime) {
      message = "Awesome! This is your best score!";
      // Show confetti if the user beats their previous best
      if (!showConfetti) {
        setShowConfetti(true);
      }
    } else {
      message = "You didn’t beat your best time. Try again!";
    }
  } else {
    // User not on leaderboard or first time playing
    message = "Great attempt! You're not on the leaderboard yet.";
  }

  const getRankIcon = (rank: number) => {
    switch (rank) {
      case 1:
        return "🏆";
      case 2:
        return "🥈";
      case 3:
        return "🥉";
      default:
        return rank.toString();
    }
  };

  const colors = [
    "bg-blue-400",
    "bg-lingawa-teal",
    "bg-orange-600",
    "bg-pink-400",
    "bg-yellow-400",
    "bg-purple-500",
  ];

  return (
    <div className="w-full max-w-md mx-auto p-4 relative ">
      {showConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={300}
          onConfettiComplete={(confetti) => {
            // @ts-ignore
            confetti.reset(); // Properly reset confetti
            setShowConfetti(false); // Now safely update state
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}

      <div className="text-center mb-6 ">
        <h2 className="text-xl font-bold text-white py-2 bg-gradient-to-r from-orange-600 to-lingawa-teal rounded-md inline-block px-4">
          {mode === "normal"
            ? "Normal Mode Leaderboard"
            : "Beast Mode Leaderboard"}
        </h2>
        <div className="mt-4 text-lg text-white font-medium">{message}</div>
      </div>

      {scores.length === 0 ? (
        <div className="text-center mt-6 text-gray-500">
          No scores yet. Be the first to play!
        </div>
      ) : (
        <div className="space-y-4 mb-4 ">
          <div className="flex justify-between">
            <p className="text-white text-sm font-semibold">{`${topic}`}</p>
            <p className="text-white text-sm font-semibold">Top 10</p>
          </div>
          
          {scores.map((entry, index) => {
            const rank = index + 1;
            const rankIcon = getRankIcon(rank);
            const lastNameInitial = entry.lastName
              ? entry.lastName.charAt(0)
              : "";
            const displayName = `${entry.firstName} ${lastNameInitial}.`;
            const initials = `${entry.firstName.charAt(
              0
            )}${lastNameInitial}`.toUpperCase();
            const bgColor = colors[index % colors.length];

            // Highlight user's score if it's the current user
            const highlightClass =
              entry.userId === userId ? "border-4 border-yellow-500" : "";

            return (
              <div
                key={entry.userId}
                className={`flex items-center text p-4 rounded-lg shadow-md bg-gradient-to-r from-lingawa-primary-light to-lingawa-primary transition-transform transform hover:scale-105 ${highlightClass}`}
              >
                <div className="flex-shrink-0 w-12 h-12 rounded-full overflow-hidden mr-4">
                  {entry.userAvatar ? (
                    <img
                      src={entry.userAvatar}
                      alt={displayName}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div
                      className={`w-full h-full flex items-center justify-center ${bgColor} text-white font-bold uppercase`}
                    >
                      {initials}
                    </div>
                  )}
                </div>
                <div className="flex-1">
                  <h3 className="font-semibold text-lg text-white">
                    {displayName}
                  </h3>
                  <p className="text-sm text-white">
                    {entry.bestTime.toFixed(1)} seconds
                  </p>
                </div>
                <div className="text-2xl font-bold text-white ml-4">
                  {rankIcon}
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex justify-between mt-4">
        <button
          onClick={onPlayAgain}
          className="px-4 py-2 bg-lingawa-teal text-white font-bold rounded hover:bg-lingawa-teal-dark transition-colors"
        >
          Play Again
        </button>
        <button
          onClick={onReturnToTopicMenu}
          className="px-4 py-2 bg-orange-600 text-white font-bold rounded hover:bg-orange-700 transition-colors"
        >
          Topic Menu
        </button>
      </div>

      {showScoreModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
          <div className="bg-gradient-to-r from-lingawa-teal to-lingawa-orange p-6 text-center rounded-lg shadow-lg relative w-full max-w-sm mx-auto">
            <h2 className="text-xl text-white font-bold mb-4">Session Score</h2>
            <p className="mb-4 text-white font-semibold">
              {currentScore.toFixed(1)} seconds
            </p>
            <button
              onClick={() => setShowScoreModal(false)}
              className="px-4 py-2 bg-lingawa-primary text-white font-bold rounded hover:bg-orange-700 transition-colors"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
