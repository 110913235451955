import React, { useEffect, useState } from "react";
import { Prompt } from "./types";
import { LuForward } from "react-icons/lu";
import SubmitButton from "../VocabDrills/SubmitButton";

interface MultipleChoicePromptProps {
  prompt: Prompt;
  onNext: (isCorrect: boolean) => void;
  handleRequestRemoveWord?: any;
}

const MultipleChoicePrompt: React.FC<MultipleChoicePromptProps> = ({
  prompt,
  onNext,
  handleRequestRemoveWord,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const handleOptionSelect = (optionId: string) => {
    setSelectedOption(optionId);
  };

  useEffect(() => {
    setSelectedOption("");
    setHasSubmitted(false); // Reset when new prompt arrives
  }, [prompt._id]);

  const handleSubmit = () => {
    if (hasSubmitted) return; // Prevent double submission
    setHasSubmitted(true);

    const isCorrect =
      selectedOption ===
      prompt.options?.find((opt) => opt.word === prompt.correctAnswer)?._id;

    onNext(isCorrect || false);

    setTimeout(() => {
      setSelectedOption("");
      setHasSubmitted(false);
    }, 2000);
  };

  return (
    <div className="relative mx-auto md:p-6 rounded-xl">
      {/* Top content with extra bottom padding on large screens */}
      <div className="lg:pb-24">
        <p className="text-center text-primary text-lg mb-6">
          Translate:{" "}
          <span className="font-semibold text-lingawa-primary">
            "{prompt.word.englishTranslation}"
          </span>
        </p>

        {/* Image and options container */}

        <div className="flex flex-col items-center md:flex-row justify-center gap-8">
          {/* Display image if available */}
          {prompt.word.imageUrl && (
            <div className="flex justify-center max-w-60 md:w-full rounded-xl p-3 border-[1px] border-gray-300 mb-4">
              <img
                src={prompt.word.imageUrl}
                alt={prompt.word.englishTranslation}
                className="max-h-60 object-contain rounded-lg shadow-md"
              />
            </div>
          )}
          {/* Options as Cards */}
          <div className="mb-4 -mt-6 md:mt-0 w-full md:min-w-[330px]">
            {prompt.options?.map((option) => {
              const isSelected = selectedOption === option._id;
              return (
                <div
                  key={option._id}
                  onClick={() => handleOptionSelect(option._id)}
                  className={`
                    cursor-pointer px-3 py-3 rounded-2xl border-b-8 mb-2 bg-white border-2 hover:scale-105 transform transition-transform text-sm font-medium 
                    ${
                      isSelected
                        ? "text-green-600 bg-very-light-green border-green-600"
                        : "text-gray-400"
                    }
                  `}
                >
                  {option.word}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Buttons container:
          - On mobile: rendered in normal flow.
          - On large screens: fixed to bottom with offset from the side nav */}
      <SubmitButton
        handleRequestRemoveWord={handleRequestRemoveWord}
        handleSubmit={handleSubmit}
        selectedOption={selectedOption}
        hasSubmitted={hasSubmitted}
      />
    </div>
  );
};

export default MultipleChoicePrompt;
