import React, { useState, useEffect } from "react";
import { Word, CardData } from "./types";
import { LearningAppApi } from "src/api/learning-app";
import Timer from "./Timer";
import CardGrid from "./CardGrid";
import BackToResources from "src/components/BackToResources";

interface BeastGameProps {
  words: Word[];
  userId: string;
  topicId: string;
  onGameOver: (currentScore: number) => void;
}

const BeastGame: React.FC<BeastGameProps> = ({
  words,
  userId,
  topicId,
  onGameOver,
}) => {
  const [cards, setCards] = useState<CardData[]>([]);
  const [selectedCards, setSelectedCards] = useState<CardData[]>([]);
  const [incorrectSelection, setIncorrectSelection] = useState<CardData[]>([]);
  const [matchedCount, setMatchedCount] = useState<number>(0);
  const [startTime, setStartTime] = useState<number | null>(null);

  // Prepare cards on mount
  useEffect(() => {
    const nativeCards = words.map((w, i) => ({
      id: `n-${i}`,
      text: w.word,
      type: "native" as const,
      matched: false,
      imageUrl: w.imageUrl,
    }));
    const englishCards = words.map((w, i) => ({
      id: `e-${i}`,
      text: w.englishTranslation,
      type: "english" as const,
      matched: false,
      imageUrl: w.imageUrl,
    }));
    const allCards = [...nativeCards, ...englishCards];

    // Shuffle
    for (let i = allCards.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [allCards[i], allCards[j]] = [allCards[j], allCards[i]];
    }

    setCards(allCards);
    setStartTime(Date.now());
  }, [words]);

  const handleGameOver = async () => {
    if (matchedCount === 6 && cards.length > 0 && startTime !== null) {
      const timeTaken = (Date.now() - startTime) / 1000;

      // Post score and call onGameOver with timeTaken
      await LearningAppApi.postMatchingGameScore(
        userId,
        "beast",
        topicId,
        timeTaken
      );
      onGameOver(timeTaken);
    }
  };

  useEffect(() => {
    handleGameOver();
  }, [matchedCount, cards, startTime, userId, topicId]);

  const isMatch = (c1: CardData, c2: CardData) => {
    const nativeIndex =
      c1.type === "native"
        ? parseInt(c1.id.split("-")[1], 10)
        : parseInt(c2.id.split("-")[1], 10);
    const englishIndex =
      c1.type === "english"
        ? parseInt(c1.id.split("-")[1], 10)
        : parseInt(c2.id.split("-")[1], 10);
    return nativeIndex === englishIndex;
  };

  const matchCards = (c1: CardData, c2: CardData) => {
    const audio = new Audio(`${process.env.REACT_APP_ASSET_CDN}/success.wav`);
    audio.play().catch((err) => console.log("Audio play error:", err));

    setCards((prev) =>
      prev.map((card) =>
        card.id === c1.id || card.id === c2.id
          ? { ...card, matched: true }
          : card
      )
    );
    setMatchedCount((m) => m + 1);
  };

  const handleCardClick = (card: CardData) => {
    if (card.matched || incorrectSelection.length > 0) return;

    if (selectedCards.length === 0) {
      setSelectedCards([card]);
    } else if (selectedCards.length === 1) {
      const [firstCard] = selectedCards;
      const newSelection = [firstCard, card];
      setSelectedCards(newSelection);

      if (
        firstCard.id !== card.id &&
        firstCard.type !== card.type &&
        isMatch(firstCard, card)
      ) {
        // Delay so second card is shown as flipped
        setTimeout(() => {
          matchCards(firstCard, card);
          setSelectedCards([]);
        }, 500);
      } else {
        // Incorrect selection
        setIncorrectSelection(newSelection);
        setTimeout(() => {
          setIncorrectSelection([]);
          setSelectedCards([]);
        }, 1000);
      }
    }
  };

  return (
    <>
      <div>
        <BackToResources
          isBetaVersion={true}
          sessionType={"Test Your Knowledge"}
        />
      </div>
      <div className="bg-primary relative w-[87vw] h-[75vh] md:w-[80vw] overflow-hidden rounded-xl mt-3">
        {startTime && (
          <div className="text-center z-10 text-white rounded px-2 py-1">
            <Timer
              startTime={startTime}
              running={matchedCount < 6}
              mode="beast"
            />
          </div>
        )}
        <CardGrid
          cards={cards}
          selectedCards={selectedCards}
          incorrectSelection={incorrectSelection}
          mode="beast"
          onCardClick={handleCardClick}
        />
      </div>
    </>
  );
};

export default BeastGame;
