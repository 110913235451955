// WrittenAnswerPrompt.tsx
import React, { useState, useEffect } from "react";
import { Prompt } from "./types";
import SubmitButton from "../VocabDrills/SubmitButton";

interface WrittenAnswerPromptProps {
  prompt: Prompt;
  beastMode: boolean;
  onBeastModeToggle: () => void;
  onNext: (isCorrect: boolean) => void;
  handleRequestRemoveWord?: any;
}

const WrittenAnswerPrompt: React.FC<WrittenAnswerPromptProps> = ({
  prompt,
  beastMode,
  onBeastModeToggle,
  onNext,
  handleRequestRemoveWord,
}) => {
  const [userAnswer, setUserAnswer] = useState<string>("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  useEffect(() => {
    setUserAnswer("");
    setHasSubmitted(false); // Reset when new prompt arrives
  }, [prompt._id]);

  // Function to normalize user input
  const normalizeInput = (input: string) => {
    return input
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[\s.,/#!$%^&*;:{}=_`~()]/g, "")
      .toLowerCase();
  };

  const handleSubmit = () => {
    if (hasSubmitted) return; // Prevent double submission
    setHasSubmitted(true);

    const normalizedUserAnswer = normalizeInput(userAnswer);
    let isCorrect = false;

    if (beastMode) {
      // Strict mode: exact match except for case differences
      const lowerUserAnswer = userAnswer.trim().toLowerCase();
      isCorrect =
        prompt.strictModeAnswers?.some(
          (syn) => syn.toLowerCase() === lowerUserAnswer
        ) || false;
    } else {
      // Non-strict mode: match with alternateAnswers (normalized)
      const normalizedAlternates =
        prompt.alternateAnswers?.map((ans) => normalizeInput(ans)) || [];
      const normalizedStrictAnswers =
        prompt.strictModeAnswers?.map((ans) => normalizeInput(ans)) || [];

      isCorrect =
        normalizedAlternates.includes(normalizedUserAnswer) ||
        normalizedStrictAnswers.includes(normalizedUserAnswer);
    }

    // Proceed to the next prompt after a delay
    setTimeout(() => {
      setUserAnswer("");
      setHasSubmitted(false); // Reset when new prompt arrives
      onNext(isCorrect);
    }, 1000);
  };

  // Clear the input field when the prompt changes
  useEffect(() => {
    setUserAnswer("");
  }, [prompt._id]);

  console.log("hasSubmitted", hasSubmitted);

  return (
    <>
      <p className="text-gray-400 mt-4 md:text-2xl text-center mb-6">
        Translate:{" "}
        <span className="font-semibold text-lingawa-primary">
          {prompt.word.englishTranslation}
        </span>
      </p>
      <div className="flex flex-col md:flex-row md:gap-8 justify-between">
        {/* image */}
        {/* Display image if available */}
        {prompt.word.imageUrl && (
          <div className="flex justify-center mb-4 p-3  border-[2px] border-gray-200 rounded-xl">
            <img
              src={prompt.word.imageUrl}
              alt={prompt.word.englishTranslation}
              className="max-h-72 object-contain rounded-lg shadow-md"
            />
          </div>
        )}

        {/* Mode Toggle */}

        <div>
          <div className="flex gap-4 bg-orange-100 border-[2px] border-orange-200 p-4 rounded-xl max-h-32">
            {/* toggle and text */}
            <div>
              <div className="flex space-x-2">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={beastMode}
                    onChange={onBeastModeToggle}
                  />
                  <div
                    className="w-10 h-5 bg-lingawa-primary rounded-full  relative after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:border-gray-300 after:rounded-full after:h-4 after:w-4 after:transition-all 
            peer-checked:bg-orange-500 peer-checked:after:translate-x-full peer-checked:after:border-white"
                  />
                </label>
                <div className="flex items-center space-x-2">
                  <h2 className="text-xs font-bold text-primary">
                    {beastMode ? "Beast Mode" : "Normal Mode"}
                  </h2>
                </div>
              </div>

              <div className="mt-2 max-w-[250px] text-primary text-xs italic">
                {beastMode
                  ? "Only perfect answers — no mercy on typos or accents! 🦾"
                  : "Stay calm and confident. You've got this! ✨"}
              </div>
            </div>

            {/* illustration */}
            <div>
              <img
                src={
                  beastMode
                    ? `${process.env.REACT_APP_ASSET_CDN}/beast-mode.png`
                    : `${process.env.REACT_APP_ASSET_CDN}/normal-mode.png`
                }
                alt="game_mode"
                className="max-h-24 object-contain"
              />
            </div>
          </div>

          <div className="md:mt-4 mt-6 md:mb-0 mb-10">
            <input
              type="text"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              placeholder="Type your answer here..."
              className={`
            w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 
            ${beastMode ? "focus:ring-lingawa-teal" : "focus:ring-orange-500"}
          `}
            />
          </div>
        </div>
      </div>

      <SubmitButton
        handleRequestRemoveWord={handleRequestRemoveWord}
        handleSubmit={handleSubmit}
        selectedOption={userAnswer}
        hasSubmitted={hasSubmitted}
      />
    </>
  );
};

export default WrittenAnswerPrompt;
