import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { FcGoogle } from "react-icons/fc";
import { MdLockOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthApi } from "src/api/auth.api";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";
import { getClientTimeZone } from "src/services/client";
import { AuthType } from "src/views/Authentication/Login";
import LoadingScreen from "../LottieAnimations/SpinningRose";

interface IAuthOptions {
  setAuthType: Dispatch<SetStateAction<AuthType | null>>;
  flow: "login" | "signup";
  children?: ReactNode;
}

export default function AuthOptions({
  setAuthType,
  flow,
  children,
}: IAuthOptions) {
  const [user, setUser] = useState<any>(null);
  const [, setProfile] = useState<any>(null);
  const [, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    state,
    actions: { getOnboarding, updateOnboarding, setIsLoading },
  } = useContext(DirectPayContext);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      if (
        state.leadFunnel === "direct-to-pay-flow-1" ||
        state.leadFunnel === "direct-to-pay-flow-3"
      ) {
        setIsLoading(true);
        setAuthType("google");
      } else {
        setLoading(true);
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          setProfile(res.data);
          try {
            const response = await AuthApi.googleSignIn({
              dp: res.data.picture,
              email: res.data.email,
              firstName: res.data.given_name,
              lastName: res.data.family_name,
              ...(state?.leadFunnel ? { leadFunnel: state?.leadFunnel } : {}),
              timeZone: await getClientTimeZone(),
              onboardingFields: {
                numberOfUsers: state.numberOfUsers,
                whoLessonIsFor: state.whoLessonIsFor,
                proficiencyLevel: state.proficiencyLevel,
                lessonFrequency: state.lessonFrequency,
                wordsThatDescribe: state.wordsThatDescribe,
              },
            });

            localStorage.setItem("accessToken", response?.data?.user?.token);

            dispatch({
              type: "SET_USER",
              user: response?.data?.user,
            });

            if (flow === "signup" && state.directPayUser) {
              if (
                state.leadFunnel === "direct-to-pay-flow-1" ||
                state.leadFunnel === "direct-to-pay-flow-3"
              ) {
                await updateOnboarding(
                  { onboarding: state },
                  response?.data?.onboarding?._id
                );
              } else {
                await updateOnboarding(
                  {
                    onboarding: { ...response?.data?.onboarding, ...state },
                  },
                  "",
                  "google"
                );
              }
              if (
                state.leadFunnel === "direct-to-pay-flow-1" ||
                state.leadFunnel === "direct-to-pay-flow-3"
              ) {
                navigate(routes.DIRECT_TO_PAY_MEET_TUTORS.url);
              } else {
                navigate(routes.DIRECT_TO_PAY_WELCOME_B.url + "/payment-plans");
              }
            }
            if (flow === "login") {
              if (response?.data?.user?.type === "student") {
                await getOnboarding(response.data.user._id).catch((err) =>
                  console.log(err)
                );
              }
            }
            toast.success(
              flow.charAt(0).toUpperCase() + flow.slice(1) + " was successful",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          } catch (error: any) {
            toast.error(
              error?.response?.data?.message ??
                error?.message ??
                "An error occurred",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          } finally {
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <div className="text-center py-4 text-primary mt-48 sm:mt-0">
      {children}
      <button
        onClick={() => {
          login();
        }}
        className="mx-auto border-primary hover:shadow-md border p-2 sm:p-4 rounded-md flex flex-wrap min-w-full justify-center sm:gap-5 gap-2 items-center font-medium "
      >
        <FcGoogle size={24} />
        {flow === "login" ? "Sign in " : "Sign up "}
        with Google
      </button>
      <div className="flex gap-4 items-center mt-4">
        <span className="h-[1px] w-full bg-gray-300 block flex-1"></span>
        <p>or</p>
        <span className="h-[1px] w-full bg-gray-300 block flex-1"></span>
      </div>
      <button
        onClick={() => {
          setAuthType("email");
        }}
        data-test="authWithEmailButton"
        className="mt-4 mx-auto bg-lingawa-primary hover:bg-primary-light text-white p-2 sm:px-4 sm:py-4 rounded-md flex flex-wrap justify-center min-w-full sm:gap-5 gap-2 items-center font-medium"
      >
        <MdLockOutline size={24} />
        {flow === "login" ? "Log in " : "Sign up "}
        with Email
      </button>
    </div>
  );
}
