// FlashcardSection.tsx
import React, { useState } from "react";
import { Prompt } from "./types";
import FlashcardPrompt from "../TestYourKnowledge/FlashcardPrompt";

interface FlashcardSectionProps {
  /**
   * The array of flashcard prompts for this section.
   */
  prompts: Prompt[];

  /**
   * Called when the section is fully done (all prompts passed).
   */
  onFinish: () => void;

  /**
   * If the user chooses "I already know this word,"
   * we can remove that prompt from the session.
   */

  /**
   * A setter from the parent that merges newly completed word IDs.
   * Usually typed: React.Dispatch<React.SetStateAction<string[]>>
   */
  setCompletedWordIds: React.Dispatch<React.SetStateAction<string[]>>;

  /**
   * Called every time a prompt is completed (didKnow: boolean).
   * The parent might use it to increment progress, track streaks, etc.
   */
  onPromptComplete: (didKnow: boolean) => void;
  setCurrentWordId?: React.Dispatch<React.SetStateAction<string | null>>;
  setCurrentWordProficiency?: any;
  handleRequestRemoveWord?: any;
}

const FlashcardSection: React.FC<FlashcardSectionProps> = ({
  prompts,
  onFinish,
  handleRequestRemoveWord,
  setCompletedWordIds,
  onPromptComplete,
  setCurrentWordId,
  setCurrentWordProficiency,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [failedQueue, setFailedQueue] = useState<Prompt[]>([]);

  /**
   * Called by each flashcard prompt when the user chooses
   * "Learned" (didKnow=true) or "Still Learning" (didKnow=false).
   */
  const handleNext = (didKnow: boolean) => {
    const currentPrompt = prompts[currentIndex];

    if (didKnow) {
      // If user passes => add wordId to completed
      const wordId = currentPrompt.word?._id;
      if (wordId) {
        setCompletedWordIds((prev) =>
          prev.includes(wordId) ? prev : [...prev, wordId]
        );
      }
    } else {
      // If user fails => push prompt to the failedQueue
      setFailedQueue((prev) => [...prev, currentPrompt]);
    }

    // Inform parent that a prompt was completed
    onPromptComplete(didKnow);

    // Move to the next prompt in the main list
    setCurrentIndex((prev) => prev + 1);
  };

  /**
   * If we've reached the end of the main prompts array,
   * we check if there are any fails to re-queue infinitely.
   */
  if (currentIndex >= prompts.length) {
    if (failedQueue.length > 0) {
      // Switch to a re-queue phase
      return (
        <ReQueueFlashcards
          queue={failedQueue}
          onDone={onFinish}
          handleRequestRemoveWord={handleRequestRemoveWord}
          setCompletedWordIds={setCompletedWordIds}
          onPromptComplete={onPromptComplete}
          setCurrentWordProficiency={setCurrentWordProficiency}
          setCurrentWordId={setCurrentWordId}
        />
      );
    } else {
      // All prompts eventually passed => we are done
      onFinish();
      return null;
    }
  }

  // The current prompt in the main pass
  const currentPrompt = prompts[currentIndex];
  if (setCurrentWordId) setCurrentWordId(currentPrompt.word?._id);

  return (
    <div>
      {/* 
        If you want a "I already know this word" button here, 
        for the main pass, you could do:
        
        <button onClick={() => onRemovePrompt(currentPrompt._id)}>
          I already know this word
        </button>
      */}

      <FlashcardPrompt
        prompt={currentPrompt}
        onNext={handleNext}
        setCurrentWordProficiency={setCurrentWordProficiency}
        handleRequestRemoveWord={handleRequestRemoveWord}
      />
    </div>
  );
};

/**
 * A sub-component that handles re-serving any failed prompts
 * infinitely until the user passes them.
 */
interface ReQueueProps {
  queue: Prompt[];
  onDone: () => void;
  handleRequestRemoveWord: () => void;
  setCompletedWordIds: React.Dispatch<React.SetStateAction<string[]>>;
  onPromptComplete: (didKnow: boolean) => void;
  setCurrentWordProficiency?: React.Dispatch<React.SetStateAction<number>>;
  setCurrentWordId?: any;
}

const ReQueueFlashcards: React.FC<ReQueueProps> = ({
  queue: initialQueue,
  onDone,
  setCompletedWordIds,
  onPromptComplete,
  setCurrentWordProficiency,
  handleRequestRemoveWord,
  setCurrentWordId,
}) => {
  // We'll store the queue in local state for indefinite re-cycling
  const [queue, setQueue] = useState<Prompt[]>(initialQueue);
  const [index, setIndex] = useState<number>(0);

  /**
   * Called by each flashcard in the re-queue phase
   */
  const handleNext = (didKnow: boolean) => {
    const currentPrompt = queue[index];

    if (didKnow) {
      // If user passes => remove from the queue
      const wordId = currentPrompt.word?._id;
      if (wordId) {
        setCompletedWordIds((prev) =>
          prev.includes(wordId) ? prev : [...prev, wordId]
        );
      }

      setQueue((prev) => {
        const newArr = [...prev];
        newArr.splice(index, 1);
        return newArr;
      });
    } else {
      // If user fails => move this prompt to the end of the queue
      setQueue((prev) => {
        const newArr = [...prev];
        const item = newArr[index];
        newArr.splice(index, 1);
        newArr.push(item);
        return newArr;
      });
    }

    onPromptComplete(didKnow);

    // We'll fix the "single item" hang by resetting index if needed.
    setIndex((prev) => {
      // If the queue is now empty => we'll handle it in the next render
      // If there's only 1 item or we removed an item, let's reset to 0
      return 0;
    });
  };

  // If the queue is empty => user eventually passed all
  if (queue.length === 0) {
    onDone();
    return null;
  }

  // If index >= queue.length => reset index to 0
  if (index >= queue.length) {
    setIndex(0);
    return null;
  }

  const prompt = queue[index];
  if (setCurrentWordId) setCurrentWordId(prompt.word?._id);

  return (
    <div>
      {/* 
        If you want "I already know this word" in re-queue as well:
        <button onClick={() => onRemovePrompt(prompt._id)}>
          I already know this word
        </button>
      */}

      <FlashcardPrompt
        prompt={prompt}
        onNext={handleNext}
        setCurrentWordProficiency={setCurrentWordProficiency}
        handleRequestRemoveWord={handleRequestRemoveWord}
      />
    </div>
  );
};

export default FlashcardSection;
