import React from "react";
import Modal from "src/components/Shared/Modal";

interface ModeSelectionModalProps {
  onSelectMode: (mode: "normal" | "beast") => void;
}

const ModeSelectionModal: React.FC<ModeSelectionModalProps> = ({
  onSelectMode,
}) => {
  console.log("ModeSelectionModalProps");
  return (
    <Modal>
      <h2 className="text-xl text-white text-center font-bold mb-4">
        Choose Your Challenge!
      </h2>
      <div className="flex justify-center mb-4 mt-4">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/matching-game-image.png`}
          alt={"Select Mode"}
          className="max-h-40 object-contain rounded"
        />
      </div>
      <p className="mb-4 text-white text-center text-sm">
        <span className="font-semibold">Normal Mode:</span> All words are
        visible from the start.
      </p>
      <p className="mb-4 text-white text-center text-sm">
        <span className="font-semibold">Beast Mode:</span> Cards start
        face-down, testing your memory and focus.
      </p>
      <div className="flex gap-4 justify-center mt-2">
        <button
          onClick={() => onSelectMode("normal")}
          className="px-4 py-2 bg-lingawa-primary-light text-white rounded-lg hover:bg-lingawa-primary transition-colors"
        >
          Normal Mode
        </button>
        <button
          onClick={() => onSelectMode("beast")}
          className="px-4 py-2 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition-colors"
        >
          Beast Mode
        </button>
      </div>
    </Modal>
  );
};

export default ModeSelectionModal;
