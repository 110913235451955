import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LearningAppApi } from "src/api/learning-app";
import { Word } from "./types";
import ModeSelectionModal from "./ModeSelectionModal";
import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
import NormalGame from "./NormalGame";
import BeastGame from "./BeastGame";
import Leaderboard from "./Leaderboard";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";

export default function MatchingGame() {
  const navigate = useNavigate();
  const { userId, topicId, level, order, topic } = useParams();
  const [words, setWords] = useState<Word[]>([]);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState<"" | "normal" | "beast">("");
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [currentScore, setCurrentScore] = useState<number | null>(null);
  const [{ user }] = useStateValue();
  const { state } = useLocation();
  const route =
    user?.type === "student"
      ? routes.STUDENT_DASHBOARD_LEARN.url
      : routes.TUTOR_DASHBOARD_LEARN.url;
  const getMatchingGameWords = useCallback(async () => {
    if (topicId) {
      setLoading(true);
      const response = await LearningAppApi.getMatchingGameWords(topicId);
      setWords(response.data);
      setLoading(false);
    }
  }, [topicId]);

  useEffect(() => {
    getMatchingGameWords();
  }, [getMatchingGameWords]);

  const handleModeSelect = (selectedMode: "normal" | "beast") => {
    setMode(selectedMode);
  };

  const handleGameOver = (timeTaken: number) => {
    setCurrentScore(timeTaken);
    setShowLeaderboard(true);
  };

  // New handlePlayAgain: Reset game state and start a new session with same mode
  const handlePlayAgain = () => {
    // Hide leaderboard
    setShowLeaderboard(false);
    // Reset currentScore
    setCurrentScore(null);
    // Re-fetch words to start a fresh game
    getMatchingGameWords();
    // Keep the same mode. The component logic will display NormalGame or BeastGame as soon as words are ready.
  };

  const handleReturnToTopicMenu = () => {
    navigate(`${route}/${userId}/${level}/${order}/${topic}/${topicId}`, {
      state: { curriculum: state?.curriculum, ageGroup: state?.ageGroup },
    });
  };

  if (loading) {
    return <LoadingBarScreen />;
  }

  if (!mode) {
    // Words are loaded, but no mode selected yet
    return <ModeSelectionModal onSelectMode={handleModeSelect} />;
  }

  if (!userId || !topicId) {
    return <div>Missing userId or topicId</div>;
  }

  // If showing leaderboard
  if (showLeaderboard && currentScore !== null) {
    return (
      <div className="bg-primary rounded-xl">
        <Leaderboard
          userId={userId}
          topicId={topicId}
          mode={mode}
          currentScore={currentScore}
          // Pass handlePlayAgain and another callback (like handleReturnToTopicMenu) to Leaderboard
          onPlayAgain={handlePlayAgain}
          onReturnToTopicMenu={handleReturnToTopicMenu}
        />
      </div>
    );
  }

  // Mode selected and not showing leaderboard, render game
  return mode === "normal" ? (
    <NormalGame
      words={words}
      userId={userId}
      topicId={topicId}
      onGameOver={handleGameOver}
    />
  ) : (
    <BeastGame
      words={words}
      userId={userId}
      topicId={topicId}
      onGameOver={handleGameOver}
    />
  );
}
