import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import assets from "src/assets/assets";
import useResponsive from "src/hooks/useResponsive";
import BaseCard, { BaseCardButton, BaseCardText } from "./BaseCard";

const DailyWorkoutCard = ({
	resource,
	header,
	userId,
	route,
	btnLabel,
	altImg,
}: any) => {
	const navigate = useNavigate();
	const _mob = useResponsive();

	const _stl = "w-[80px] h-[80px] object-cover rounded-lg absolute";

	const prp = { className: "text-white" };

	const cardProps = {
		padding: 10,
		...(!_mob ? { w: 390 } : { style: { width: "75vw" } }),
	};

	return (
		<BaseCard {...cardProps}>
			<div className="bg-[#f8f8f8] rounded-lg p-5 flex flex-col justify-between relative overflow-hidden">
				{altImg ? (
					<div className="grid place-items-centers">
						<div className="h-[100%] relative">
							<img
								src={assets.dArm}
								className={clsx(_stl)}
								style={{ top: "0px", left: "50px" }}
							/>
							<img
								src={assets.dMoney}
								className={clsx(_stl)}
								style={{ top: "10px", left: "120px" }}
							/>
							<img
								src={assets.dDog}
								className={clsx(_stl)}
								style={{ top: "0px", right: "50px" }}
							/>
						</div>
					</div>
				) : (
					<>
						{resource?.imageUrl && (
							<div
								className="absolute inset-0 bg-cover bg-center"
								style={{
									backgroundImage: `url(${resource.imageUrl})`,
								}}
							/>
						)}
					</>
				)}

				<div
					className="absolute inset-0"
					style={{
						backgroundColor: "#6B0D4A",
						opacity: 0.46,
					}}
				/>

				<div className="flex justify-between mb-5 gap-2" style={{ zIndex: 1 }}>
					<div className="grid place-items-center w-[100%]">
						<BaseCardText t={header} lg bold center {...prp} />
					</div>
				</div>

				<div className="grid place-items-center"></div>

				<BaseCardButton
					onClick={() => navigate(route)}
					leadingIcon={assets.dRedo}
					title={btnLabel ?? resource?.title}
					className="z-10"
				/>
			</div>
		</BaseCard>
	);
};

export default DailyWorkoutCard;
