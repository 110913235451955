// IAlreadyKnowPopup.tsx
import React, { useEffect, useState } from "react";

interface IAlreadyKnowPopupProps {
  /** Whether the popup is visible */
  visible: boolean;

  /** Text to show, e.g., "Word removed!" or something fun */
  message?: string;

  /** Called after the popup auto-hides, or can be used to close manually */
  onClose: () => void;
}

/**
 * A fun, gamified popup that slides in from bottom-right
 * with a big round image, some text, and disappears after ~2s (or user call).
 */
const IAlreadyKnowPopup: React.FC<IAlreadyKnowPopupProps> = ({
  visible,
  message = "Word Removed!",
  onClose,
}) => {
  const [shouldRender, setShouldRender] = useState(visible);
  const imageUrl = `${process.env.REACT_APP_ASSET_CDN}/mascot-excited-badge.png`;

  // Watch "visible" prop changes; if true => set shouldRender
  // if false => set a timer to remove from DOM after transition
  useEffect(() => {
    if (visible) {
      setShouldRender(true);
    } else {
      // If we want it to slide out, we can keep it rendered for a short time
      // then remove from DOM if needed
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300); // match your transition duration
      return () => clearTimeout(timer);
    }
  }, [visible]);

  // Auto-close after 2 seconds if you want automatic removal
  // comment this out if you only want manual close
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [visible, onClose]);

  // If it's not rendered, return null
  if (!shouldRender) return null;

  // We apply a tailwind class that sets position, transitions, etc.
  // We'll toggle "translate-x-0" vs "translate-x-full" based on `visible`.
  // We also might use "translate-y-4" or something if you want a small vertical shift.
  return (
    <div
      className={`
        fixed 
        bottom-6 
        right-6
        z-50
        flex
        items-center
        gap-4
        p-4
        w-64
       bg-gradient-to-br from-orange-600 to-lingawa-teal
        rounded-xl
        shadow-lg
        transition-transform
        duration-300
        ${
          visible
            ? "translate-x-0 opacity-100" // slide in
            : "translate-x-full opacity-0 pointer-events-none" // slide out
        }
      `}
    >
      <img
        src={imageUrl}
        alt="Gamified"
        className="w-14 h-14 rounded-full object-cover"
      />
      <div className="flex flex-col">
        <span className="font-bold text-white text-sm">{message}</span>
        <span className="text-xs text-gray-600 italic">Word removed</span>
      </div>
    </div>
  );
};

export default IAlreadyKnowPopup;
