import axios from "axios";

export async function queryDialect(query: string): Promise<any> {
	try {
		let response = await axios.get(
			`${process.env.REACT_APP_V2_SERVER_URL}/utility/query-dialects?q=${query}`
		);
		return response.data;
	} catch (error) {
		console.log("caught error: ", error);
	}
}
