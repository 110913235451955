import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "src/Routes";

import assets from "src/assets/assets";
import { useStateValue } from "src/context/StateProvider";

export default function Profile() {
	const location = useLocation();
	const [{ showMobileMenu }, dispatch] = useStateValue();

	const closeMobileMenu = async () => {
		await dispatch({
			type: "SET_SHOW_MOBILE_MENU",
			showMobileMenu: !showMobileMenu,
		});
	};

	// useEffect(() => {
	// 	if (
	// 		// routes.STUDENT_DASHBOARD_BIO.url === location.pathname ||
	// 		routes.STUDENT_DASHBOARD_SETTINGS.url === location.pathname ||
	// 		routes.STUDENT_DASHBOARD_OVERVIEW.url === location.pathname ||
	// 		routes.STUDENT_DASHBOARD_ACADEMIC_INFO.url === location.pathname ||
	// 		routes.STUDENT_DASHBOARD_PAYMENTS.url === location.pathname
	// 	) {
	// 		setShowProfileMenu(true);
	// 	} else {
	// 		setShowProfileMenu(false);
	// 	}
	// }, [location.pathname]);

	return (
		<>
			<NavLink
				to={routes.STUDENT_DASHBOARD_OVERVIEW.url}
				onClick={closeMobileMenu}
			>
				<div
					className={clsx("parent-menu-inactive", {
						"parent-menu-active":
							// routes.STUDENT_DASHBOARD_BIO.url === location.pathname ||
							routes.STUDENT_DASHBOARD_SETTINGS.url === location.pathname ||
							routes.STUDENT_DASHBOARD_OVERVIEW.url === location.pathname ||
							routes.STUDENT_DASHBOARD_ACADEMIC_INFO.url ===
								location.pathname ||
							routes.STUDENT_DASHBOARD_PAYMENTS.url === location.pathname,
					})}
					// onClick={toggleProfileMenu}
				>
					<div className="flex gap-4 items-center">
						<div>
							{/* <FaRegUserCircle className="text-lg" /> */}
							<img src={assets.sHome} className="w-[30px]" />
						</div>
						<div className="text-[16px]">Home</div>
					</div>
					{/* <div className="flex items-center">
					<div
						className={clsx({
							hidden: !showProfileMenu,
							block: showProfileMenu,
						})}
					>
						<BiChevronUp size={23} />
					</div>
					<div
						className={clsx({
							block: !showProfileMenu,
							hidden: showProfileMenu,
						})}
					>
						<BiChevronDown size={23} />
					</div>
				</div> */}
				</div>
			</NavLink>

			{/* Sub Menu */}
			{/* <div
				className={clsx({
					hidden: !showProfileMenu,
					"block bg-gray-50 rounded-lg py-4 mb-2": showProfileMenu,
				})}
			>
				<ul>
					<li>
						<StudentOverview />
					</li>
					<li>
						<Bio />
					</li>
					<li>
						<AcademicInfo />
					</li>
					<li>
						<Settings />
					</li>
				</ul>
			</div> */}
		</>
	);
}
