import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
import Breadcrumb from "src/components/StudentDashboard/Learn/Breadcrumb";
import { useStateValue } from "src/context/StateProvider";
import {
  LessonResources,
  lessonResources,
} from "src/static-data/lessonResources";
import { VideoComponent } from "./VideoComponent";
import Card from "src/components/StudentDashboard/Learn/Card";
import CardContainer from "src/components/StudentDashboard/Learn/CardContainer";
import Group from "src/components/StudentDashboard/Learn/Group";
import { CanvaEmbed } from "src/components/StudentDashboard/Learn/CanvaEmbed";
import GamePage from "src/views/MiniGames/ScratchGames/GamePage";

export default function SessionDetails() {
  const { state }: any = useLocation();
  const { level, topic, topicId } = useParams();

  const [{ userLessonProgress }] = useStateValue();
  const prerequisites = userLessonProgress?.unordered?.find(
    (t: any) => t?.topicId === topicId
  )?.prerequisites;
  const practiceVideo = userLessonProgress?.unordered?.find(
    (t: any) => t?._id === topicId
  )?.practiceVideo;
  const funVideo = userLessonProgress?.unordered?.find(
    (t: any) => t?._id === topicId
  )?.funVideo;
  const grammarSpotlight = userLessonProgress?.unordered?.find(
    (t: any) => t?._id === topicId
  )?.grammarSpotlight;
  const lessonPlan = userLessonProgress?.unordered?.find(
    (t: any) => t?._id === topicId
  )?.lessonPlan;
  let miniGame = userLessonProgress?.unordered?.find(
    (t: any) => t?._id === topicId
  )?.miniGame;
  const scratchGameRegex = /scratch\.mit\.edu\/projects\/(\d+)\/fullscreen/;
  const match = miniGame?.match(scratchGameRegex);
  if (match) {
    const projectId = match[1];
    miniGame = projectId;
  }

  const groupedPrerequisites = prerequisites?.reduce((acc: any, curr: any) => {
    if (acc[curr?.level]) {
      acc[curr?.level].push(curr);
    } else {
      acc[curr?.level] = [curr];
    }
    return acc;
  }, {});

  return (
    <section className="text-primary">
      <Breadcrumb />
      {state?.id === 3 && <VideoComponent practiceVideo={practiceVideo} />}
      {state?.id === 11 && <VideoComponent practiceVideo={funVideo} />}
      {state?.id === 1 && <GrammarSpotlight content={grammarSpotlight} />}
      {state?.id === 13 && (
        <GrammarSpotlight
          content={`https://lingawa.notion.site/ebd/${lessonPlan}`}
        />
      )}
      {state?.id === 10 && <GamePage id={miniGame} />}
      {/* {state?.id === 1 && <GrammarSpotlight content={grammarSpotlight} />} */}
      {state?.id === 6 && groupedPrerequisites && (
        <div className="mt-4">
          {Object.entries(groupedPrerequisites)?.map(([level, topics]: any) => (
            <Group title={level} key={level}>
              {topics?.map((t: any) => {
                return (
                  <Card
                    key={t?.title}
                    topic={t}
                    level={t?.level}
                    topicId={t?._id}
                    curriculumId={t?.curriculumId}
                    parentDivisionId={t?.parentDivisionId}
                  />
                );
              })}
            </Group>
          ))}
        </div>
      )}
    </section>
  );
}

const GrammarSpotlight = ({ content }: any) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust this value as needed
    return () => clearTimeout(timer);
  }, []);
  return (
    // Usage
    loading ? (
      <div className=" flex items-center justify-center">
        <LoadingBarScreen />
      </div>
    ) : (
      <CanvaEmbed canvaLink={content} />
    )
  );
};
