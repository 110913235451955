import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import * as emptyAnimation from "src/assets/animations/empty-loading.json";
import useD2POnboarding from "src/hooks/useD2POnboarding";
import Swal from "sweetalert2";
import { routes } from "src/Routes";

export default function EmptyLessonList({ message, hideButton }: any) {
  const { route } = useD2POnboarding();
  const navigate = useNavigate();
  return (
    <>
      <div className="grid place-items-center h-[75vh]">
        <div className="flex flex-col items-center">
          <div className="-mb-24">
            <h1 className="lg:text-2xl text-primary-light text-center">
              {message}
            </h1>
          </div>
          <Lottie
            animationData={emptyAnimation}
            style={{
              height: 350,
              width: 350,
            }}
          />
          {!hideButton && (
            <button
              type="button"
              onClick={() => {
                if (route) {
                  navigate(route);
                  return;
                } else {
                  navigate(routes.STUDENT_DASHBOARD_BOOKINGS.url);
                }
                // Swal.fire({
                //   color: "#341A64",
                //   title: `<strong >Request Cannot Proceed</strong>`,
                //   html: `
                //   <p>
                //   We are unable to proceed with your request at this time due to missing information. Please contact our support team for assistance.
                //    </p>
                //   `,
                //   showCloseButton: true,
                //   showCancelButton: true,
                //   focusConfirm: false,
                //   confirmButtonText: `
                //   Contact Support
                //   `,
                //   confirmButtonColor: "#D33479",
                // }).then((result) => {
                //   if (result.isConfirmed) {
                //     window.open("mailto:info@lingawa.com", "_blank");
                //   }
                // });
              }}
              className="flex z-0 justify-between gap-2 items-center bg-secondary hover:bg-secondary-light hover:shadow-xl text-white rounded-2xl
    px-8 py-3"
            >
              BOOK A LESSON
            </button>
          )}
        </div>
      </div>
    </>
  );
}
