import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "src/Routes";
import assets from "src/assets/assets";
import { useStateValue } from "src/context/StateProvider";

export default function Learn() {
	const [
		{ userLessonDetails, userLessonProgress, showMobileMenu, user },
		dispatch,
	] = useStateValue();

	const closeMobileMenu = async () => {
		await dispatch({
			type: "SET_SHOW_MOBILE_MENU",
			showMobileMenu: !showMobileMenu,
		});
	};

	const location = useLocation();

	return (
		<div>
			<NavLink to={`${routes.STUDENT_DASHBOARD_LEARN.url}/${user._id}`}>
				<div
					className={clsx(
						{
							"menu-inactive": !location.pathname.includes(
								routes.STUDENT_DASHBOARD_LEARN.url
							),
						},
						{
							"menu-active": location.pathname.includes(
								routes.STUDENT_DASHBOARD_LEARN.url
							),
						}
					)}
					onClick={closeMobileMenu}
				>
					<div className="flex gap-4">
						<div className="flex gap-4 pr-5 items-center">
							<div>
								{/* <LuBrain className="text-lg" /> */}
								<img src={assets.sLearn} className="w-[35px]" />
							</div>
							<div className="text-[16px]  truncate_ w-[100px]_">
								{routes.STUDENT_DASHBOARD_LEARN.label}
							</div>
						</div>
					</div>
				</div>
			</NavLink>
		</div>
	);
}
