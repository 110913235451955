// LearnSession.tsx
import React, { useState, useEffect, useCallback } from "react";
import { Prompt } from "./types";
import CheckpointComponent from "./CheckpointComponent";
import MultipleChoiceSection from "./MultipleChoiceSection";
import AudioMCSection from "./AudioMCSection";
import FlashcardSection from "./FlashcardSection";
import WrittenAnswerSection from "./WrittenAnswerSection";
import EndOfSession from "./EndOfSession";
import { LearningAppApi } from "src/api/learning-app";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "src/context/StateProvider";
import FeedbackPopup from "../FeedbackPopup";
import ProficiencyBadge from "./ProficiencyBadge";
import RemoveWordModal from "./RemoveWordModal";
import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
import BackToResources from "src/components/BackToResources";
import { FaRegCheckCircle } from "react-icons/fa";

import StartScreen from "./StartScreen"; // <-- import your new StartScreen
import { set } from "lodash";

// STREAK LOGIC START: import the StreakPopup
import StreakPopup from "./StreakPopup";
import LessonSummary from "./LessonSummary";
import { routes } from "src/Routes";
import IAlreadyKnowPopup from "./IAlreadyKnowPopup";
import SessionBreadcrumbs from "src/components/SessionBreadcrumbs";
// STREAK LOGIC END

// The possible sections or stages:
type Section =
  | "flashcard"
  | "textMC"
  | "audioMC"
  | "writtenAnswer"
  | "completed"
  | "restart"
  | "summary";

interface FeedbackState {
  feedbackType: "correct" | "incorrect";
  currentPrompt: Prompt;
}

function getSectionStatus(section: Section): number {
  switch (section) {
    case "flashcard":
      return 1;
    case "textMC":
      return 2;
    case "audioMC":
      return 3;
    case "writtenAnswer":
      return 4;
    default:
      return 0;
  }
}

export default function VocabDrills() {
  const navigate = useNavigate();
  const [{ userLessonProgress }] = useStateValue();
  const { level, userId, topic, order, topicId } = useParams();

  // NEW: track if the user has started the session or not
  const [hasStarted, setHasStarted] = useState(false);

  const [flashcardPrompts, setFlashcardPrompts] = useState<Prompt[]>([]);
  const [textMCprompts, setTextMCprompts] = useState<Prompt[]>([]);
  const [audioMCprompts, setAudioMCprompts] = useState<Prompt[]>([]);
  const [writtenPrompts, setWrittenPrompts] = useState<Prompt[]>([]);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>("");
  const [sessionId, setSessionId] = useState<string | null>(null);

  const [feedback, setFeedback] = useState<FeedbackState | null>(null);
  const [currentSection, setCurrentSection] = useState<Section>("flashcard");
  const [checkpointVisible, setCheckpointVisible] = useState<boolean>(false);
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [sessionWords, setSessionWords] = useState<string[]>([]);

  const [completedWordIds, setCompletedWordIds] = useState<string[]>([]);
  const [proficiencyMap, setProficiencyMap] = useState<Record<string, number>>(
    {}
  );
  const [currentWordId, setCurrentWordId] = useState<string | null>(null);
  const [currentWordProficiency, setCurrentWordProficiency] =
    useState<number>(0);

  // remove word confirm
  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
  const [pendingRemoveWordId, setPendingRemoveWordId] = useState<string | null>(
    null
  );

  // PROMPT PROGRESS
  const [completedPromptsCount, setCompletedPromptsCount] = useState(0);
  const [totalPromptsCount, setTotalPromptsCount] = useState(0);
  const progressPercentage =
    totalPromptsCount > 0
      ? Math.round((completedPromptsCount / totalPromptsCount) * 100)
      : 0;

  // STREAK LOGIC START
  // Track consecutive correct answers
  const [streakCount, setStreakCount] = useState<number>(0);
  const [showStreakPopup, setShowStreakPopup] = useState<boolean>(false);
  // STREAK LOGIC END

  const [showKnowPopup, setShowKnowPopup] = useState(false);
  const [removeWordMessage, setRemoveWordMessage] = useState("");
  const [{ user }] = useStateValue();
  const { state } = useLocation();
  const route =
    user?.type === "student"
      ? routes.STUDENT_DASHBOARD_LEARN.url
      : routes.TUTOR_DASHBOARD_LEARN.url;
  useEffect(() => {
    setLanguage(userLessonProgress?.curriculum || "");
  }, [userLessonProgress]);

  const shuffleArray = (array: Prompt[]): Prompt[] => {
    const shuffled = array.sort(() => Math.random() - 0.5);
    return shuffled;
  };

  const getLearnSessionPrompts = useCallback(async () => {
    if (!userId || !topicId || !language) return;

    const response = await LearningAppApi.getLearnSessionPrompts(
      userId,
      topicId,
      language
    );

    if (
      response.data.message ===
      "All words learned or no unlearned words remain."
    ) {
      setCurrentSection("restart");
      setIsLoaded(true);
      return;
    }

    setSessionId(response.data.sessionId || null);
    setCurrentSection(response.data.currentSection || "flashcard");

    const prompts: Prompt[] = response.data.prompts || [];
    setPrompts(prompts);
    const flashcards = prompts.filter((p) => p.type === "flashcard");
    const textMcs = prompts.filter((p) => p.type === "multiple-choice");
    const audioMcs = prompts.filter((p) => p.type === "audio-multiple-choice");
    const written = prompts.filter((p) => p.type === "written-answer");

    setFlashcardPrompts(shuffleArray(flashcards));
    setTextMCprompts(shuffleArray(textMcs));
    setAudioMCprompts(shuffleArray(audioMcs));
    setWrittenPrompts(shuffleArray(written));

    // Get array of unique word objects from all prompts
    const allWords = prompts.map((p) => p.word);
    const uniqueObjects = Array.from(
      new Set(allWords.map((word) => JSON.stringify(word)))
    ).map((word) => JSON.parse(word));
    setSessionWords(uniqueObjects);

    // build proficiency map
    const upArray = response.data.userWordProficiency || [];
    const map: Record<string, number> = {};
    upArray.forEach((prof: { wordId: string; learningStatus: number }) => {
      map[prof.wordId] = prof.learningStatus;
    });
    setProficiencyMap(map);

    // pick a "currentWordId"
    if (flashcards.length > 0 && flashcards[0].word?._id) {
      setCurrentWordId(flashcards[0].word._id);
      setCurrentWordProficiency(map[flashcards[0].word._id]);
    } else if (textMcs.length > 0 && textMcs[0].word?._id) {
      setCurrentWordId(textMcs[0].word._id);
      setCurrentWordProficiency(map[textMcs[0].word._id]);
    }

    // total = sum of all prompts
    let totalCount = 0;
    if (response.data.currentSection === "flashcard") {
      totalCount =
        flashcards.length + textMcs.length + audioMcs.length + written.length;
    } else if (response.data.currentSection === "textMC") {
      totalCount = textMcs.length + audioMcs.length + written.length;
    } else if (response.data.currentSection === "audioMC") {
      totalCount = audioMcs.length + written.length;
    } else if (response.data.currentSection === "writtenAnswer") {
      totalCount = written.length;
    } else {
      totalCount = prompts.length;
    }

    setTotalPromptsCount(totalCount);
    console.log("total prompts", totalCount);

    setIsLoaded(true);
  }, [userId, topicId, language]);

  useEffect(() => {
    getLearnSessionPrompts();
  }, [getLearnSessionPrompts]);

  // This function is called each time the user finishes or moves on from a single prompt
  const handlePromptComplete = (isCorrect: boolean) => {
    if (isCorrect) {
      setCompletedPromptsCount((prev) => prev + 1);

      // STREAK LOGIC START
      setStreakCount((prev) => {
        const newStreak = prev + 1;
        if (newStreak % 5 === 0) {
          setShowStreakPopup(true);
        }
        return newStreak;
      });
      // STREAK LOGIC END
    } else {
      // STREAK LOGIC START
      setStreakCount(0);
      // STREAK LOGIC END
    }
  };

  const handleFinishSection = () => {
    if (currentSection === "flashcard") {
      setCurrentSection("textMC");
      setCheckpointVisible(true);
    } else if (currentSection === "textMC") {
      setCurrentSection("audioMC");
      setCheckpointVisible(true);
    } else if (currentSection === "audioMC") {
      setCurrentSection("writtenAnswer");
      setCheckpointVisible(true);
    } else if (currentSection === "writtenAnswer") {
      setCurrentSection("completed");
    }
  };

  // Called by the section to show feedback.
  // Also calls handlePromptComplete to update progress
  const handleShowFeedback = (isCorrect: boolean, currentPrompt: Prompt) => {
    // every time user finishes a prompt => increment completedPromptsCount
    handlePromptComplete(isCorrect);

    setFeedback({
      feedbackType: isCorrect ? "correct" : "incorrect",
      currentPrompt,
    });

    if (isCorrect && currentPrompt.word?._id) {
      setCompletedWordIds((prev) =>
        prev.includes(currentPrompt.word._id)
          ? prev
          : [...prev, currentPrompt.word._id]
      );

      // increment local currentWordProficiency
      if (currentSection === "textMC") {
        // if prev proficiency is < 2 => increment
        if (proficiencyMap[currentPrompt.word._id] < 2) {
          if (currentPrompt.word?._id) {
            setProficiencyMap((prevMap) => ({
              ...prevMap,
              [currentPrompt.word?._id]:
                (prevMap[currentPrompt.word?._id] ?? 0) + 1,
            }));
          }
        }
      } else if (currentSection === "audioMC") {
        // if prev proficiency is < 3 => increment
        if (proficiencyMap[currentPrompt.word._id] < 3) {
          if (currentPrompt.word?._id) {
            setProficiencyMap((prevMap) => ({
              ...prevMap,
              [currentPrompt.word?._id]:
                (prevMap[currentPrompt.word?._id] ?? 0) + 1,
            }));
          }
        }
      } else if (currentSection === "writtenAnswer") {
        // if prev proficiency is < 4 => increment
        if (proficiencyMap[currentPrompt.word._id] < 4) {
          if (currentPrompt.word?._id) {
            setProficiencyMap((prevMap) => ({
              ...prevMap,
              [currentPrompt.word?._id]:
                (prevMap[currentPrompt.word?._id] ?? 0) + 1,
            }));
          }
        }
      }
    } else if (!isCorrect && currentPrompt.word?._id) {
    }
  };

  const handleFeedbackClose = () => {
    setFeedback(null);
  };

  const handleCheckpointAutoSave = async (): Promise<void> => {
    if (!userId || !topicId || !sessionId) {
      console.error("Missing userId/topicId/sessionId for checkpoint save");
      return;
    }
    const sectionStatus = getSectionStatus(currentSection);
    await LearningAppApi.saveLearningSessionCheckpoint(
      sessionId,
      userId,
      topicId,
      currentSection,
      sectionStatus,
      completedWordIds
    );
    setCompletedWordIds([]);
  };

  const handleCheckpointOverlayClose = () => {
    setCheckpointVisible(false);
  };

  // "I Already Know This Word" => show confirm modal
  const handleRequestRemoveWord = () => {
    if (!currentWordId) return;
    setPendingRemoveWordId(currentWordId);
    setRemoveModalVisible(true);
  };

  // user cancels removing
  const handleCancelRemoveWord = () => {
    setRemoveModalVisible(false);
    setPendingRemoveWordId(null);
  };

  // user confirms removing the word => skip from prompts, mark as learned
  const handleConfirmRemoveWord = async () => {
    if (!pendingRemoveWordId) return;

    setTimeout(() => {
      setFlashcardPrompts((prev) =>
        prev.filter((p) => p.word?._id !== pendingRemoveWordId)
      );
      setTextMCprompts((prev) =>
        prev.filter((p) => p.word?._id !== pendingRemoveWordId)
      );
      setAudioMCprompts((prev) =>
        prev.filter((p) => p.word?._id !== pendingRemoveWordId)
      );
      setWrittenPrompts((prev) =>
        prev.filter((p) => p.word?._id !== pendingRemoveWordId)
      );
    }, 2000);

    setProficiencyMap((prev) => ({
      ...prev,
      [pendingRemoveWordId]: 4,
    }));

    if (sessionId && userId && topicId) {
      try {
        const response = await LearningAppApi.removeWordFromSession(
          userId,
          sessionId,
          pendingRemoveWordId
        );

        // Endpoint should remove word from session and mark as learned
        const updatedPrompts = response.data.session.prompts || [];
        if (updatedPrompts.length === 0) {
          setCurrentSection("completed");
        }
        setRemoveWordMessage(response.data.message);
        setShowKnowPopup(true);
      } catch (err) {
        console.error("Failed to mark word as known in backend:", err);
      }
    }

    setRemoveModalVisible(false);
    setPendingRemoveWordId(null);
  };

  const handleRestartSession = async () => {
    if (!userId || !topicId) {
      console.error("Missing userId/topicId for session restart");
      return;
    }
    setIsLoaded(false);
    const response = await LearningAppApi.restartLearningSession(
      userId,
      topicId
    );
    getLearnSessionPrompts();
  };

  const handleReturnToTopicMenu = () => {
    navigate(`${route}/${userId}/${level}/${order}/${topic}/${topicId}`, {
      state: {
        curriculum: state?.curriculum,
        ageGroup: state?.ageGroup,
      },
    });
  };

  const handleEndSession = async () => {
    if (!sessionId || !userId || !topicId) {
      console.error("Missing sessionId/userId/topicId for session end");
      return;
    }
    const response = await LearningAppApi.endLearningSession(
      userId,
      sessionId,
      topicId
    );
  };

  /**
   * handleStartNextSession:
   * We will reset all counters/trackers and fetch new session data
   * so the user can start a fresh session.
   */
  const handleStartNextSession = async () => {
    // Reset relevant states
    setIsLoaded(false);
    setCompletedWordIds([]);
    setProficiencyMap({});
    setCurrentWordId(null);
    setCurrentWordProficiency(0);
    setCompletedPromptsCount(0);
    setTotalPromptsCount(0);
    setStreakCount(0);
    setShowStreakPopup(false);
    setCurrentSection("flashcard");
    setHasStarted(false);

    // Re-fetch session data
    await getLearnSessionPrompts();
    setIsLoaded(true);
    setHasStarted(true);
  };

  useEffect(() => {
    if (currentSection === "completed") {
      handleEndSession();
    }
  }, [currentSection]);

  // if not loaded => show a loading screen
  if (!isLoaded) {
    return <LoadingBarScreen />;
  }

  const handleGoToSummary = () => {
    setCurrentSection("summary");
  };

  if (currentSection === "restart") {
    return (
      <div>
        <div className="mb-4">
          <BackToResources isBetaVersion={true} sessionType={"Learn Session"} />
        </div>
        <StartScreen handleRestartSession={handleRestartSession} />
      </div>
    );
  }

  if (currentSection === "completed") {
    return (
      <EndOfSession
        sessionWords={sessionWords}
        handleGoToSummary={handleGoToSummary}
      />
    );
  }

  if (currentSection === "summary") {
    return (
      <LessonSummary
        learnedWords={sessionWords}
        onRestart={handleStartNextSession}
        onReturnToMenu={handleReturnToTopicMenu}
      />
    );
  }

  return (
    <>
      <div>
        <SessionBreadcrumbs
          isBetaVersion={true}
          sessionType={"Learn Session"}
        />
      </div>

      <div className="flex justify-center">
        <div className="flex lg:gap-10 gap-4 mt-6 lg:mt-8 items-center">
          <ProficiencyBadge
            wordId={currentWordId || undefined}
            proficiencyMap={proficiencyMap}
            setCurrentWordProficiency={setCurrentWordProficiency}
            setHasStarted={setHasStarted}
          />
          {/* 
        Progress bar at top
      */}
          <div className="my-4">
            <div className="xl:w-[700px] w-[160px] bg-purple-100 h-3 rounded-xl">
              <div
                className="bg-lingawa-primary h-3 rounded-xl"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
          </div>
          <div className="bg-black text-white px-3  py-[5px] h-8 font-semibold rounded-2xl">{`${completedPromptsCount} / ${totalPromptsCount}`}</div>
        </div>
      </div>

      {/* <div className="flex items-center justify-between gap-4 ">
        <div className="flex flex-col items-center"></div>
        <button
          onClick={handleRequestRemoveWord}
          className="flex gap-2 text-center justify-center items-center text-lingawa-primary px-4 py-2 rounded-xl border-2 border-lingawa-primary hover:bg-lingawa-primary hover:text-white"
        >
          <FaRegCheckCircle size={22} />I already know this
        </button>
      </div> */}
      {/* Render your sections */}
      <div
        className={`flex flex-col rounded-lg items-center relative transition-colors duration-500 bg-gradient-to-b white`}
      >
        <div className="w-full max-w-2xl p-4">
          {currentSection === "flashcard" && (
            <FlashcardSection
              prompts={flashcardPrompts}
              onFinish={handleFinishSection}
              setCompletedWordIds={setCompletedWordIds}
              // Whenever user completes a prompt => handleShowFeedback
              handleRequestRemoveWord={handleRequestRemoveWord}
              onPromptComplete={handlePromptComplete}
              setCurrentWordId={setCurrentWordId}
              setCurrentWordProficiency={setProficiencyMap}
            />
          )}
          {currentSection === "textMC" && (
            <MultipleChoiceSection
              prompts={textMCprompts}
              onFinish={handleFinishSection}
              handleRequestRemoveWord={handleRequestRemoveWord}
              onShowFeedback={handleShowFeedback}
              setCurrentWordId={setCurrentWordId}
            />
          )}
          {currentSection === "audioMC" && (
            <AudioMCSection
              prompts={audioMCprompts}
              onFinish={handleFinishSection}
              handleRequestRemoveWord={handleRequestRemoveWord}
              onShowFeedback={handleShowFeedback}
              setCurrentWordId={setCurrentWordId}
            />
          )}
          {currentSection === "writtenAnswer" && (
            <WrittenAnswerSection
              prompts={writtenPrompts}
              onFinish={handleFinishSection}
              handleRequestRemoveWord={handleRequestRemoveWord}
              onShowFeedback={handleShowFeedback}
              setCurrentWordId={setCurrentWordId}
            />
          )}
          {/* FEEDBACK POPUP */}
          {feedback && (
            <FeedbackPopup
              feedbackType={feedback.feedbackType}
              currentPrompt={feedback.currentPrompt}
              onClose={handleFeedbackClose}
            />
          )}
          {/* checkpoint overlay => auto-save */}
          {checkpointVisible && (
            <CheckpointComponent
              visible={checkpointVisible}
              onSaveAndClose={handleCheckpointAutoSave}
              onClose={handleCheckpointOverlayClose}
              message="Checkpoint Reached!"
              score={completedWordIds.length}
            />
          )}
          {/* confirm modal to remove word */}
          <RemoveWordModal
            visible={removeModalVisible}
            onConfirm={handleConfirmRemoveWord}
            onCancel={handleCancelRemoveWord}
            wordId={pendingRemoveWordId}
          />
          <IAlreadyKnowPopup
            visible={showKnowPopup}
            message={removeWordMessage}
            onClose={() => setShowKnowPopup(false)}
          />
        </div>
        {/* STREAK LOGIC START: Conditionally render the StreakPopup */}
        {showStreakPopup && (
          <StreakPopup
            streakCount={streakCount}
            onClose={() => setShowStreakPopup(false)}
          />
        )}
        {/* STREAK LOGIC END */}
      </div>
    </>
  );
}
