import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LearningAppApi } from "src/api/learning-app";
import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
import EmptyList from "src/components/Shared/EmptyList";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";
import BackNavigation from "../../../../components/StudentDashboard/Learn/BackNavigation";
import { FaCheckCircle } from "react-icons/fa";
import { HiOutlineGift } from "react-icons/hi2";
import SessionBreadcrumbs from "src/components/SessionBreadcrumbs";

export default function SentenceContructionSelection() {
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const { order, topicId, userId, dependantId, dependantName } = useParams();
  const [interactiveSessions, setInteractiveSessions] = useState<any>([]);
  const [topic, setTopic] = useState<any>();
  const [level, setLevel] = useState<any>();
  const [userProgress, setUserProgress] = useState<any>();
  const [isComponentLoading, setIsComponentLoading] = useState(true);
  const route =
    user?.type === "student"
      ? routes.STUDENT_DASHBOARD_LEARN.url
      : routes.TUTOR_DASHBOARD_LEARN.url;
  useEffect(() => {
    if (!topicId) return;
    LearningAppApi.getInteractiveSessions(topicId, userId!).then((response) => {
      setInteractiveSessions(response?.data?.sessions);
      setTopic(response?.data?.topic?.title);
      setLevel(response?.data?.level?.title);
      setUserProgress(response?.data?.userProgress);
      setIsComponentLoading(false);
    });
  }, [topicId]);

  console.log("sentence construction selection");
  console.log("interactiveSessions", interactiveSessions);
  console.log("userProgress", userProgress);

  if (isComponentLoading) {
    return <LoadingBarScreen />;
  }

  if (!interactiveSessions?.length) {
    return (
      <div>
        <div>
          <div>
            <SessionBreadcrumbs
              isBetaVersion={false}
              sessionType={"Sentence Construction"}
            />
          </div>
        </div>
        <EmptyList message="Sentence construction coming soon for this topic" />
      </div>
    );
  }

  return (
    <div>
      <div>
        <SessionBreadcrumbs
          isBetaVersion={false}
          sessionType={"Sentence Construction"}
        />
      </div>
      <div className="flex flex-col lg:flex-row gap-4 mt-6 flex-wrap">
        {interactiveSessions?.map((session: any) => (
          <div
            key={session._id}
            className="border-[1px] border-b-4 rounded-2xl hover:cursor-pointer hover:border-lingawa-orange mb-4 w-full lg:w-[350px] lg:min-h-[100px] px-3 py-2 flex flex-col justify-between"
            onClick={() =>
              navigate(
                `${route}/${userId}/${level}/${order}/${topic}/${topicId}${routes.SENTENCE_CONSTRUCTION_SESSION.url}/${session._id}`
              )
            }
          >
            <div>
              <div className="flex gap-4 justify-between">
                <h2 className="text-lingawa-primary font-semibold">
                  {session.title}
                </h2>
                {!userProgress.some(
                  (obj: { sessionId: any }) => obj.sessionId === session._id
                ) && (
                  <div className="flex gap-1 text-xs items-center bg-yellow-500 rounded-2xl py-1 px-2 font-semibold">
                    Earn 50
                    <img
                      src={`${process.env.REACT_APP_ASSET_CDN}/cowry_icon.png`}
                      alt=""
                      className="h-4"
                    />
                  </div>
                )}
                {userProgress.some(
                  (obj: { sessionId: any }) => obj.sessionId === session._id
                ) && (
                  <div>
                    <FaCheckCircle className="text-green-400" size={20} />
                  </div>
                )}
              </div>
              <div>
                <p className="text-gray-500 mt-1 text-xs">
                  {session.description}
                </p>
              </div>
              {userProgress.some(
                (obj: { sessionId: any }) => obj.sessionId === session._id
              ) && (
                <div className="flex gap-1 justify-end mt-4">
                  <div className="flex gap-2 items-center">
                    <HiOutlineGift className="text-gray-400" size={15} />
                    <p className="text-gray-400 text-xxs font-semibold">
                      Earned 50 Cowries
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
