import { useEffect, useState } from "react";
import { StudentApi } from "src/api/student.api";
import { useStateValue } from "src/context/StateProvider";
import RewardCard from "./RewardCard";
import EmptyList from "src/components/Shared/EmptyList";
import LoadingScreen from "src/components/LottieAnimations/LoadingBarScreen";
import { useNavigate, useParams } from "react-router-dom";
import ProfileSwitcher from "src/components/StudentDashboard/ProfileSwitcher";
import { User } from "src/interfaces/direct-pay";
import { routes } from "src/Routes";

export default function Rewards() {
  const [{ user, userNewRewards, dependants }] = useStateValue();
  const [rewards, setRewards] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const { userId } = useParams();
  const [activeDependant, setActiveDependant] = useState<User | null>(null);
  const navigate = useNavigate();

  const getRewardsData = async () => {
    setisLoading(true);
    const response = await StudentApi.getLessonRewards(userId!);
    setisLoading(false);
    setRewards(response.data);
    if (userNewRewards && userNewRewards.length > 0) {
      await StudentApi.postMarKLessonRewardsAsRead(userId!);
    }
  };

  useEffect(() => {
    getRewardsData();
    setActiveDependant(dependants?.find((dep: User) => dep._id === userId));
  }, [userId]);

  // if (isLoading) return <LoadingScreen />;

  // if (!rewards || rewards.length === 0) {
  //   return <EmptyList message="You have no lesson rewards" />;
  // }
  const handleProfileSwitch = (person: User) => {
    if (person._id !== user?._id) {
      setActiveDependant(person);
    }
    navigate(`${routes.STUDENT_DASHBOARD_REWARDS.url}/${person._id}`, {
      replace: true,
    });
  };
  return (
    <>
      {dependants && (
        <div className="z-10 relative px-6">
          <ProfileSwitcher switchHandler={handleProfileSwitch} />
        </div>
      )}
      <div
        className={`border-2 p-6 rounded-2xl relative ${
          dependants && "-top-12 pt-16"
        }`}
      >
        <p className="mt-[18px] text-primary font-bold text-2xl leading-9">
          {activeDependant && activeDependant?.firstname + `'s`} Lesson Rewards
        </p>
        {isLoading ? (
          <LoadingScreen />
        ) : !rewards || rewards.length === 0 ? (
          <EmptyList
            message={`${
              activeDependant ? `${activeDependant?.firstname} has` : "You have"
            } no lesson rewards`}
          />
        ) : (
          <div className="flex gap-4 flex-wrap  mt-6">
            {rewards.map((reward: any) => (
              <div key={reward._id}>
                <RewardCard reward={reward} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
