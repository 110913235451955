import Overview from "./Overview";
import Lessons from "./Lessons";
import Students from "./Students";
import Availability from "./Availability";
import Messages from "./Messages";
import Profile from "./Profile";
import Notifications from "./Notifications";
import Homework from "./Homework";
import ParentResources from "./ParentResources";

export default function Menu() {
  return (
    <div>
      <p
        data-test="tutorSideMenuHeader"
        className="text-xs text-gray-400 px-4 mb-6"
      >
        Tutor Dashboard
      </p>
      <ul>
        <li>
          <Overview />
        </li>
        <li>
          <Profile />
        </li>
        <li>
          <Lessons />
        </li>
        <li>
          <ParentResources />
        </li>
        {/* <li>
          <Students />
        </li> */}
        <li>
          <Availability />
        </li>
        {/* <li>
          <Homework />
        </li> */}
        <li>
          <Messages />
        </li>
        <li>
          <Notifications />
        </li>
      </ul>
    </div>
  );
}
