// PromptFactory.tsx
import React from "react";
import MultipleChoicePrompt from "./MultipleChoicePrompt";
import WrittenAnswerPrompt from "./WrittenAnswerPrompt";
import AudioMultipleChoicePrompt from "./AudioMultipleChoicePrompt";
import FlashcardPrompt from "./FlashcardPrompt";
import { Prompt } from "./types";

interface PromptFactoryProps {
  prompt: Prompt;
  beastMode: boolean;
  onBeastModeToggle: () => void;
  onNext: (isCorrect: boolean) => void;
  handleNextFlashcard: (isCorrect: boolean) => void;
}

const PromptFactory: React.FC<PromptFactoryProps> = ({
  prompt,
  beastMode,
  onBeastModeToggle,
  onNext,
  handleNextFlashcard,
}) => {
  switch (prompt.type) {
    case "multiple-choice":
      return <MultipleChoicePrompt prompt={prompt} onNext={onNext} />;
    case "written-answer":
      return (
        <WrittenAnswerPrompt
          prompt={prompt}
          beastMode={beastMode}
          onBeastModeToggle={onBeastModeToggle || (() => {})}
          onNext={onNext}
        />
      );
    case "audio-multiple-choice":
      return <AudioMultipleChoicePrompt prompt={prompt} onNext={onNext} />;
    case "flashcard":
      // If "onNext" expects a boolean, "didKnow", you can pass it directly
      return <FlashcardPrompt prompt={prompt} onNext={handleNextFlashcard} />;
    default:
      return <div>Unknown prompt type: {prompt.type}</div>;
  }
};

export default PromptFactory;
