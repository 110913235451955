import moment from "moment-timezone";

export default function Subject({ subject }: any) {
  return (
    <div className="flex justify-between items-center mt-2 gap-4 flex-wrap">
      <p className="flex-1">{subject?.name}</p>
      <p className="font-semibold flex-1 text-right ">
        {subject?.schedule
          ?.map((schedule: any) =>
            moment(schedule?.date)
              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .format("dddd, MMMM Do [at] hh:mm a")
          )
          .join(",")}
      </p>
    </div>
  );
}
