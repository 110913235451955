import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import "react-phone-input-2/lib/style.css";
import { BsArrowLeftShort } from "react-icons/bs";
import { routes } from "src/Routes";
import LoginForm from "./LoginForm";
import ForgotPasswordLink from "../ForgotPasswordLink";
import AuthOptions from "src/components/Shared/AuthOptions";
import { useState } from "react";
import { useIsMdScreen } from "src/hooks/useIsMdScreen";

const logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-logo-dark.png`;
const logolight = `${process.env.REACT_APP_ASSET_CDN}/lingawa-logo-light.png`;
const bgImage = `${process.env.REACT_APP_ASSET_CDN}/lingawa-login-bg.png`;

export type AuthType = "google" | "email";
export default function Login() {
  const navigate = useNavigate();
  const isMd = useIsMdScreen();
  const [authType, setAuthType] = useState<AuthType | null>(null);

  return (
    <div
      className="min-h-screen bg-white"
      style={
        isMd
          ? {
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }
          : {}
      }
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {/* Centralise Div */}
      <div className="container md:grid md:place-items-center md:h-screen">
        <div className="flex flex-col gap-10 pt-8 md:pt-0">
          <div
            className="hidden md:flex cursor-pointer items-center justify-center"
            onClick={() => (window.location.href = "https://lingawa.com")}
          >
            <img src={logolight} alt="" width={171} height={135} />
          </div>
          <div
            className="md:hidden cursor-pointer flex items-center justify-center"
            onClick={() => (window.location.href = "https://lingawa.com")}
          >
            <img src={logo} alt="" width={171} height={135} />
          </div>
          {/* White Card */}
          <div className="bg-white md:p-6 rounded-2xl md:shadow-md w-auto md:w-[28rem]">
            {/* Top Nav */}
            <div className="flex justify-between items-center mb-6">
              <p></p>
              <h2 className="text-primary pl-14 font-semibold md:text-lg">
                Log In
              </h2>
              <NavLink to={routes.CHOOSE_A_LANGUAGE.url}>
                <h3 className="text-white bg-secondary rounded-lg p-2 py-1 hover:bg-secondary-light hover:shadow-xl text-xs cursor-pointer">
                  Sign Up
                </h3>
              </NavLink>
            </div>
            {/* Pre-Log In */}
            {!authType && (
              <AuthOptions flow="login" setAuthType={setAuthType} />
            )}
            {authType === "email" && (
              <>
                {/* Form */}
                <LoginForm />
                {/* <div className="mt-4">
            <SocialButtons />
          </div> */}
                <div className="mt-3 flex justify-end">
                  <ForgotPasswordLink />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
