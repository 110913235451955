import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { LessonApi } from "src/api/lesson.api";
import { BaseCardText } from "src/components/Cards/BaseCard";
import DailyWorkoutCard from "src/components/Cards/DailyWorkoutCard";
import GrowTogetherCard from "src/components/Cards/GrowTogetherCard";
import LeaderboardCard from "src/components/Cards/LeaderboardCard";
import UpcomingLessonCard from "src/components/Cards/UpcomingLessonsCard";
import { useStateValue } from "src/context/StateProvider";
import useResponsive from "src/hooks/useResponsive";
import { routes } from "src/Routes";
import { getStudentDashboard } from "src/server/endpoints/student";

export function AppDashboard() {
	const [{ user, dashboard }, dispatch] = useStateValue();
	const [focusedCard, setFocusedCard] = useState(0);
	const scrollContainerRef: any = useRef(null);
	const [userList, setUserList] = useState<any>([]);

	const _mob = useResponsive();

	useEffect(() => {
		init();
	}, []);

	async function init() {
		if (!dashboard) {
			await fetchDashboardData(user?._id);
		}
	}

	async function fetchDashboardData(userId: string) {
		const resources = await LessonApi.getTopics(userId);
		const resp = await getStudentDashboard(userId);
		dispatch({
			type: "SET_DASHBOARD",
			dashboard: {
				...resp?.data,
				allResc: resources?.data,
			},
		});

		if (userList?.length == 0) {
			setUserList([
				{
					_id: user?._id,
					firstname: user?.firstname,
					lastname: user?.lastname,
				},
				...resp?.data?.dependants,
			]);
		}
	}

	const handleScroll = () => {
		const container: any = scrollContainerRef.current;
		if (container) {
			const cards: any = container.children;
			let maxVisibleArea = 0;
			let focusedIndex = 0;

			for (let i = 0; i < cards.length; i++) {
				const card: any = cards[i];
				const rect = card.getBoundingClientRect();
				const visibleArea = Math.max(
					0,
					Math.min(rect.right, window.innerWidth) - Math.max(rect.left, 0)
				);

				if (visibleArea > maxVisibleArea) {
					maxVisibleArea = visibleArea;
					focusedIndex = i;
				}
			}

			console.log(`Focused card index: ${focusedIndex}`);
			setFocusedCard(focusedIndex);
		}
	};

	useEffect(() => {
		const container: any = scrollContainerRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
			handleScroll();
			return () => container.removeEventListener("scroll", handleScroll);
		}
	}, []);

	const _col = _mob ? "1fr" : "390px";
	const _dot = "w-3 h-3 bg-[#e84d17] rounded-full cursor-pointer";

	return (
		<div className="grid">
			<div
				className={clsx(
					"grid bg-white justify-left",
					_mob ? "w-[100%]" : "w-[100%]"
				)}
			>
				<div
					className="flex flex-col gap-5 mb-10"
					style={{ width: _mob ? "87vw" : "100%" }}
				>
					{dashboard?.allResc && (
						<>
							<div className="flex flex-col items-start md:justify-between md:flex-row">
								<BaseCardText
									t="Games & Resources"
									bold
									className="text-[#6B0D4A]"
								/>

								{userList?.length > 0 && (
									<Select
										options={userList.map((dep: any) => ({
											value: dep._id,
											label: `${dep.firstname} ${dep.lastname}`,
										}))}
										onChange={async (selectedOption: any) => {
											await fetchDashboardData(selectedOption.value);
										}}
										className="w-[200px] mt-2 md:mt-0"
										isSearchable={false}
										hideSelectedOptions={false}
										defaultValue={{
											value: userList?.[0]?._id,
											label: `${userList?.[0]?.firstname} ${userList?.[0]?.lastname}`,
										}}
									/>
								)}
							</div>

							<div
								ref={scrollContainerRef}
								className="gap-5 pb-[5px] overflow-x-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-400 scrollbar-track-gray-200 scrollbar-thumb-visible scrollbar-always-show"
								style={{
									display: "grid",
									gridAutoFlow: "column",
									justifyContent: "start",
									width: !_mob ? "70vw" : "100%",
								}}
							>
								{dashboard?.allResc?.[0] && (
									<DailyWorkoutCard
										header={"Practise Your Current Topic"}
										resource={dashboard?.allResc?.[0]}
										userId={user?._id}
										route={`${routes.STUDENT_DASHBOARD_LEARN.url}/${user?._id}/${dashboard?.allResc?.[0]?.level}/${dashboard?.allResc?.[0]?.order}/${dashboard?.allResc?.[0]?.title}/${dashboard?.allResc?.[0]?._id}`}
									/>
								)}
								{dashboard?.allResc?.[1] && (
									<DailyWorkoutCard
										header={"Get Ahead With Your Next Topic"}
										resource={dashboard?.allResc?.[1]}
										userId={user?._id}
										route={`${routes.STUDENT_DASHBOARD_LEARN.url}/${user?._id}/${dashboard?.allResc?.[1]?.level}/${dashboard?.allResc?.[0]?.order}/${dashboard?.allResc?.[1]?.title}/${dashboard?.allResc?.[1]?._id}`}
									/>
								)}
								<DailyWorkoutCard
									header={"Want To Recap Another Topic?"}
									resource={dashboard?.allResc?.[0]}
									route={`${routes.STUDENT_DASHBOARD_LEARN.url}/${user?._id}`}
									btnLabel={"Take your pick!"}
									altImg
								/>
							</div>
						</>
					)}

					{dashboard?.allResc && _mob && (
						<div className="flex justify-center items-center gap-3">
							<div
								className={clsx(
									_dot,
									focusedCard === 0 ? "bg-[#6B0D4A]" : "bg-[lightgrey]"
								)}
							/>
							<div
								className={clsx(
									_dot,
									focusedCard === 1 ? "bg-[#6B0D4A]" : "bg-[lightgrey]"
								)}
							/>
							<div
								className={clsx(
									_dot,
									focusedCard === 2 ? "bg-[#6B0D4A]" : "bg-[lightgrey]"
								)}
							/>
						</div>
					)}
				</div>

				<div className={`${_mob ? "" : "grid"}`}>
					<div
						className={`${_mob ? "flex flex-col" : "grid w-[50%] gap-10"}`}
						style={{ gridTemplateColumns: `${_col} ${_col}` }}
					>
						<div className="flex flex-col gap-5">
							<GrowTogetherCard />
						</div>

						<div className="flex flex-col gap-5">
							{dashboard?.lessons?.length > 0 && (
								<div>
									{dashboard?.lessons?.length > 0 && (
										<UpcomingLessonCard
											lessons={dashboard?.lessons}
											alt
											className="mt-5"
										/>
									)}
								</div>
							)}
						</div>
					</div>

					<div className="flex justify-center w-[full]">
						{dashboard?.leaderboard && (
							<LeaderboardCard
								leaderboard={dashboard?.leaderboard?.leaderboard.map(
									(e: any, idx: number) => ({
										...e,
										rank: idx + 1,
									})
								)}
								createdAt={dashboard?.leaderboard?.createdAt}
								user={user}
								className={clsx("mt-10", _mob ? "w-[100%]" : "min-w-[30%]")}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
