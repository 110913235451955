import React from "react";
import { LuForward } from "react-icons/lu";

export default function SubmitButton({
  handleRequestRemoveWord,
  handleSubmit,
  selectedOption,
  hasSubmitted,
}: any) {
  return (
    <div className="w-full lg:fixed lg:bottom-0 lg:left-64 lg:w-[calc(100%-16rem)] lg:bg-white lg:p-6 lg:border-t">
      <div className="flex md:justify-around justify-between">
        {handleRequestRemoveWord && (
          <button
            onClick={handleRequestRemoveWord}
            className="py-2 px-4 rounded-xl flex justify between gap-4 font-semibold border-[1px] border-b-4 hover:border-green-500 border-l-4 transition-transform transform"
          >
            <p className="hidden md:block">Already know this</p>
            <p>
              <LuForward size={22} />
            </p>
          </button>
        )}
        <button
          onClick={handleSubmit}
          disabled={!selectedOption || hasSubmitted}
          className={`
                py-2 px-4 rounded-xl font-semibold border-[1px] border-b-4 border-l-4 transition-transform transform 
                ${
                  selectedOption
                    ? "bg-orange-600 text-white hover:bg-orange-700 border-primary"
                    : "bg-gray-200 text-gray-400 cursor-not-allowed"
                }
              `}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
