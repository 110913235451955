import React, { useEffect, useState } from "react";
import { useStateValue } from "src/context/StateProvider";
import { IPhoneNumber } from "src/interfaces/contact";
import { getContactDetails, getWhatsappNumber } from "src/services/contact";
import Modal from "react-modal";
import { ReactComponent as ContactChat } from "src/assets/icons/contact-chat.svg";

import "src/styles/react-modal.css";
import { ArrowRight2, Call, CloseCircle, Sms, Whatsapp } from "iconsax-react";
import { MdCancel } from "react-icons/md";
Modal.setAppElement("#root");

type Props = {
  modalIsOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

function ContactUsModal({ modalIsOpen, setIsOpen }: Props) {
  const [{ clientIpCountry }] = useStateValue();
  const [contactNumber, setContactNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (clientIpCountry) {
      setContactNumber(getContactDetails(clientIpCountry).phone);
      setWhatsappNumber(getWhatsappNumber(clientIpCountry));
    }
  }, [clientIpCountry]);
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="max-w-md w-full"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 1000,
          },
          content: {
            position: "absolute",
            background: "rgba(0, 0, 0, 0)",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "20px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            zIndex: 1000,
          },
        }}
      >
        <div
          style={{ backgroundColor: "#F1DDE6" }}
          className="p-5 pb-10 rounded-2xl "
        >
          <div className="flex">
            <div className="flex-1">
              <p className="text-primary font-bold">Need help?</p>
              <p style={{ color: "#484949" }} className="font-semibold">
                We are here to assist
              </p>
            </div>
            <CloseCircle
              size="24"
              className="text-primary cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <ContactChat className="mx-auto mt-10" />
          <div className="mt-10 space-y-[10px]">
            <a href={`tel:${contactNumber}`} className="block">
              <div className="p-4 flex bg-white rounded-[14px] gap-2 text-primary items-center ">
                <Call size="28" />
                <p className="font-semibold">{contactNumber}</p>
                <ArrowRight2 size="28" className="ml-auto" />
              </div>
            </a>
            <a href={`mailto:info@lingawa.com`} className="block">
              <div className="p-4 flex bg-white rounded-[14px] gap-2 text-primary items-center ">
                <Sms size="28" />
                <p className="font-semibold">info@lingawa.com</p>
                <ArrowRight2 size="28" className="ml-auto" />
              </div>
            </a>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noreferrer"
              className="block"
            >
              <div className="p-4 flex bg-white rounded-[14px] gap-2 text-primary items-center ">
                <Whatsapp size="28" />
                <p className="font-semibold">{whatsappNumber}</p>
                <ArrowRight2 size="28" className="ml-auto" />
              </div>
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ContactUsModal;
