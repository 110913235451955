// FinalResult.tsx
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

interface FinalResultProps {
  score: number;
  totalPrompts: number;
  onRestart: () => void;
  onClose: () => void;
}

const FinalResult: React.FC<FinalResultProps> = ({
  score,
  totalPrompts,
  onRestart,
  onClose,
}) => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener("resize", handleResize);

    // Play victory jingle on mount
    const audio = new Audio(
      `${process.env.REACT_APP_ASSET_CDN}/happy-african.mp3`
    );
    audio.play();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const percentage = Math.round((score / totalPrompts) * 100);

  let performanceMessage = "Good Job!";
  if (percentage === 100) {
    performanceMessage = "Perfect Score! Amazing! 🎉";
  } else if (percentage >= 80) {
    performanceMessage = "Awesome Work! 💪";
  } else if (percentage >= 50) {
    performanceMessage = "Nice Effort! Keep Improving! 👍";
  } else {
    performanceMessage = "Keep Practicing! You got this! 💫";
  }

  return (
    <div className="relative flex flex-col items-center justify-center min-h-[78vh] bg-gradient-to-b from-lingawa-primary to-lingawa-primary p-4">
      {/* Confetti */}
      <Confetti width={windowSize.width} height={windowSize.height - 200} />

      <div className="bg-white p-8 rounded-3xl shadow-2xl max-w-md w-full text-center transform transition-all duration-500 hover:scale-105">
        <h1 className="text-4xl font-extrabold text-lingawa-primary mb-4 animate-pulse">
          Quiz Completed!
        </h1>

        <div className="text-6xl mb-4 animate-bounce-trophy">🏆</div>

        <p className="text-2xl text-lingawa-orange font-bold mb-2">
          {performanceMessage}
        </p>
        <p className="text-lg text-gray-700 mb-6">
          You scored{" "}
          <span className="font-semibold text-orange-500">{score}</span> out of{" "}
          <span className="font-semibold text-orange-500">{totalPrompts}</span>{" "}
          <br />
          That's <span className="font-semibold">{percentage}%</span>
        </p>

        <div className="flex space-x-4 justify-center">
          <button
            onClick={onRestart}
            className="bg-orange-600 text-white font-medium px-6 py-2 rounded-full hover:bg-orange-700 transition-colors transform hover:scale-110 shadow-lg"
          >
            Restart Quiz
          </button>
          <button
            onClick={onClose}
            className="bg-lingawa-teal text-white font-medium px-6 py-2 rounded-full hover:bg-primary transition-colors transform hover:scale-110 shadow-lg"
          >
            Topic Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalResult;
