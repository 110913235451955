import React, { useState } from "react";

type SentenceAreaProps = {
  sentence: any[];
  setSentence: React.Dispatch<React.SetStateAction<any[]>>;
  onWordRemove: (word: string, add: boolean) => void;
};

export default function SentenceArea({
  sentence,
  setSentence,
  onWordRemove,
}: SentenceAreaProps) {
  const [draggedWordIndex, setDraggedWordIndex] = useState<number | null>(null);
  const [dropTargetIndex, setDropTargetIndex] = useState<number | null>(null);

  const handleDragStart = (
    index: number,
    word: any,
    e: React.DragEvent<HTMLSpanElement>
  ) => {
    setDraggedWordIndex(index);
    const data = JSON.stringify(word);
    e.dataTransfer.setData("application/json", data);
    e.dataTransfer.setData("source", "sentenceArea");
  };

  const handleDragEnd = (word: any) => {
    // Check if the word is being removed (not rearranged within the sentence)
    if (draggedWordIndex !== null) {
      setSentence(sentence.filter((w, idx) => idx !== draggedWordIndex));
    }
    setDraggedWordIndex(null); // Reset dragged word index after dropping
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLElement>,
    targetIndex: number
  ) => {
    e.preventDefault();
    setDropTargetIndex(targetIndex);
    if (draggedWordIndex === null) return;

    // Move the dragged word to the new position
    let newSentence = [...sentence];
    newSentence.splice(
      targetIndex,
      0,
      newSentence.splice(draggedWordIndex, 1)[0]
    );
    setSentence(newSentence);
    setDraggedWordIndex(targetIndex); // Update the index of the dragged word
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();

    const source = e.dataTransfer.getData("source");
    if (source === "wordbank") {
      const data = e.dataTransfer.getData("application/json");
      const word = JSON.parse(data);
      if (dropTargetIndex !== null) {
        let newSentence = [...sentence];
        newSentence.splice(dropTargetIndex, 0, word);
        setSentence(newSentence);
      } else {
        setSentence([...sentence, word]);
      }
      onWordRemove(word, false);
    }

    setDraggedWordIndex(null);
    setDropTargetIndex(null); // Reset the drop target index
  };

  const handleWordClick = (word: any) => {
    // Remove the word from the sentence
    setSentence(sentence.filter((w) => w.word !== word.word));
    // Add the word back to the word bank
    onWordRemove(word, true);
  };

  return (
    <div
      className="bg-[#F7EFD9] p-4 flex flex-wrap justify-center items-center min-h-[5rem] rounded-xl"
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      {sentence.map((word, index) => (
        <span
          key={index}
          draggable
          onDragStart={(e) => handleDragStart(index, word, e)}
          onDragOver={(e) => handleDragOver(e, index)}
          onDragEnd={() => handleDragEnd(word)}
          onClick={() => handleWordClick(word)}
          className="draggable-word-sentenceArea cursor-grab"
        >
          {word.word}
        </span>
      ))}
    </div>
  );
}
