import { FaRegBookmark } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import Subject from "./Subject";
import Tutor from "./Tutor";

export interface ICheckoutSummary {
	firstName: string;
	lastName: string;
	amount: number;
	currency: string;
	tutors?: [];
	subjects?: [];
	promo?: {
		title: string;
	};
	selectedTutor?: {
		firstName: string;
		lastName: string;
	};
	numberOfLessons: number;
	type: string;
	subject: string;
	lessonFrequency: number;
	perLessonRate: string;
	lessonStartDate: string;
	pkgRate: string;
	influencerDiscountPercentage?: number;
	influencerName?: string;
	pkgAmount: number;
}

export default function CheckoutSummary({
	details,
	learningPackage,
}: {
	details: ICheckoutSummary;
	learningPackage: any;
}) {
	return (
		<div className="border-[2px] rounded-xl flex flex-col justify-between p-4 flex-1 ">
			<>
				<p className="text-gray-400 ">Customer</p>

				<h1 className="font-semibold text-lingawa-primary text-xl">
					{details?.firstName} {details?.lastName}
				</h1>

				{details?.tutors && (
					<div>
						<div className="border border-gray-300 my-2"></div>

						<h1 className="font-bold text-lg">Potential Tutors</h1>
						{details?.tutors?.map((tutor: any) => (
							<Tutor key={tutor?.tutorId} tutor={tutor} />
						))}
					</div>
				)}

				<div>
					{details?.subjects && (
						<>
							<div className="border border-gray-300 my-4"></div>

							<h1 className="font-bold text-lg">Your Order</h1>

							{details?.subjects?.map((subject: any) => (
								<Subject key={subject.subjectId} subject={subject} />
							))}
						</>
					)}

					{details?.promo && (
						<div className="flex justify-between items-center gap-4 mt-2 flex-wrap">
							<p className="flex-1">Promos:</p>

							<p className="font-semibold flex-1 text-right">
								{details?.promo && details?.promo?.title}
							</p>
						</div>
					)}
				</div>

				<div className="border border-gray-100 my-1"></div>
			</>

			<>
				<p className="text-gray-400 mt-2">Lesson Details</p>

				{details?.selectedTutor ? (
					details?.selectedTutor?.firstName &&
					details?.selectedTutor?.lastName && (
						<h1 className="font-medium text-lingawa-primary text-lg mt-2">{`${details?.subject} sessions with  ${details?.selectedTutor?.firstName} ${details?.selectedTutor?.lastName}`}</h1>
					)
				) : (
					<h1 className="font-medium text-lg mt-2">{details?.subject}</h1>
				)}

				<div className="text-lingawa-primary items-center flex gap-2">
					<FaRegBookmark />
					<h1 className="font-medium text-sm">
						{details?.type === "ONE_TIME" ? "One off, " : `${details?.type}, `}
						{details?.numberOfLessons &&
							details?.numberOfLessons *
								(details?.type === "Monthly Package"
									? details?.lessonFrequency
									: 1)}{" "}
						Lessons
					</h1>
				</div>

				{details?.lessonStartDate && (
					<div className="text-lingawa-primary items-center flex gap-2">
						<MdOutlineCalendarMonth />
						<h1 className="font-medium text-sm">{`First Lesson: ${details?.lessonStartDate}`}</h1>
					</div>
				)}

				{learningPackage && (
					<div className="text-lingawa-primary items-center flex gap-2">
						<FaRegClock />
						<h1 className="font-medium text-sm">{`${
							learningPackage?.lessonType === "fullLesson"
								? "55 minutes"
								: "25 minutes"
						}`}</h1>
					</div>
				)}

				<div className="border  border-gray-100 my-3"></div>

				<div>
					{learningPackage && (
						<div className="flex justify-between items-center">
							<h1 className="text-gray-400 text-sm">No. of students</h1>

							<p className="font-semibold text-lingawa-primary text-right">
								{learningPackage?.numberOfUsers}
							</p>
						</div>
					)}

					<div className="flex justify-between items-center">
						<h1 className="text-gray-400 text-sm">Payment plan</h1>

						<p className=" text-lingawa-primary text-right">
							<span className="font-semibold">{details?.perLessonRate}</span> x{" "}
							{details?.numberOfLessons} lessons
						</p>
					</div>

					{details?.influencerDiscountPercentage && (
						<div className=" w-full  mt-0 mb-1">
							<p className=" text-right text-secondary-light italic text-sm">
								You just saved {details.influencerDiscountPercentage}% with{" "}
								{details.influencerName}'s code
							</p>
						</div>
					)}
				</div>
			</>

			<div className="flex justify-between text-lingawa-primary items-center text-sm">
				<h1>Total due today</h1>

				<p className="font-semibold text-2xl ">
					{details?.currency &&
						Intl.NumberFormat("en-US", {
							style: "currency",
							currency: details?.currency,
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						}).format(details?.amount)}
				</p>
			</div>
		</div>
	);
}
