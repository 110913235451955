import { MenuIcon, XIcon } from "@heroicons/react/solid";
import clsx from "clsx";

import { useNavigate } from "react-router-dom";
import assets from "src/assets/assets";
import UserProfile from "src/components/Shared/Header/UserProfile";
import { useStateValue } from "src/context/StateProvider";
import MobileNav from "../DashboardMobileNav";
const logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-logo-light.png`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-logo-light.png`;

function Header() {
  const navigate = useNavigate();
  const [
    { learningAppLoading, showMobileMenu, user, userCowryPouch },
    dispatch,
  ] = useStateValue();

  const toggleMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const onSwitchToLearningClick = async () => {
    await dispatch({
      type: "SET_LEARNING_APP_LOADING",
      learningAppLoading: true,
    });
    navigate("/");
  };

  return (
    <header
      className="top-0 z-50 
    bg-lingawa-primary py-5 relative border-b-2"
    >
      {/* Top Bar */}
      <div className="flex justify-between px-6">
        {/* left */}
        <div>
          <div
            className="lg:flex items-center h-10 
            cursor-pointer hidden"
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="" width={171} height={135} />
          </div>
          <div
            className="relative flex items-center h-10 
            cursor-pointer my-auto lg:hidden"
            onClick={() => navigate("/")}
          >
            <img src={mobile_logo} alt="" width={160} />
          </div>
        </div>

        {/* right */}
        {/* Logged In Profile  */}
        <div>
          <div className="lg:flex items-center space-x-6 hidden">
            {/* <div>
              <p
                className="text-sm text-primary hover:text-secondary cursor-pointer font-semibold"
                onClick={onSwitchToLearningClick}
              >
                Switch to Learning
              </p>
            </div> */}
            <UserProfile />
          </div>
        </div>
        {/* Burger Icon */}
        <div className="flex lg:hidden">
          {user?.type === "student" && (
            <div
              onClick={() => navigate("/student/dashboard/leaderboard")}
              className="flex gap-1 bg-primary py-1 px-[8px] mr-1 lg:hidden rounded-lg items-center"
            >
              <img
                className="w-6 h-6 mr-1 rounded-full object-cover"
                // src="https://storage.googleapis.com/topset-app-assets/cowry_icon.png"
                src={assets.dCowry}
                alt="cowry"
              />
              <p className="text-white  font-bold text-lg text-center">
                {userCowryPouch?.balance ? userCowryPouch.balance : 0}
              </p>
            </div>
          )}

          <div
            className={clsx({
              block: !showMobileMenu,
              hidden: showMobileMenu,
            })}
          >
            <MenuIcon
              onClick={toggleMenu}
              className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
            />
          </div>
          <div
            className={clsx({
              block: showMobileMenu,
              hidden: !showMobileMenu,
            })}
          >
            <XIcon
              onClick={toggleMenu}
              className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
            />
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={clsx({
          block: showMobileMenu,
          hidden: !showMobileMenu,
        })}
      >
        <div className="w-full px-6 bg-pale-purple-bg absolute lg:hidden h-screen">
          <MobileNav toggleMenu={toggleMenu} />
        </div>
      </div>
    </header>
  );
}

export default Header;
