import React from "react";
import { CardData } from "./types";
import Card from "./Card";

interface CardGridProps {
  cards: CardData[];
  selectedCards: CardData[];
  incorrectSelection: CardData[];
  mode: "normal" | "beast";
  onCardClick: (card: CardData) => void;
}

const CardGrid: React.FC<CardGridProps> = ({
  cards,
  selectedCards,
  incorrectSelection,
  mode,
  onCardClick,
}) => {
  return (
    <div
      className="
        grid 
        w-full h-full
        overflow-hidden 
        gap-2 
        grid-cols-3 grid-rows-4
        md:grid-cols-4 md:grid-rows-3 pb-20 px-2 py-2
      "
    >
      {cards.map((card) => {
        const isSelected = selectedCards.some((sel) => sel.id === card.id);
        const isIncorrectSelection = incorrectSelection.some(
          (sel) => sel.id === card.id
        );
        return (
          <Card
            key={card.id}
            card={card}
            isSelected={isSelected}
            isIncorrectSelection={isIncorrectSelection}
            mode={mode}
            onClick={() => onCardClick(card)}
          />
        );
      })}
    </div>
  );
};

export default CardGrid;
