import React from "react";

export default function RefundPolicyText() {
  return (
    <p className="text-gray-400 text-sm mt-4">
      We offer a full refund for cancellations made within 7 days of purchase.
      For more details, please review our full refund policy{" "}
      <a
        target="_blank"
        className="underline text-lingawa-primary font-semibold"
        href="https://lingawa.com/pricing/#pricing"
        rel="noreferrer"
      >
        here.
      </a>
    </p>
  );
}
