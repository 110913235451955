import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import * as completeAnimation from "src/assets/animations/complete.json";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "src/Routes";
import clsx from "clsx";
import { LearningAppApi } from "src/api/learning-app";
import { useStateValue } from "src/context/StateProvider";

interface ResultsPageProps {
  wordsLearned: any;
  wordsStillLearning: any;
  restartSession: any;
}

export default function ResultsPage({
  wordsLearned,
  wordsStillLearning,
  restartSession,
}: ResultsPageProps) {
  const navigate = useNavigate();
  const { userId, level, topic, order, topicId } = useParams();
  const [{ user }] = useStateValue();
  const { state } = useLocation();
  const route =
    user?.type === "student"
      ? routes.STUDENT_DASHBOARD_LEARN.url
      : routes.TUTOR_DASHBOARD_LEARN.url;

  console.log("wordsLearned", wordsLearned);
  console.log("wordsStillLearning", wordsStillLearning);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnNextClick = async () => {
    console.log("wordsStillLearning.length", wordsStillLearning.length);

    setIsSubmitting(true);
    await LearningAppApi.postSaveFlashcardProgress(
      userId,
      topicId,
      wordsLearned,
      wordsStillLearning
    );
    if (wordsStillLearning.length > 0) {
      restartSession();
    } else {
      // navigate to the next topic
      navigate(`${route}/${userId}/${level}/${order}/${topic}/${topicId}`);
    }
    setIsSubmitting(false);
  };

  // useEffect(() => {
  //   handleCompleteSession();
  // }, []);

  return (
    <>
      <div className="mt-10 flex justify-center">
        <div className="flex flex-col items-center max-w-[350px]">
          <div className="bg-pale-purple-bg w-full pt-2 rounded-xl mb-4">
            <h1 className="text-xl font-semibold mb-2 text-primary-light text-center">
              You've reviewed all cards
            </h1>
          </div>
          <div className=" white-card-rounded h-[350-px] w-[350px]">
            {
              <Lottie
                animationData={completeAnimation}
                style={{
                  height: 350,
                  width: 350,
                }}
                loop={false}
              />
            }
          </div>
          {/* <div className="bg-pale-purple-bg w-full pt-2 rounded-xl mt-4">
            <h1 className="lg:text-l font-semibold mb-2 text-primary-light text-center">
              {`Level ${level}, Topic ${topic}`}
            </h1>
          </div> */}
          <div className="white-card-rounded w-full flex pt-2 mt-4 justify-between px-4">
            <h1 className="lg:text-l font-semibold mb-2 text-secondary-light text-center">
              Completed:
            </h1>
            <h1 className="lg:text-l font-semibold mb-2 text-secondary-light text-center">
              {wordsLearned.length}
            </h1>
          </div>
          <div className="white-card-rounded w-full flex pt-2 mt-2 justify-between px-4">
            <h1 className="lg:text-l font-semibold mb-2 text-green-500 text-center">
              Terms Remaining:
            </h1>
            <h1 className="lg:text-l font-semibold mb-2 text-green-600 text-center">
              {wordsStillLearning.length}
            </h1>
          </div>
          {
            <button
              className={clsx(
                "bg-primary-light hover:bg-primary rounded-xl lg:min-w-[250px] min-w-[150px] px-4 py-1 mt-8",
                {
                  "cursor-not-allowed": isSubmitting,
                }
              )}
              disabled={isSubmitting}
              onClick={handleOnNextClick}
            >
              <h1 className="lg:text-l font-semibold py-2 text-white text-center">
                Next
              </h1>
            </button>
          }
        </div>
      </div>
    </>
  );
}
