// AudioMCSection.tsx
import React, { useState } from "react";
import { Prompt } from "./types";
import AudioMultipleChoicePrompt from "../TestYourKnowledge/AudioMultipleChoicePrompt";

interface AudioMCSectionProps {
  /**
   * The array of audio multiple-choice prompts
   */
  prompts: Prompt[];

  /**
   * Called when the user finishes all prompts (including re-queued fails)
   */
  onFinish: () => void;

  /**
   * If user chooses "I already know this word," remove that prompt from the session
   */
  handleRequestRemoveWord: () => void;

  /**
   * Called whenever user finishes a prompt (correct or incorrect).
   * The parent can handle feedback UI or scoring logic.
   */
  onShowFeedback: (isCorrect: boolean, prompt: Prompt) => void;
  setCurrentWordId?: React.Dispatch<React.SetStateAction<string | null>>;
}

/**
 * 1) Main pass: user sees each prompt once.
 *    - If user fails => push to `failedQueue`.
 *    - After finishing main pass, if there's any failed prompt,
 *      we switch to indefinite re-queue with ReQueueAudioMC.
 */
const AudioMCSection: React.FC<AudioMCSectionProps> = ({
  prompts,
  onFinish,
  handleRequestRemoveWord,
  onShowFeedback,
  setCurrentWordId,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [failedQueue, setFailedQueue] = useState<Prompt[]>([]);

  /**
   * Called by each AudioMultipleChoicePrompt in the main pass.
   */
  const handleNext = (isCorrect: boolean) => {
    const currentPrompt = prompts[currentIndex];

    // Let the parent show feedback (score, animations, etc.)
    onShowFeedback(isCorrect, currentPrompt);

    // If user fails => push that prompt to `failedQueue`
    if (!isCorrect) {
      setFailedQueue((prev) => [...prev, currentPrompt]);
    }

    // Move on to the next prompt
    setTimeout(() => {
      setCurrentIndex((prev) => prev + 1);
    }, 2000);
  };

  /**
   * Once we exhaust the main pass:
   * - if failedQueue is not empty => indefinite re-queue
   * - else => onFinish
   */
  if (currentIndex >= prompts.length) {
    if (failedQueue.length > 0) {
      return (
        <ReQueueAudioMC
          queue={failedQueue}
          onDone={onFinish}
          handleRequestRemoveWord={handleRequestRemoveWord}
          onShowFeedback={onShowFeedback}
          setCurrentWordId={setCurrentWordId}
        />
      );
    } else {
      onFinish();
      return null;
    }
  }

  // Still in the main pass => show the current prompt
  const currentPrompt = prompts[currentIndex];
  setCurrentWordId?.(currentPrompt.word?._id || null);

  return (
    <div>
      {/* 
        If you want a "I already know this" button for the main pass:
        
        <button onClick={() => handleRemove(currentPrompt._id)}>
          I already know this
        </button>
      */}

      <AudioMultipleChoicePrompt
        prompt={currentPrompt}
        onNext={handleNext}
        handleRequestRemoveWord={handleRequestRemoveWord}
      />
    </div>
  );
};

/**
 * 2) The indefinite re-queue sub-component that cycles any failed prompts
 *    until the user passes them all.
 *    - On pass => remove from queue
 *    - On fail => move item to end
 *    - Always reset index=0 after manipulating the queue
 *    - If queue empty => onDone
 */
interface ReQueueProps {
  queue: Prompt[];
  onDone: () => void;
  handleRequestRemoveWord: () => void;
  onShowFeedback: (isCorrect: boolean, prompt: Prompt) => void;
  setCurrentWordId?: React.Dispatch<React.SetStateAction<string | null>>;
}

const ReQueueAudioMC: React.FC<ReQueueProps> = ({
  queue: initialQueue,
  onDone,
  handleRequestRemoveWord,
  onShowFeedback,
  setCurrentWordId,
}) => {
  const [queue, setQueue] = useState<Prompt[]>(initialQueue);
  const [index, setIndex] = useState(0);

  /**
   * Called by each prompt in the re-queue
   */
  const handleNext = (isCorrect: boolean) => {
    const currentPrompt = queue[index];

    // Let the parent handle feedback again
    onShowFeedback(isCorrect, currentPrompt);

    // If pass => remove from queue
    if (isCorrect) {
      setTimeout(() => {
        setQueue((prev) => {
          const newArr = [...prev];
          newArr.splice(index, 1);
          return newArr;
        });
      }, 2000);
    } else {
      // If fail => remove from index & push to end
      setTimeout(() => {
        setQueue((prev) => {
          const newArr = [...prev];
          const item = newArr[index];
          newArr.splice(index, 1);
          newArr.push(item);
          return newArr;
        });
      }, 2000);
    }

    // Force index=0 so we re-check from the start
    setTimeout(() => {
      setIndex(0);
    }, 2000);
  };

  // If queue is empty => user eventually passed them all
  if (queue.length === 0) {
    onDone();
    return null;
  }

  // If index >= queue.length => reset to 0
  if (index >= queue.length) {
    setIndex(0);
    return null;
  }

  const prompt = queue[index];
  setCurrentWordId?.(prompt.word?._id || null);

  return (
    <div>
      {/* 
        If you want a "I already know this" button for re-queue:
        
        <button onClick={() => onRemovePrompt(prompt._id)}>
          I already know this
        </button>
      */}

      <AudioMultipleChoicePrompt
        prompt={prompt}
        onNext={handleNext}
        handleRequestRemoveWord={handleRequestRemoveWord}
      />
    </div>
  );
};

export default AudioMCSection;
