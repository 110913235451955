import React, { useState, useEffect, useRef } from "react";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { Prompt } from "./types";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import SubmitButton from "../VocabDrills/SubmitButton";
import { MdOutlineReplay } from "react-icons/md";

type AudioMCVariant = "iconOptions" | "audioButtonMain";

interface AudioMultipleChoicePromptProps {
  prompt: Prompt;
  onNext: (isCorrect: boolean) => void;
  variant?: AudioMCVariant; // Decide which layout
  handleRequestRemoveWord?: any;
}

/**
 * AudioMultipleChoicePrompt:
 *
 * variant="iconOptions":
 *   - Shows a main image (prompt.word.imageUrl)
 *   - Each option is an audio icon that auto-plays on selection
 *
 * variant="audioButtonMain":
 *   - Auto-plays the main word's audio when the component first renders
 *   - Shows a large "Play Audio" button for manual replay
 *   - The options are text (English translations)
 */
const AudioMultipleChoicePrompt: React.FC<AudioMultipleChoicePromptProps> = ({
  prompt,
  onNext,
  variant = "audioButtonMain", // default
  handleRequestRemoveWord,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const confettiRef = useRef<HTMLDivElement>(null);

  // Reset state whenever a new prompt arrives
  useEffect(() => {
    setSelectedOption("");
    setHasSubmitted(false);
  }, [prompt]);

  // For sizing confetti
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (confettiRef.current) {
      setDimensions({
        width: confettiRef.current.offsetWidth,
        height: confettiRef.current.offsetHeight,
      });
    }
    const handleResize = () => {
      if (confettiRef.current) {
        setDimensions({
          width: confettiRef.current.offsetWidth,
          height: confettiRef.current.offsetHeight,
        });
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /**
   * Function to safely play audio by URL.
   */
  const playAudio = (audioUrl?: string) => {
    if (!audioUrl) return;
    const audio = new Audio(audioUrl);
    audio.play().catch((err) => console.error("Audio playback failed", err));
  };

  /**
   * If variant="audioButtonMain", auto-play the main word's audio
   * whenever the component loads a new prompt.
   */
  useEffect(() => {
    if (variant === "audioButtonMain" && prompt.word.audioUrl) {
      playAudio(prompt.word.audioUrl);
    }
  }, [variant, prompt]);

  /**
   * Handle user picking an option.
   * In "iconOptions" variant, auto-play that option’s audio on selection.
   */
  const handleOptionSelect = (optionId: string, audioUrl?: string) => {
    if (hasSubmitted) return;
    setSelectedOption(optionId);

    if (variant === "iconOptions" && audioUrl) {
      playAudio(audioUrl);
    }
  };

  /**
   * After user finalizes the selection by clicking "Submit"
   */
  const handleSubmit = () => {
    if (!selectedOption || hasSubmitted) return;
    setHasSubmitted(true);

    // Compare selected option to correctAnswerId
    const isCorrect = selectedOption === prompt.conrrectAnswerId?.toString();

    onNext(isCorrect);
    setTimeout(() => {
      setSelectedOption("");
      setHasSubmitted(false);
    }, 2000);
  };

  /**
   * Renders the main area:
   * - If variant="iconOptions", show main image if available.
   * - If variant="audioButtonMain", show a big button for manual replay.
   */
  const renderMainArea = () => {
    if (variant === "iconOptions") {
      // Show main image, if any
      if (prompt.word.imageUrl) {
        return (
          <div className="flex justify-center mb-4">
            <img
              src={prompt.word.imageUrl}
              alt={prompt.word.englishTranslation}
              className="max-h-40 object-contain rounded shadow-md"
            />
          </div>
        );
      }
      return null;
    } else {
      // variant="audioButtonMain"
      // The user can replay the main word's audio with this big button
      return (
        <div className="flex justify-center mb-4">
          <button
            onClick={() => playAudio(prompt.word.audioUrl)}
            className="bg-lingawa-teal text-white text-lg font-semibold w-48 h-32 rounded-md  transition-transform transform hover:scale-105"
          >
            <HiMiniSpeakerWave className="inline-block" size={50} />
          </button>
        </div>
      );
    }
  };

  /**
   * Renders each option.
   * - "iconOptions": just an audio icon
   * - "audioButtonMain": text = englishTranslation
   */
  const renderOption = (option: any) => {
    const optionId = option._id?.toString() || option;
    const isSelected = selectedOption === optionId;

    if (variant === "iconOptions") {
      // No text, just audio icon
      return (
        <div
          onClick={() => handleOptionSelect(optionId, option.audioUrl)}
          className={`
            cursor-pointer flex items-center justify-center p-4 rounded-xl shadow-md bg-white 
            hover:scale-105 transform transition-transform font-medium
            ${isSelected ? "ring-4 ring-orange-600" : "ring-2 ring-transparent"}
          `}
        >
          <BsMusicNoteBeamed className="text-2xl text-orange-600" />
        </div>
      );
    } else {
      // variant="audioButtonMain": show the englishTranslation as text
      const textLabel = option.englishTranslation || "Option";
      return (
        <div
          onClick={() => handleOptionSelect(optionId)}
          className={`
            cursor-pointer px-3 py-3 rounded-2xl border-b-8 mb-2 bg-white border-2 hover:scale-105 transform transition-transform text-sm font-medium 
            ${
              isSelected
                ? "text-green-600 bg-very-light-green border-green-600"
                : "text-gray-400"
            }
          `}
        >
          {textLabel}
        </div>
      );
    }
  };

  return (
    <>
      {/* Updates */}
      <div className="lg:pb-24">
        <div className="flex items-center justify-center">
          <span className="text-3xl select-none">
            {variant === "iconOptions" ? `🎧` : ``}
          </span>
          <h2 className="text-2xl font-bold text-lingawa-primary ml-2">
            {variant === "iconOptions"
              ? `${prompt.word.englishTranslation}`
              : "Listen & Choose"}
          </h2>
        </div>
        <p className="text-center text-gray-500 text-sm mb-6">
          {variant === "iconOptions"
            ? `Select the correct audio clip:`
            : `Select the correct English translation:`}
        </p>

        {/* Image and options container */}

        <div className="flex flex-col items-center md:flex-row justify-center gap-8">
          {/* Display image if available */}
          {prompt.word.audioUrl && (
            <div className=" md:w-[300px] rounded-xl p-3 border-[1px] border-gray-300 mb-4">
              <button
                onClick={() => playAudio(prompt.word.audioUrl)}
                className="bg-[#30B0C7] hover:bg-[#2b9caf]  text-white text-lg font-semibold h-60 w-[260px] rounded-md  transition-transform transform"
              >
                <HiMiniSpeakerWave className="inline-block" size={50} />
              </button>
              <div className="flex gap-2 text-gray-500 mt-2">
                <MdOutlineReplay />
                <p className="text-xs ">Click to replay audio</p>
              </div>
            </div>
          )}
          {/* Options as Cards */}
          <div className="mb-4 -mt-8 md:mt-0 w-full md:min-w-[330px]">
            {prompt.options?.map((option: any) => (
              <React.Fragment key={option._id?.toString() || option}>
                {renderOption(option)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <SubmitButton
        handleRequestRemoveWord={handleRequestRemoveWord}
        handleSubmit={handleSubmit}
        selectedOption={selectedOption}
        hasSubmitted={hasSubmitted}
      />
    </>
  );
};

export default AudioMultipleChoicePrompt;
