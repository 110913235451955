import React from "react";
import { FaUserCircle } from "react-icons/fa";

type ProfileIconProps = {
  avatar: any;
  active?: boolean;
  onClick: () => void;
};

export default function AvatarIcon({
  avatar,
  active,
  onClick,
}: ProfileIconProps) {
  return (
    <div
      className={`hover:scale-105 w-10 h-10 cursor-pointer rounded-full ${
        active ? "border-secondary border-[3px]" : "border-gray-300 border-2"
      }`}
      data-profileid="profile-id"
      // onMouseLeave={handleHideMenu}
      onClick={onClick}
    >
      {avatar ? (
        <img
          className="w-full h-full rounded-full object-cover"
          src={avatar}
          alt=""
          referrerPolicy="no-referrer"
        />
      ) : (
        <FaUserCircle className="text-lingawa-teal w-full h-full" />
      )}
    </div>
  );
}
