// StartScreen.tsx
import React from "react";
import Confetti from "react-confetti";
import { useParams } from "react-router-dom";

interface StartScreenProps {
  handleRestartSession: () => void;
}

const imageUrl = `${process.env.REACT_APP_ASSET_CDN}/mascot-excited-badge.png`;

const StartScreen: React.FC<StartScreenProps> = ({ handleRestartSession }) => {
  console.log("StartScreen.tsx");
  const { userId, level, topic, order, topicId } = useParams();

  return (
    <div className="flex flex-col p-4 items-center justify-center min-h-[78vh] bg-gradient-to-br from-orange-600 to-lingawa-teal rounded-xl ">
      <Confetti />
      <div>
        <img src={imageUrl} alt="Success" className="h-32 w-32 mb-4" />
      </div>
      <h1 className="md:text-4xl text-xl text-center font-extrabold text-white mb-4">
        {topic} Session Completed!
      </h1>
      <p className="text-lg text-white text-center">
        Awesome job mastering all words!
      </p>
      <button
        className="mt-6 px-6 py-3 bg-lingawa-primary text-white rounded-md hover:bg-primary"
        onClick={handleRestartSession}
      >
        Restart Session
      </button>
    </div>
  );
};

export default StartScreen;
