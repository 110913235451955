import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Prompt } from "./types";
import PromptFactory from "./PromptFactory";
import BackToResources from "src/components/BackToResources";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LearningAppApi } from "src/api/learning-app";
import FeedbackPopup from "../FeedbackPopup";
import Checkpoint from "./Checkpoint";
import FinalResult from "./FinalResult";
import { routes } from "src/Routes";
import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
import { set } from "lodash";
import EmptyList from "src/components/Shared/EmptyList";
import { useStateValue } from "src/context/StateProvider";

export default function TestYourKnowledge() {
  const navigate = useNavigate();
  const { userId, level, topic, order, topicId } = useParams();
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [currentPromptIndex, setCurrentPromptIndex] = useState<number>(0);
  const [beastMode, setBeastMode] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [feedbackType, setFeedbackType] = useState<
    "correct" | "incorrect" | null
  >(null);
  const [showCheckpoint, setShowCheckpoint] = useState<boolean>(false);
  const [streak, setStreak] = useState<number>(0); // Track consecutive correct answers
  const [{ user }] = useStateValue();
  const { state } = useLocation();
  const route =
    user?.type === "student"
      ? routes.STUDENT_DASHBOARD_LEARN.url
      : routes.TUTOR_DASHBOARD_LEARN.url;
  console.log("topicId", topicId);

  const getTestYourKnowledgePrompts = useCallback(async () => {
    const response = await LearningAppApi.getTestYourKnowledgePrompts(topicId);
    setPrompts(response.data.prompts);
    setIsLoaded(true);
  }, [topicId]);

  useEffect(() => {
    getTestYourKnowledgePrompts();
  }, [getTestYourKnowledgePrompts]);

  // Optional: Play a sound or show animation when reaching a streak milestone
  useEffect(() => {
    if (streak > 0 && streak % 5 === 0) {
      playSound(`${process.env.REACT_APP_ASSET_CDN}/streak.wav`);
    }
  }, [streak]);

  // Play start sound when sessio starts
  useEffect(() => {
    console.log("play start soundis", isLoaded, prompts.length);
    if (isLoaded && prompts.length > 0) {
      console.log("play start sound");
      playSound(`${process.env.REACT_APP_ASSET_CDN}/open.wav`);
    }
  }, [isLoaded, prompts]);

  // Callback to toggle beast mode
  const onBeastModeToggle = () => {
    setBeastMode((prevMode) => !prevMode);
  };

  console.log("prompts", prompts);

  // Calculate checkpoints based on the number of prompts
  const checkpoints = useMemo(() => {
    const total = prompts.length;
    if (total <= 10) {
      return [];
    } else if (total < 20) {
      // 2 checkpoints at ~1/3 and ~2/3
      const oneThird = Math.floor(total / 3);
      const twoThirds = Math.floor((2 * total) / 3);
      return [oneThird, twoThirds];
    } else {
      // 3 checkpoints at ~1/4, 1/2, 3/4
      const quarter = Math.floor(total / 4);
      const half = Math.floor(total / 2);
      const threeQuarters = Math.floor((3 * total) / 4);
      return [quarter, half, threeQuarters];
    }
  }, [prompts]);

  const handleNext = (isCorrect: boolean) => {
    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
      setFeedbackType("correct");
      setStreak((prev) => prev + 1);
    } else {
      setFeedbackType("incorrect");
      setStreak(0);
    }
  };

  const handleNextFlashcard = (isCorrect: boolean) => {
    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
      setStreak((prev) => prev + 1);
    } else {
      setStreak(0);
    }

    setTimeout(() => {
      const nextIndex = currentPromptIndex + 1;
      if (nextIndex >= prompts.length) {
        // Quiz completed
        setCurrentPromptIndex(nextIndex);
        return;
      }

      // Check if nextIndex hits any of the defined checkpoints
      if (checkpoints.includes(nextIndex)) {
        setShowCheckpoint(true);
      } else {
        setCurrentPromptIndex(nextIndex);
      }
    }, 0);
  };

  const closeFeedback = () => {
    setFeedbackType(null);

    const nextIndex = currentPromptIndex + 1;
    if (nextIndex >= prompts.length) {
      // Quiz completed
      setCurrentPromptIndex(nextIndex);
      return;
    }

    // Check if nextIndex hits any of the defined checkpoints
    if (checkpoints.includes(nextIndex)) {
      setShowCheckpoint(true);
    } else {
      setCurrentPromptIndex(nextIndex);
    }
  };

  const handleContinueFromCheckpoint = () => {
    setShowCheckpoint(false);
    setCurrentPromptIndex((prevIndex) => prevIndex + 1);
  };

  // Handlers for FinalResult
  const handleRestart = () => {
    // Reset quiz state
    setCurrentPromptIndex(0);
    setScore(0);
    setShowCheckpoint(false);
    setFeedbackType(null);
    setStreak(0);
  };

  const handleClose = () => {
    navigate(`${route}/${userId}/${level}/${order}/${topic}/${topicId}`, {
      state: { curriculum: state?.curriculum, ageGroup: state?.ageGroup },
    });
  };

  if (!isLoaded) {
    return <LoadingBarScreen />;
  }

  if (isLoaded && prompts.length === 0) {
    return (
      <>
        <div>
          <BackToResources
            isBetaVersion={true}
            sessionType={"Test Your Knowledge"}
          />
        </div>
        <EmptyList message="Coming soon" />;
      </>
    );
  }

  // If quiz completed, show FinalResult
  if (currentPromptIndex >= prompts.length) {
    return (
      <FinalResult
        score={score}
        totalPrompts={prompts.length}
        onRestart={handleRestart}
        onClose={handleClose}
      />
    );
  }

  const currentPrompt = prompts[currentPromptIndex];
  const progressPercentage = (currentPromptIndex / prompts.length) * 100;

  // Streak announcements
  let streakMessage = "";
  if (streak > 0 && streak % 5 === 0) {
    streakMessage = `${streak} in a row! 🔥 Keep it going!`;
  }

  function playSound(src: string) {
    const audio = new Audio(src);
    audio.play();
  }

  return (
    <>
      <div>
        <BackToResources
          isBetaVersion={true}
          sessionType={"Test Your Knowledge"}
        />
      </div>
      {/* Progress Bar */}
      <div className="w-full bg-gray-200 mt-4 rounded-full h-4 mb-6">
        <div
          className="bg-lingawa-orange h-4 rounded-full transition-all duration-500"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <div
        className={`flex mt-4 flex-col rounded-lg items-center relative transition-colors duration-500 bg-gradient-to-b white`}
      >
        <div className="w-full max-w-2xl p-4">
          {/* Streak display if any */}
          {streakMessage && (
            <div className="text-center text-xl font-semibold text-blue-400 mb-4 animate-pulse">
              {streakMessage}
            </div>
          )}

          {/* PromptFactory includes the beastMode and onBeastModeToggle */}
          {/* WrittenAnswerPrompt and MultipleChoicePrompt both handle beastMode now */}
          <PromptFactory
            prompt={currentPrompt}
            beastMode={beastMode}
            onBeastModeToggle={onBeastModeToggle}
            onNext={handleNext}
            handleNextFlashcard={handleNextFlashcard}
          />
        </div>

        {/* Checkpoint */}
        {showCheckpoint && (
          <Checkpoint
            currentScore={score}
            questionsAttempted={currentPromptIndex + 1}
            onContinue={handleContinueFromCheckpoint}
          />
        )}

        {/* Feedback Popup */}
        {feedbackType && (
          <FeedbackPopup
            feedbackType={feedbackType}
            onClose={closeFeedback}
            currentPrompt={currentPrompt}
          />
        )}
      </div>
    </>
  );
}
