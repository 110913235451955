import React from "react";
import FormContainer from "./FormContainer";
import LeanerForm from "./LearnerForm";
import AgeGroupForm from "./AgeGroupForm";
import DialectForm from "./DialectForm";
import PrimaryReasonForm from "./PrimaryReasonForm";
import OtherReason from "./OtherReason";
import PersonalityForm from "./PersonalityForm";
import CompetenceForm from "./CompetenceForm";
import UserDataForm from "./UserDataForm";
import { useDirectPayContext } from "src/context/DirectPayContext";
import WordsThatDescribe from "./WordsThatDescribe";
import PreDialectForm from "./PreDialectForm";
import ScheduleForm from "./ScheduleForm";

interface FormLogicProps {
  handleValidator: () => void;
  handleFormCancel: () => void;
  pageIndex: number;
  formNum: number;
}

type FormComponent = React.ComponentType<any>;

const FormLogic: React.FC<FormLogicProps> = ({
  handleValidator,
  handleFormCancel,
  pageIndex,
  formNum,
}) => {
  const {
    state: { subject, reasonsForLearning },
  } = useDirectPayContext();
  const renderIgboForm = (): React.ReactElement => {
    const formMap: Record<number, FormComponent> = {
      1: LeanerForm,
      2: AgeGroupForm,
      3: PreDialectForm,
      4: DialectForm,
      5: PrimaryReasonForm,
      6: reasonsForLearning.includes("Other") ? OtherReason : PersonalityForm,
      7: reasonsForLearning.includes("Other")
        ? PersonalityForm
        : WordsThatDescribe,
      8: reasonsForLearning.includes("Other")
        ? WordsThatDescribe
        : CompetenceForm,
      9: reasonsForLearning.includes("Other") ? CompetenceForm : ScheduleForm,
      10: reasonsForLearning.includes("Other") ? ScheduleForm : UserDataForm,
    };

    const Component = formMap[pageIndex] || UserDataForm;
    return <Component />;
  };

  const renderOtherLanguageForm = (): React.ReactElement => {
    const formMap: Record<number, FormComponent> = {
      1: LeanerForm,
      2: AgeGroupForm,
      3: PrimaryReasonForm,
      4: reasonsForLearning.includes("Other") ? OtherReason : PersonalityForm,
      5: reasonsForLearning.includes("Other")
        ? PersonalityForm
        : WordsThatDescribe,
      6: reasonsForLearning.includes("Other")
        ? WordsThatDescribe
        : CompetenceForm,
      7: reasonsForLearning.includes("Other") ? CompetenceForm : ScheduleForm,
      8: reasonsForLearning.includes("Other") ? ScheduleForm : UserDataForm,
    };

    const Component = formMap[pageIndex] || UserDataForm;
    return <Component />;
  };

  const renderForm = (): React.ReactElement => {
    return subject === "igbo" ? renderIgboForm() : renderOtherLanguageForm();
  };

  return (
    <FormContainer
      onContinue={handleValidator}
      onCancel={handleFormCancel}
      currentIndex={pageIndex}
      numberOfQuestions={formNum}
    >
      {renderForm()}
    </FormContainer>
  );
};

export default FormLogic;
