import {
  IGeneralNotification,
  IMessageAndNotification,
} from "src/interfaces/notification";
import {
  getUreadNotifications,
  getUserNotifications,
  markAllNotificationsAsRead,
  sendBookingFailure,
  setReadNotifications,
} from "src/server/endpoints/notification";

export class NotificationApi {
  static getUserNotifications(
    userId: string
  ): Promise<IMessageAndNotification> {
    return getUserNotifications(userId);
  }
  static getUreadNotifications(
    userId: string
  ): Promise<IMessageAndNotification> {
    return getUreadNotifications(userId);
  }
  static setReadNotifications(
    userId: string,
    notificationId: string
  ): Promise<any> {
    return setReadNotifications(userId, notificationId);
  }
  static markAllNotificationsAsRead(userId: string): Promise<any> {
    return markAllNotificationsAsRead(userId);
  }
  static sendBookingFailure(body: {
    gender: string;
    name: string;
    subject: string;
    paymentMethod: string;
    errorMessage: string;
  }): Promise<any> {
    return sendBookingFailure(body);
  }
}
