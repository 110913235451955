// LessonSummary.tsx
import React, { useEffect } from "react";
import Confetti from "react-confetti"; // for celebration effect (npm i react-confetti)
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "src/Routes";

interface LessonSummaryProps {
  /**
   * The array of words learned during the session.
   * Usually passed in from your LearnSession state.
   */
  learnedWords: any[];

  /**
   * Callback for when the user wants to restart the session.
   */
  onRestart: () => void;
  onReturnToMenu: () => void;
}

/**
 * LessonSummary:
 *  - Celebratory confetti
 *  - Optional victory sound
 *  - A heading that says how many words were learned
 *  - Cards for each learned word
 *  - Buttons to "Restart" or "Close"
 */
const LessonSummary: React.FC<LessonSummaryProps> = ({
  learnedWords,
  onRestart,
  onReturnToMenu,
}) => {
  // Optional: play a short victory sound on mount
  useEffect(() => {
    const victorySound = new Audio(
      `${process.env.REACT_APP_ASSET_CDN}/victory.wav`
    );
    victorySound.play().catch(() => {
      /* ignore any playback errors */
    });
  }, []);

  return (
    <div className="min-h-[78vh] flex flex-col w-full items-center rounded-2xl justify-center bg-gradient-to-b from-primary to-lingawa-primary p-6">
      {/* Celebration Confetti */}
      <Confetti
        numberOfPieces={150}
        recycle={false}
        // adapt width/height if needed
      />

      {/* Main Container */}
      <div className="p-6 rounded-xl max-w-3xl w-full animate-scale-in">
        <h2 className="text-2xl font-bold text-center text-white mb-4">
          Session Summary
        </h2>

        <p className="text-lg text-white text-center mb-6">
          You learned{" "}
          <span className="font-bold text-orange-500">
            {learnedWords.length} {learnedWords.length === 1 ? "word" : "words"}
          </span>{" "}
          this session!
        </p>

        {/* Learned words as Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {learnedWords.map((word) => (
            <div
              key={word._id}
              className="bg-purple-100 rounded-lg shadow-md p-4 flex items-center gap-4 transform hover:scale-105 transition-transform"
            >
              {/* Optional image if you store images for each word */}
              {word.imageUrl && (
                <img
                  src={word.imageUrl}
                  alt={word.word}
                  className="w-16 h-16 object-cover rounded"
                />
              )}

              <div>
                <h3 className="text-gray-800 font-bold text-lg">{word.word}</h3>
                {word.englishTranslation && (
                  <p className="text-gray-600 text-sm italic">
                    {word.englishTranslation}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="mt-8 flex justify-center space-x-4">
          <button
            className="bg-lingawa-teal text-white px-4 py-2 rounded-md hover:bg-lingawa-teal-dark transition-all"
            onClick={onReturnToMenu}
          >
            Done
          </button>
          <button
            onClick={onRestart}
            className="bg-orange-600 text-white px-4 py-2 rounded-md hover:bg-orange-700 transition-all"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default LessonSummary;
