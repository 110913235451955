import { getInteractiveSessions, getInteractiveSession, getRandomWords, postOptionalFeedback, postSessionCompleteAuditLog, postSessionStartedAuditLog, postWasSessionUseful, getPromptWords, getTopicVocabList, postSaveFlashcardProgress, getTestYourKnowledgePrompts, getMatchingGameWords, getMatchingGameLeaderboard, postMatchingGameScore, getLearnSessionPrompts, saveLearningSessionCheckpoint, endLearningSession, restartLearningSession, removeWordFromSession, getUserTopicWordsProficiency } from "src/server/endpoints/learning-app";

export class LearningAppApi {
    static postWasSessionUseful(choice: string, user: any, uniqueId: string, sessionId?: string): Promise<any> {
        return postWasSessionUseful(choice, user, uniqueId, sessionId);
    }
    static postSaveFlashcardProgress(userId?: string, topicId?: any, wordsLearned?: any, wordsStillLearning?: string): Promise<any> {
        return postSaveFlashcardProgress(userId, topicId, wordsLearned, wordsStillLearning);
    }
    static postOptionalFeedback(message: string, uniqueId: string): Promise<any> {
        return postOptionalFeedback(message, uniqueId,);
    }
    static postSessionStartedAuditLog(user: any, languageId?: string, levelId?: string, sessionId?: string): Promise<any> {
        return postSessionStartedAuditLog(user, languageId, levelId, sessionId);
    }
    static postSessionCompleteAuditLog(user: any, results: any, sessionId?: string): Promise<any> {
        return postSessionCompleteAuditLog(user, results, sessionId);
    }
    static getRandomWords(ageGroup: string, basicPOScategory: any, level: number, numberOfWords: number, topics: any, languageId: any): Promise<any> {
        return getRandomWords(ageGroup, basicPOScategory, level, numberOfWords, topics, languageId);
    }
    static getInteractiveSessions(topicId: string, userId: string): Promise<any> {
        return getInteractiveSessions(topicId, userId);
    }
    static getInteractiveSession(sessionId: string): Promise<any> {
        return getInteractiveSession(sessionId);
    }
    static getTestYourKnowledgePrompts(topicId?: string): Promise<any> {
        return getTestYourKnowledgePrompts(topicId);
    }
    static getLearnSessionPrompts(userId?: string, topicId?: string, language?: string): Promise<any> {
        return getLearnSessionPrompts(userId, topicId, language);
    }
    static saveLearningSessionCheckpoint(sessionId: string, userId: string, topicId: string, currentSection: string, sectionStatus: number, completedWordIds: string[]): Promise<any> {
        return saveLearningSessionCheckpoint(sessionId, userId,
            topicId,
            currentSection,
            sectionStatus,
            completedWordIds);
    }
    static getTopicVocabList(topicId?: string, userId?: string): Promise<any> {
        return getTopicVocabList(topicId, userId);
    }
    static getPromptWords(wordBankIds: any): Promise<any> {
        return getPromptWords(wordBankIds);
    }
    static getMatchingGameWords(topicId: string): Promise<any> {
        return getMatchingGameWords(topicId);
    }
    static getMatchingGameLeaderboard(mode: string, topicId: string): Promise<any> {
        return getMatchingGameLeaderboard(mode, topicId);
    }
    static postMatchingGameScore(userId: string, mode: 'normal' | 'beast', topicId: string, timeTaken: number): Promise<any> {
        return postMatchingGameScore(userId, mode, topicId, timeTaken);
    }
    static endLearningSession(userId: string, sessionId: string, topicId: string): Promise<any> {
        return endLearningSession(userId, sessionId, topicId);
    }
    static removeWordFromSession(userId: string, sessionId: string, wordId: string): Promise<any> {
        return removeWordFromSession(userId, sessionId, wordId);
    }
    static restartLearningSession(userId: string, topicId: string): Promise<any> {
        return restartLearningSession(userId, topicId);
    }
    static getUserTopicWordsProficiency(userId: string, topicId: string): Promise<any> {
        return getUserTopicWordsProficiency(userId, topicId);
    }
}