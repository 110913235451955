import clsx from "clsx";
import assets from "src/assets/assets";
import useResponsive from "src/hooks/useResponsive";

const BaseCard = ({
	children,
	buttonTitle,
	padding,
	style,
	w,
	className,
	buttonLeadingIcon,
}: any) => {
	const _mob = useResponsive();

	return (
		<div
			className={clsx(
				`border border-gray-300 rounded-lg grid bg-white min-h-[50px] white-card-rounded`,
				_mob && "mb-5",
				className
			)}
			style={{
				padding: padding ?? 5,
				width: _mob ? "100%" : `${w}px`,
				...style,
			}}
		>
			{children}
			{buttonTitle?.length > 0 ? (
				<BaseCardButton leadingIcon={buttonLeadingIcon} title={buttonTitle} />
			) : (
				<></>
			)}
		</div>
	);
};

export default BaseCard;

export function BaseCardButton({
	title,
	leadingIcon,
	onClick,
	className,
}: any) {
	return (
		<button
			onClick={() => onClick?.()}
			className={clsx(
				"h-[60px] w-full bg-gray-200 rounded-lg bg-[#e84d17] text-white font-bold grid place-items-center cursor-pointer",
				className
			)}
			style={{
				backgroundColor: "#e84d17",
			}}
		>
			<div className="flex items-center">
				{leadingIcon && (
					<img
						src={leadingIcon}
						alt="leading-icon"
						className="w-[18px] h-[18px]"
					/>
				)}
				<p className="text-[14px] ml-[5px]">{title}</p>
			</div>
		</button>
	);
}

export function BaseCardTimeBlob({ float }: any) {
	return (
		<div
			className="bg-white rounded-lg p-[5px] text-[#6B0D4A] flex align-center"
			style={
				float
					? { top: 20, right: 10, position: "absolute" }
					: { position: "relative" }
			}
		>
			{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
			<img src={assets.dClock} alt="image" className="w-[20px] h-[20px]" />
			<p style={{ fontSize: 14, marginLeft: 5 }}>last Reviewed 6d ago</p>
		</div>
	);
}

export function BaseCardText({
	t,
	lg,
	md,
	sm,
	bold,
	center,
	className,
	style,
}: any) {
	return (
		<h2
			className={`text-${lg ? "xl" : md ? "md" : sm ? "sm" : "xl"} font-${
				bold ? "bold" : "normal"
			} text-[${lg ? "#6B0D4A" : "black"}] ${className}`}
			style={{ textAlign: center ? "center" : "left", ...style }}
		>
			{t}
		</h2>
	);
}
