import { useEffect, useState } from "react";
import { CustomerApi } from "src/api/customer.api";
import { useStateValue } from "src/context/StateProvider";
import Learn from "./Learn";
import Lessons from "./Lessons";
import Messages from "./Messages";
import Notifications from "./Notifications";
import ParentMessages from "./ParentMessages";
import ParentResources from "./ParentResources";
import Profile from "./Profile";
import Referral from "./Referral";
import Rewards from "./Rewards";
import UserProfileMenuItem from "./UserProfileMenuItem";
import ParentRewards from "./ParentRewards";

export default function Menu() {
  const [{ user, userLessonDetails }, dispatch] = useStateValue();
  const [dependants, setDependants] = useState<any>(null);

  useEffect(() => {
    const getDependants = async () => {
      const response = await CustomerApi.getDependantAccounts(user._id);
      await dispatch({
        type: "SET_DEPENDANTS",
        dependants: response,
      });
      setDependants(response);
    };
    if (user?.isParent) {
      getDependants();
    }
  }, [user]);

  return (
    <div>
      <p
        data-test="studentSideMenuHeader"
        className="text-xs text-gray-400 px-4 mb-6"
      >
        Student Dashboard
      </p>

      <ul>
        <li>
          <Profile />
        </li>

        <li>
          <Lessons />
        </li>

        <li>
          {user.isParent && dependants ? (
            <ParentResources dependants={dependants} />
          ) : (
            <Learn />
          )}
        </li>

        <li>
          {user.isParent && dependants ? (
            <ParentMessages dependants={dependants} />
          ) : (
            <Messages />
          )}
        </li>

        <li>
          {user.isParent && dependants ? (
            <ParentRewards dependants={dependants} />
          ) : (
            <Rewards />
          )}
        </li>

        <li>
          <Notifications />
        </li>

        <li>
          <Referral />
        </li>

        <li>
          <UserProfileMenuItem />
        </li>
      </ul>
    </div>
  );
}
