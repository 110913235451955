import { ReactComponent as MobileContactIcon } from "src/assets/icons/mobile-contact.svg";

type Props = {
  onClick: () => void;
};

export default function MobileContact({ onClick }: Props) {
  return (
    <div
      className="text-primary flex gap-3 items-center lg:hidden cursor-pointer"
      onClick={onClick}
    >
      <MobileContactIcon className="w-[20px]" />
      <h3 className="text-lg font-semibold ">Contact Us</h3>
    </div>
  );
}
