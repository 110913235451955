import React, { ReactNode } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StudentApi } from "src/api/student.api";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";

interface IPracticeSectionProps {
  title: string;
  icon?: string;
  description: string;
  link?: string;
  id?: number;
}
export default function Resources({
  title,
  icon,
  description,
  link,
  id,
}: IPracticeSectionProps) {
  const [{ user }] = useStateValue();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { level, userId, topic, order, topicId, dependantId, dependantName } =
    useParams();

  // let route = dependantId
  //   ? `${routes.DEPENDANT_DASHBOARD_LEARN.url}/${dependantName}/${dependantId}`
  //   : routes.STUDENT_DASHBOARD_LEARN.url;
  const route =
    user?.type === "student"
      ? routes.STUDENT_DASHBOARD_LEARN.url
      : routes.TUTOR_DASHBOARD_LEARN.url;
  const handleNavigate = () => {
    if (id === 4) {
      // for sentence construction
      navigate(
        `${route}/${userId}/${level}/${order}/${topic}/${topicId}${routes.SENTENCE_CONSTRUCTION_SELECTION.url}`,
        {
          state: { curriculum: state?.curriculum, ageGroup: state?.ageGroup },
        }
      );
    } else if (id === 7) {
      // for test your knowledge
      navigate(
        `${route}/${userId}/${level}/${order}/${topic}/${topicId}${routes.TEST_YOUR_KNOWLEDGE.url}`,
        {
          state: { curriculum: state?.curriculum, ageGroup: state?.ageGroup },
        }
      );
    } else if (id === 8) {
      // for matching game
      navigate(
        `${route}/${userId}/${level}/${order}/${topic}/${topicId}${routes.MATCHING_GAME.url}`,
        {
          state: { curriculum: state?.curriculum, ageGroup: state?.ageGroup },
        }
      );
    } else if (id === 9) {
      // for learning session
      navigate(
        `${route}/${userId}/${level}/${order}/${topic}/${topicId}${routes.LEARN_SESSION.url}`,
        {
          state: { curriculum: state?.curriculum, ageGroup: state?.ageGroup },
        }
      );
    } else if (link) {
      window.open(link, "_blank");
    } else {
      navigate(
        `${route}/${userId}/${level}/${order}/${topic}/${topicId}/${title}`,
        {
          state: {
            id,
            curriculum: state?.curriculum,
            ageGroup: state?.ageGroup,
          },
        }
      );
    }
    if (topicId && id !== 6)
      StudentApi.updateTopicLastPracticed(user?._id, { topicId });
  };

  return (
    <section
      className="pb-2 pt-2 px-5 h-full border-b-8 border-2 rounded-2xl border-gray-200 hover:border-orange-600 cursor-pointer hover:bg-pale-orange transition-all duration-200"
      onClick={handleNavigate}
    >
      <div className="flex  gap-4 pt-2" w-10>
        <span className="inline-block rounded-[9px]">
          {icon ? (
            <img src={icon} alt="Icon" className="w-16 h-10" />
          ) : (
            <span className="text-gray-400">No Icon Available</span>
          )}
        </span>
        <div className="">
          <p className="font-semibold text-xl text-lingawa-primary">{title}</p>
          <p className="mt-[10px] text-sm text-gray-400 font-semibold">
            {description}
          </p>
        </div>
      </div>
    </section>
  );
}
