import React, { useEffect, useState } from "react";
import { useStateValue } from "src/context/StateProvider";
import AvatarIcon from "./AvatarIcon";
import { User } from "src/interfaces/direct-pay";
import { useParams } from "react-router-dom";

type ProfileSwitcherProps = {
  switchHandler: (user: User) => void;
};

export default function ProfileSwitcher({
  switchHandler,
}: ProfileSwitcherProps) {
  const [{ dependants, user }] = useStateValue();
  const [activeUser, setActiveUser] = useState<User | null>(null);
  const { userId } = useParams();

  useEffect(() => {
    setActiveUser(dependants?.find((dep: User) => dep._id === userId) || user);
  }, [dependants, userId]);
  return (
    <section>
      <p
        className="font-medium"
        style={{
          color: "#979798",
        }}
      >
        Click on avatar to switch user
      </p>
      <div
        style={{
          borderColor: "#C8C8C8",
        }}
        className="border border-b-4 rounded-2xl p-3 flex gap-2 w-fit bg-white"
      >
        <div className="px-4 flex flex-col  items-center" key={user?._id}>
          <AvatarIcon
            avatar={user?.tutoringAppAvatar}
            active={activeUser?._id === user?._id}
            onClick={() => {
              setActiveUser(user);
              switchHandler(user);
            }}
          />
          <p
            className={`mt-1 ${
              activeUser?._id === user?._id ? "font-bold " : "font-medium"
            }`}
            style={{
              color: activeUser?._id === user?._id ? "#340d24" : "#979798",
            }}
          >
            Me
          </p>
        </div>
        {dependants?.map((dependant: User) => (
          <div
            className="px-4 flex flex-col  items-center"
            key={dependant?._id}
          >
            <AvatarIcon
              avatar={dependant?.tutoringAppAvatar}
              active={activeUser?._id === dependant?._id}
              onClick={() => {
                setActiveUser(dependant);
                switchHandler(dependant);
              }}
            />
            <p
              className={`mt-1 ${
                activeUser?._id === dependant?._id
                  ? "font-bold "
                  : "font-medium"
              }`}
              style={{
                color:
                  activeUser?._id === dependant?._id ? "#340d24" : "#979798",
              }}
            >
              {dependant?.firstname}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
