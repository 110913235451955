import React from "react";
import { CardData } from "./types";

interface CardProps {
  card: CardData;
  isSelected: boolean;
  isIncorrectSelection: boolean;
  mode: "normal" | "beast";
  onClick: () => void;
}

const Card: React.FC<CardProps> = ({
  card,
  isSelected,
  isIncorrectSelection,
  mode,
  onClick,
}) => {
  const { text, matched, imageUrl, type } = card;

  // For Beast Mode: show the back face if selected or matched
  const beastModeFlipped = mode === "beast" && (isSelected || matched);
  const showImageOnFront = mode === "normal" && type === "english";
  const showImageOnBack =
    type === "english" &&
    (mode === "normal" || (mode === "beast" && beastModeFlipped));

  // Base container classes for the flipping effect
  const baseClasses = `
    relative 
    w-full h-full 
    cursor-pointer 
    [perspective:1000px]
    transition-transform duration-300
  `;

  const innerClasses = `
    card-inner
    relative w-full h-full 
    transition-transform duration-300 
    [transform-style:preserve-3d]
    ${beastModeFlipped ? "[transform:rotateY(180deg)]" : ""}
  `;

  // Common classes for both faces
  const faceBaseClasses = `
    absolute inset-0 flex items-center justify-center 
    rounded-lg border border-gray-300 
    text-sm lg:font-semibold 
    [backface-visibility:hidden] 
    p-2 
    ${showImageOnFront ? `bg-black` : `bg-lingawa-primary`}
    text-white 
    lg:text-lg
    transition-opacity
    duration-300
  `;

  let frontFaceClasses = faceBaseClasses;
  let backFaceClasses = faceBaseClasses;

  // Handle matched: fade out using opacity, removing pointer events
  if (matched) {
    frontFaceClasses += " opacity-0 pointer-events-none";
    backFaceClasses += " opacity-0 pointer-events-none";
  } else {
    // Not matched:
    // If this card is selected and not incorrect
    if (isSelected && !isIncorrectSelection) {
      // Special case for Normal Mode + English card (with an image):
      // => highlight border in orange, but no teal background overwriting the image
      if (mode === "normal" && type === "english" && imageUrl) {
        frontFaceClasses += " ring-4 ring-lingawa-teal";
        backFaceClasses += " ring-4 ring-lingawa-teal";
      } else if (mode === "beast" && type === "english" && imageUrl) {
        // Default highlight for other scenarios
        frontFaceClasses += ` ring-4 ring-lingawa-teal bg-black`;
        backFaceClasses += ` ring-4 ring-lingawa-teal bg-black`;
      } else {
        frontFaceClasses += " bg-lingawa-teal ring-4 ring-lingawa-teal";
        backFaceClasses += " bg-lingawa-teal ring-4 ring-lingawa-teal";
      }
    }

    // Incorrect selection
    if (isIncorrectSelection) {
      frontFaceClasses += `${
        mode === "beast" && type === "english" && imageUrl
          ? "bg-black"
          : "bg-orange-600"
      } ring-4 ring-lingawa-orange`;
      backFaceClasses += `${
        mode === "beast" && type === "english" && imageUrl
          ? "bg-black"
          : "bg-orange-600"
      } ring-4 ring-lingawa-orange`;
    }
  }

  console.log("showImageOnFront", showImageOnFront);
  console.log("showImageOnBack", showImageOnBack);

  // FRONT / BACK TEXT:
  // In Beast Mode, front face is empty (or ???) unless flipped
  const frontText = mode === "normal" || beastModeFlipped ? text : "";
  const backText = text;

  // Logic for showing images:
  // - Normal Mode, English card => show image on front & back
  // - Beast Mode, English card => show image only on back if flipped

  const frontStyle = showImageOnFront
    ? {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }
    : {};

  const backStyle = showImageOnBack
    ? {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }
    : {};

  return (
    <div className={baseClasses} onClick={() => !matched && onClick()}>
      <div className={innerClasses}>
        {/* FRONT FACE */}
        <div className={`card-front ${frontFaceClasses}`} style={frontStyle}>
          {/* If showing image, overlay text on black translucent background */}
          {showImageOnFront ? (
            <div
              className={`bg-black ${frontFaceClasses} absolute inset-0  bg-opacity-60 flex items-center justify-center rounded-lg px-1 md:px-2 text-center`}
            >
              {frontText}
            </div>
          ) : (
            <div className="text-center">{frontText}</div>
          )}
        </div>

        {/* BACK FACE */}
        <div
          className={`card-back ${backFaceClasses} [transform:rotateY(180deg)]`}
          style={backStyle}
        >
          {showImageOnBack ? (
            <div
              className={`bg-black ${frontFaceClasses} absolute inset-0  bg-opacity-60 flex items-center justify-center rounded-lg px-1 md:px-2 text-center`}
            >
              {backText}
            </div>
          ) : (
            <div className="text-center">{backText}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
