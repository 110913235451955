import axios from "axios";

export async function getTeacherRefferalDetails (referrerId?: string): Promise<any | undefined[]> {
  try {
      let response  = await axios.get(`${process.env.REACT_APP_SERVER_URL}/teacher-referrer-details/${referrerId}`);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}

export async function postTeacherRefferalDetails (referrerId: string, data: any): Promise<any | undefined[]> {
  try {
      let response  = await axios.post(`${process.env.REACT_APP_SERVER_URL}/save-teacher-referrer-details/${referrerId}`, data);
      return response
    } catch (error) {
      console.log("caught error: ", error);
  }
}

export async function getTeacherRefferalStatistics (referrerId?: string): Promise<any | undefined[]> {
  try {
      let response  = await axios.get(`${process.env.REACT_APP_SERVER_URL}/teacher-referrer-statistics/${referrerId}`);
      return response.data
    } catch (error) {
      console.log("caught error: ", error);
  }
}