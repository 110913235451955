import React, { useEffect, useRef, useState } from "react";
import BackToGamesAndResourcesBreadCrumb from "src/components/StudentDashboard/Learn/BackToGamesAndResourcesBreadCrumb";
import { useStateValue } from "src/context/StateProvider";
import { useParams } from "react-router-dom";
import {
  LessonResources,
  lessonResources,
} from "src/static-data/lessonResources";
import Resources from "src/components/StudentDashboard/Learn/Resources";
import EmptyList from "src/components/Shared/EmptyList";
import SmallResourceCard from "src/components/StudentDashboard/Learn/SmallResourceCard";
import { LearningAppApi } from "src/api/learning-app";
import ProficiencyBadge from "./ProficiencyBadge";
import { HiMiniSpeakerWave } from "react-icons/hi2";

const practiceSessions = [
  {
    id: 9,
    title: "Vocab Drills",
    icon: `${process.env.REACT_APP_ASSET_CDN}/vocab-drills.svg`,
    description:
      "Lock in new words and phrases with smart exercises and flashcards",
  },
  {
    id: 1,
    title: "Lesson Slides",
    icon: `${process.env.REACT_APP_ASSET_CDN}/lesson-slides.svg`,
    description: "Review learning outcomes and grammar tips",
  },
  {
    id: 8,
    title: "Matching Game",
    icon: `${process.env.REACT_APP_ASSET_CDN}/matching-game.svg`,
    description:
      "Fight your way to the top and put your vocab skills to the test",
  },
  {
    id: 10,
    title: "Minigame",
    icon: `${process.env.REACT_APP_ASSET_CDN}/mini-games-icon.svg`,
    description:
      "Play a fun topic-focused game to reinforce your knowledge while having a blast!",
  },
  {
    id: 11,
    title: "Fun Videos",
    icon: `${process.env.REACT_APP_ASSET_CDN}/fun-videos-icon.svg`,
    description:
      "Fight your way to the top and put your vocab skills to the test",
  },
  {
    id: 12,
    title: "Storytime Worksheet (5-8)",
    icon: `${process.env.REACT_APP_ASSET_CDN}/strory-time-icon.svg`,
    description:
      "Fight your way to the top and put your vocab skills to the test",
  },
  {
    id: 2,
    title: "Quizlet Sets",
    icon: `${process.env.REACT_APP_ASSET_CDN}/letter-q.svg`,
    description:
      "Practice your words on Greetings with your lesson quizlet FlashCards!",
  },
  {
    id: 3,
    title: "Dialogue Video",
    icon: `${process.env.REACT_APP_ASSET_CDN}/dialogue-video.svg`,
    description: "Build your fluency with a real conversation",
  },
  {
    id: 5,
    title: "Flashcards",
    icon: `${process.env.REACT_APP_ASSET_CDN}/flashcards.svg`,
    description:
      "An alternative to Vocab Drills to learn new words and phrases",
  },

  // {
  //   id: 7,
  //   title: "Test Your Knowledge",
  //   icon: <PiExamFill className="text-purple-500" size={25} />,
  //   description:
  //     "Test your knowledge on the words and phrases you’ve learnt in this lesson.",
  // },
  {
    id: 4,
    title: "Sentence Construction",
    icon: `${process.env.REACT_APP_ASSET_CDN}/sentence-construction.svg`,
    description:
      "Play the sentence constructor game to construct different sentences with the words you’ve learnt!",
  },
  {
    id: 6,
    title: "Prerequisites",
    icon: `${process.env.REACT_APP_ASSET_CDN}/prerequisites.svg`,
    description:
      "Review the key topics and skills you should be familiar with before starting this session!",
  },
  {
    id: 13,
    title: "Lesson Plan",
    icon: `${process.env.REACT_APP_ASSET_CDN}/lesson-plan.svg`,
    description: "See all you need to deliver impactful lessons",
  },
];

export default function TopicDetails() {
  const [{ userLessonProgress, user }] = useStateValue();
  const { userId, level, topic, topicId } = useParams();
  const [hasSessions, setHasSessions] = useState(false);
  const [quizletSet, setQuizletSet] = useState<string | null>(null);
  const [grammarSpotlight, setGrammarSpotlight] = useState<string | null>(null);
  const [practiceVideo, setPracticeVideo] = useState<any>(null);
  const [funVideo, setFunVideo] = useState<any>(null);
  const [lessonPlan, setLessonPlan] = useState<any>(null);
  const [miniGame, setMiniGame] = useState<any>(null);
  const [storytimeWorksheet, setStorytimeWorksheet] = useState<any>(null);
  const [ageGroup, setAgeGroup] = useState("");
  const [userProficiencyList, setUserProficiencyList] = useState<any>([]);
  const prerequisites = userLessonProgress?.unordered?.find(
    (t: any) => t?.topicId === topicId
  )?.prerequisites;

  console.log("userLessonProgress", userLessonProgress);
  console.log("ageGroup", ageGroup);

  const getUserTopicWordsProficiency = async () => {
    if (!userId || !topicId) return;
    const response = await LearningAppApi.getUserTopicWordsProficiency(
      userId,
      topicId
    );
    setUserProficiencyList(response?.data?.wordList);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserTopicWordsProficiency();
  }, [topicId, userId]);

  console.log("userProficiencyList", userProficiencyList);

  useEffect(() => {
    if (!userLessonProgress) return;
    setAgeGroup(userLessonProgress.ageGroup);

    // Check if any sessions are rendered
    const sessionsExist = practiceSessions.some((session) => {
      const quizlet = userLessonProgress?.unordered?.find(
        (t: any) => t?._id === topicId
      )?.quizletSet;
      const grammarSpotlight = userLessonProgress?.unordered?.find(
        (t: any) => t?._id === topicId
      )?.grammarSpotlight;
      const videoIframe = userLessonProgress?.unordered?.find(
        (t: any) => t?._id === topicId
      )?.practiceVideo;
      const funVideo = userLessonProgress?.unordered?.find(
        (t: any) => t?._id === topicId
      )?.funVideo;
      const storytimeWorksheet = userLessonProgress?.unordered?.find(
        (t: any) => t?._id === topicId
      )?.storytimeWorksheet;
      const miniGame = userLessonProgress?.unordered?.find(
        (t: any) => t?._id === topicId
      )?.miniGame;
      const lessonPlan = userLessonProgress?.unordered?.find(
        (t: any) => t?._id === topicId
      )?.lessonPlan;

      if (grammarSpotlight) {
        setGrammarSpotlight(grammarSpotlight);
      }
      if (quizlet) {
        setQuizletSet(quizlet);
      }
      if (videoIframe) {
        setPracticeVideo(videoIframe);
      }
      if (funVideo) {
        setFunVideo(funVideo);
      }
      if (storytimeWorksheet) {
        setStorytimeWorksheet(storytimeWorksheet);
      }
      if (miniGame) {
        setMiniGame(miniGame);
      }
      if (lessonPlan) {
        setLessonPlan(lessonPlan);
      }

      return (
        (session.id === 1 && grammarSpotlight) ||
        (session.id === 2 && quizlet) ||
        (session.id === 3 && videoIframe) ||
        (session.id === 11 && funVideo) ||
        (session.id === 12 && storytimeWorksheet) ||
        (session.id === 10 && miniGame) ||
        (session.id === 13 && lessonPlan) ||
        session.id === 4
      );
    });

    setHasSessions(sessionsExist);
  }, [userLessonProgress, level, topic]);

  const moreExercises = (
    <div>
      {" "}
      {practiceSessions.map((session) => {
        // Quizlet Sets
        if (session.id === 2 && quizletSet && ageGroup !== "Adult") {
          return (
            <div className="mb-4 h-full" key={session.id}>
              <SmallResourceCard
                id={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
                link={quizletSet}
              />
            </div>
          );
        }
        // Flashcards
        if (session.id === 5 && topic !== "Christmas specials") {
          return (
            <div className="mb-4 h-full" key={session.id}>
              <SmallResourceCard
                id={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
              />
            </div>
          );
        }
        // Sentence Construction
        if (session.id === 4 && topic !== "Christmas specials") {
          return (
            <div className="mb-4 h-full" key={session.id}>
              <SmallResourceCard
                id={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
              />
            </div>
          );
        }
        // Lesson Slides
        if (session.id === 1 && grammarSpotlight && ageGroup !== "Adult") {
          return (
            <div className="mb-4 h-full" key={session.id}>
              <SmallResourceCard
                id={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
              />
            </div>
          );
        }
        // Fun Videos
        if (
          session.id === 11 &&
          topic !== "Christmas specials" &&
          practiceVideo &&
          funVideo &&
          ageGroup !== "Adult"
        ) {
          return (
            <div className="mb-4 h-full" key={session.id}>
              <SmallResourceCard
                id={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
              />
            </div>
          );
        }
        // Storytime Worksheet (5-8)
        if (
          session.id === 12 &&
          topic !== "Christmas specials" &&
          storytimeWorksheet &&
          ageGroup === "Child (5-8)"
        ) {
          return (
            <div className="mb-4 h-full" key={session.id}>
              <SmallResourceCard
                id={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
                link={storytimeWorksheet}
              />
            </div>
          );
        }
        if (session.id === 6 && prerequisites && prerequisites.length > 0) {
          return (
            <div className="mb-4 h-full" key={session.id}>
              <SmallResourceCard
                id={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
              />
            </div>
          );
        }
        // Tutor lesson plan
        if (session.id === 13 && lessonPlan && user.type === "tutor") {
          return (
            <div className="mb-4 h-full" key={session.id}>
              <SmallResourceCard
                id={session.id}
                title={session.title}
                icon={session.icon}
                description={session.description}
              />
            </div>
          );
        }
        // If no condition is met, return null
        return null;
      })}
    </div>
  );

  const playAudio = (audioUrl?: string) => {
    if (!audioUrl) return;
    const audio = new Audio(audioUrl);
    audio.play().catch((err) => console.error("Audio playback failed", err));
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
        {/* First div (spans two columns on md and above) */}
        <div className="md:col-span-2">
          <BackToGamesAndResourcesBreadCrumb />

          <div className="flex flex-col w-full items-center rounded-2xl">
            <div className="max-w-3xl w-full animate-scale-in">
              {/* Main ACtivity Cards */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 mt-8">
                {practiceSessions.map((session) => {
                  /**  Vocab Drills
                  Dialogue Video
                  Minigame
                  Matching Game
                  Fun Videos
                  Lesson Slides */
                  if (
                    (session.id === 1 &&
                      grammarSpotlight &&
                      ageGroup === "Adult") ||
                    (session.id === 3 && practiceVideo) ||
                    (session.id === 8 && topic !== "Christmas specials") ||
                    (session.id === 11 &&
                      topic !== "Christmas specials" &&
                      !practiceVideo &&
                      funVideo &&
                      ageGroup !== "Adult") ||
                    (session.id === 9 && topic !== "Christmas specials")
                  ) {
                    return (
                      <div className="mb-4 h-full" key={session.id}>
                        <Resources
                          id={session.id}
                          title={session.title}
                          icon={session.icon}
                          description={session.description}
                        />
                      </div>
                    );
                  }

                  if (session.id === 10 && miniGame && ageGroup !== "Adult") {
                    return (
                      <div className="mb-4 h-full" key={session.id}>
                        <Resources
                          id={session.id}
                          title={session.title}
                          icon={session.icon}
                          description={session.description}
                          link={
                            miniGame?.match(
                              /scratch\.mit\.edu\/projects\/(\d+)\/fullscreen/
                            )
                              ? ""
                              : miniGame
                          }
                        />
                      </div>
                    );
                  }

                  // If no condition is met, return null
                  return null;
                })}
              </div>

              {/* Small Activity Cards -- Hidden on Desktop */}
              <div className="md:hidden mt-8">
                <p className="text-primary font-semibold mb-4 text-lg">
                  More Exercises
                </p>
                <div> {moreExercises}</div>
              </div>

              {/* Proficiency list */}
              <div>
                {userProficiencyList?.length > 0 ? (
                  <div className="mt-10">
                    <p className="text-primary font-semibold text-lg">
                      {`Vocabulary List (${userProficiencyList?.length})`}
                    </p>
                    <p className="text-gray-400 mb-6">
                      Each avatar below reflects your Vocab Drills progress!
                    </p>
                    <div className="grid grid-cols-1 gap-4">
                      {userProficiencyList?.map((word: any) => (
                        <div
                          key={word.wordId}
                          className="pb-2 pt-2 px-3 h-full flex items-center gap-4 justify-between border-2 rounded-lg border-gray-200 transition-all duration-200"
                        >
                          <div className="flex items-center gap-4">
                            <ProficiencyBadge proficiency={word.proficiency} />
                            <p className="text-lingawa-primary font-semibold md:text-lg text-xs">
                              {word.word}
                            </p>
                            <div> | </div>
                            <p className="text-gray-500 md:text-lg text-xs">
                              {word.englishTranslation}
                            </p>
                          </div>
                          {word.audioUrl && (
                            <div>
                              <HiMiniSpeakerWave
                                size={25}
                                onClick={() => playAudio(word.audioUrl)}
                                className="cursor-pointer text-lingawa-primary hover:text-lingawa-teal"
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* Second div - Hidden on Mobile (occupies the remaining single column on md and above) */}
        <div className="px-4 hidden md:block">
          <p className="text-primary font-semibold mb-10 text-lg">
            More Exercises
          </p>
          <div> {moreExercises}</div>
        </div>
      </div>
    </>
  );
}
