import { CheckCircleIcon } from "@heroicons/react/solid";
import RevolutCheckout, { RevolutCheckoutInstance } from "@revolut/checkout";
import {
	CardElement,
	Elements,
	PaymentRequestButtonElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Lottie from "lottie-react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import ReactModal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { LessonApi } from "src/api/lesson.api";
import { PaymentApi } from "src/api/payment.api";
import * as loadingAnimation from "src/assets/animations/rose-loader.json";
import CardInfo from "src/components/Checkout/CardInfo";
import CheckoutSummary, {
	ICheckoutSummary,
} from "src/components/Checkout/CheckoutSummary";
import InvalidPaymentLink from "src/components/Checkout/InvalidPaymentLink";
import PreppingDashboard from "src/components/LottieAnimations/PreppingDashboard";
import CircularProgress from "src/components/Shared/CircularProgress";
import RefundPolicyText from "src/components/Shared/RefundPolicyText";
import { useDirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import { BillingType, Instance, PaymentGateways } from "src/interfaces/payment";
import Header from "src/layouts/DashboardHeader";
import { routes } from "src/Routes";
import {
	assertStripeError,
	capitalizeFirstCharacter,
} from "src/utils/helper/core";
import Swal from "sweetalert2";
const logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-logo%20copy.png`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-logo%20copy.png`;
const USE_REGIONS = false;
interface CheckoutState {
	loading: boolean;
	gateway?: PaymentGateways;
	billingType?: BillingType;
	id?: string | null;
	invalid?: boolean;
	paymentDoc?: any;
	checkoutSummary?: ICheckoutSummary;
	chargeInProgress: boolean;
	bookingInProgress: boolean;
	cardDetails?: any;
	region?: any;
	freeTrial?: boolean;
}

const SubCheckout = ({
	checkout,
	setCheckout,
}: {
	checkout: CheckoutState;
	setCheckout: any;
}) => {
	const navigate = useNavigate();
	const elements = useElements();
	const stripe = useStripe();
	const browserRoute = useMemo(() => new URL(window.location.href), []);
	const [instance, setInstance] = useState<any>();
	const [g_APay, setG_APay] = useState(false);
	const [stripeGateway, setStripeGateway] = useState({
		open: false,
		success: false,
		loading: false,
		paymentRequest: undefined,
	});
	const [revolutGateway, setRevolutGateway] = useState({
		iframeLoading: false,
	});
	const [{ user }] = useStateValue();
	const {
		state: { onBoarding },
	} = useDirectPayContext();
	const cardElementOptions = {
		hidePostalCode: true,
	};

	const updatePaymentHistory = useCallback(
		async (state: string, paymentMethod?: any) => {
			const paymentApi = new PaymentApi();

			try {
				const res = await paymentApi.updatePayment({
					paymentId: checkout?.paymentDoc?._id,
					state,
					paymentMethod: JSON.stringify(paymentMethod),
				});

				console.log("update payment", res);
			} catch (error) {
				console.log("caught error: ", error);
			}
		},
		[checkout?.paymentDoc?._id]
	);

	useEffect(() => {
		if (stripe) {
			const pr = stripe.paymentRequest({
				country: "US",
				currency: checkout?.checkoutSummary?.currency?.toLowerCase() ?? "usd",
				total: {
					label: "Total",
					amount: 1000,
				},
				requestPayerName: true,
				requestPayerEmail: true,
			});

			pr.canMakePayment().then((result) => {
				if (result) {
					setStripeGateway((p: any) => ({ ...p, paymentRequest: pr }));
				}
			});

			pr.on("paymentmethod", async (event) => {
				const cardElement = elements?.getElement(CardElement);
				if (cardElement) {
					const { error, paymentMethod } = await stripe?.createPaymentMethod({
						type: "card",
						card: cardElement,
						billing_details: {
							name: `${checkout?.paymentDoc?.invoice?.firstname} ${checkout?.paymentDoc?.invoice?.lastname}`,
						},
					});
				}
			});
		}
	}, [stripe, elements]);

	async function onCheckoutError(err: any) {
		try {
			const res = await PaymentApi.checkoutError(
				checkout?.paymentDoc?._id,
				err
			);
			console.log("checkout error", res);
		} catch (error) {
			console.log("caught error: ", error);
		}
	}

	async function onPaymentSuccess(paymentMethod?: any) {
		setCheckout((p: CheckoutState) => ({
			...p,
			chargeInProgress: false,
			loading: true,
		}));

		await updatePaymentHistory("COMPLETE", paymentMethod);

		setCheckout((p: CheckoutState) => ({
			...p,
			loading: false,
			bookingInProgress: true,
		}));

		const _bill =
			checkout?.paymentDoc?.[`${checkout.billingType?.toLowerCase()}Id`];

		try {
			const res = await LessonApi.autoBookLesson({
				[`${checkout.billingType?.toLowerCase()}Id`]: _bill,
			});

			console.log("auto book lesson", res);
		} catch (error) {
			console.log("caught error: ", error);
		} finally {
			Swal.fire({
				color: "#341A64",
				title: `<strong>Thanks for your payment</strong>`,
				html: `<p>Your lessons will be booked shortly.</p><p>Feel free to <a href="mailto:info@lingawa.com" style="text-decoration: underline;" >reach out to us</a> for any further assistance</p>`,
				confirmButtonText: `OK`,
				confirmButtonColor: "#D33479",
			}).then(() => {
				setCheckout((p: CheckoutState) => ({ ...p, bookingInProgress: false }));
				navigate(
					`${routes.CHECKOUT_THANK_YOU.url}?amount=${
						checkout?.paymentDoc?.amount?.value as string
					}&currency=${checkout?.paymentDoc?.amount?.currency}`
				);
			});
		}
	}

	async function initiateStripePayment(event: any) {
		setStripeGateway((p) => ({ ...p, loading: true }));
		event.preventDefault();

		if (!stripe || !elements) return;

		const cardElement = elements.getElement(CardElement);

		if (cardElement) {
			const { error, paymentMethod } = await stripe
				.createPaymentMethod({
					type: "card",
					card: cardElement,
					billing_details: {
						name: `${checkout?.paymentDoc?.invoice?.firstname} ${checkout?.paymentDoc?.invoice?.lastname}`,
					},
				})
				.then(async function (result) {
					if (result.error) {
						return result;
					}

					const sI = JSON.parse(checkout?.paymentDoc?.gateway?.setupIntent);

					const setupIntent = await stripe.confirmCardSetup(sI?.client_secret, {
						payment_method: result.paymentMethod.id,
					});

					if (setupIntent.error) {
						return { error: setupIntent.error };
					}

					return result;
				});

			if (error) {
				assertStripeError(error, Swal);
				setStripeGateway((p) => ({ ...p, loading: false }));
				console.error(error);
				await onCheckoutError(error);
			} else {
				const pI = JSON.parse(checkout?.paymentDoc?.gateway?.paymentIntent);

				const { error: confirmError, paymentIntent } =
					await stripe.confirmCardPayment(pI?.client_secret, {
						payment_method: paymentMethod.id,
					});

				if (confirmError) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "An error occurred while processing your payment. Please try again later",
					});
					console.error(confirmError);
					await onCheckoutError(confirmError);
				} else if (paymentIntent) {
					setStripeGateway((p) => ({ ...p, success: true, loading: false }));

					setTimeout(async () => {
						setStripeGateway((p) => ({ ...p, open: false }));
						await onPaymentSuccess(paymentMethod);
					}, 500);

					console.log("Payment successful:", paymentIntent);
				}
			}
		} else {
			onCheckoutError("Card Element is null");
		}
	}

	const handleViewRoadmap = () => {
		const language = checkout?.checkoutSummary?.subject;
		const userAgeGroup =
			checkout?.paymentDoc?.subscription?.user?.onboarding?.ageGroup;

		console.log("checkout", checkout);

		let ageGroup = "";
		if (userAgeGroup === "Adult") {
			ageGroup = "adult";
		} else if (
			userAgeGroup === "Child (9-12)" ||
			userAgeGroup === "Child (5-8)"
		) {
			ageGroup = "child";
		}

		console.log("ageGroup", ageGroup);

		// Map language and age group combinations to URLs
		const roadmapUrls: { [key: string]: string } = {
			"Igbo-adult": "https://lingawa.com/igbo-roadmap/",
			"Yoruba-adult": "https://lingawa.com/yoruba-roadmap/",
			"Yoruba-child":
				"https://lingawa.com/wp-content/uploads/2025/01/Kids-Yoruba-Level-1-Roadmap.pdf",
			"Igbo-child":
				"https://lingawa.com/wp-content/uploads/2025/01/Kids-Igbo-Level-1-Roadmap.pdf",
		};

		const key = `${language}-${ageGroup}`;
		const roadmapUrl = roadmapUrls[key];

		if (roadmapUrl) {
			window.open(roadmapUrl, "_blank");
		} else {
			console.warn(
				`No roadmap found for language: ${language} and age group: ${ageGroup}`
			);
		}
	};

	const handleRevolutPaymentRequest = useCallback(() => {
		RevolutCheckout(
			checkout?.paymentDoc.publicId || checkout?.paymentDoc.token,
			`${
				browserRoute.host === "learn.topset.app" ||
				browserRoute.host === "learn.lingawa.com" ||
				browserRoute.host === "lingawa.com" ||
				browserRoute.host === "topset.app"
					? "prod"
					: "sandbox"
			}`
		).then((instance: Instance | RevolutCheckoutInstance) => {
			const paymentRequest = instance.paymentRequest({
				target: document.getElementById("revolut-payment-request")!,
				async onSuccess() {
					onPaymentSuccess();
				},
				savePaymentMethodFor: "merchant",
				onError(error) {
					console.log(`Error: ${error.message}`);
					updatePaymentHistory("FAILED");
				},
				buttonStyle: { size: "small", variant: "light" },
			});

			paymentRequest.canMakePayment().then((method: any) => {
				if (method) {
					paymentRequest.render();
				} else {
					console.log("Not supported");
					paymentRequest.destroy();
				}
			});

			setInstance(instance);
		});
	}, [
		// browserRoute.host,
		checkout?.paymentDoc?.publicId,
		checkout?.paymentDoc?.token,
	]);

	useEffect(() => {
		if (checkout?.paymentDoc?.publicId || checkout?.paymentDoc?.token)
			handleRevolutPaymentRequest();
	}, [handleRevolutPaymentRequest]);

	async function initiatePayment(e: any) {
		switch (checkout?.paymentDoc?.gateway.name) {
			case "STRIPE":
				if (checkout?.freeTrial) {
					// setStripeGateway((p) => ({ ...p, loading: true }));
					// setTimeout(() => {
					// setStripeGateway((p) => ({ ...p, loading: false, success: true }));
					// setTimeout(async () => {
					await onPaymentSuccess();
					// }, 1500);
					// }, 2500);
				} else {
					initiateStripePayment(e);
				}
				break;

			default:
				setTimeout(
					() => setRevolutGateway({ ...revolutGateway, iframeLoading: false }),
					2000
				);

				instance?.payWithPopup({
					savePaymentMethodFor: "merchant",
					onError(message: any) {
						console.log("error", message);
						updatePaymentHistory("FAILED");
					},
					onCancel() {},
					async onSuccess() {
						onPaymentSuccess();
					},
				});
		}
	}

	async function testGooglePay() {
		if (!stripe) return;

		// Create a Payment Request object
		const paymentRequest = stripe.paymentRequest({
			country: "US",
			currency: "usd",
			total: {
				label: "Total",
				amount: 1000, // Amount in cents (e.g., $10.00)
			},
		});

		// Check if Google Pay is available
		const canMakePayment = await paymentRequest.canMakePayment();
		if (!canMakePayment) {
			console.log("Google Pay is not available");
			return;
		}

		const prButton = stripe.elements().create("paymentRequestButton", {
			paymentRequest,
		});

		prButton.mount("#google-pay-button");

		// Handle successful payment
		paymentRequest.on("paymentmethod", async (event) => {
			const { error, paymentIntent } = await stripe.confirmCardPayment(
				"CLIENT_SECRET_FROM_SERVER",
				{
					payment_method: event.paymentMethod.id,
				}
			);

			if (error) {
				console.error(error);
				event.complete("fail");
			} else {
				console.log("Payment successful:", paymentIntent);
				event.complete("success");
			}
		});
	}

	if (checkout?.bookingInProgress) {
		return <PreppingDashboard />;
	}

	if (checkout?.invalid) {
		return (
			<div className="h-screen pb-[30vh] grid place-items-center">
				<InvalidPaymentLink />
			</div>
		);
	}

	return (
		<>
			{checkout?.loading ? (
				<div className="h-screen grid place-items-center">
					<Lottie
						animationData={loadingAnimation}
						style={{
							height: 150,
							width: 150,
						}}
						className="absolute hidden sm:block top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
					/>
				</div>
			) : (
				<>
					<div className="text-primary h-screen">
						<div className="sm:block relative">
							<Header />
						</div>

						<Helmet>
							<title>Lingawa | Checkout</title>
							<meta name="description" content="Online tutoring" />
							<meta name="keywords" content="Online tutoring" />
						</Helmet>

						<div className="relative z-10 pb-8 px-8">
							<div className="flex items-center sm:flex-row flex-col justify-between p-6">
								{/* <div
									className="items-center h-10 cursor-pointer"
									onClick={() => (window.location.href = "http://topset.app/")}
								>
									<img src={logo} alt="" width={171} height={135} />
								</div> */}

								{/* <div
									className="relative flex items-center h-10
					cursor-pointer my-auto lg:hidden"
									// onClick={() => (window.location.href = "http://topset.app/")}
								>
									<img src={mobile_logo} alt="" width={160} />
								</div> */}

								<div className="flex items-center  sm:hidden ">
									<p>
										Powered by{" "}
										<span className="font-bold text-xl text-black">
											{checkout?.paymentDoc?.gateway?.name}
										</span>
									</p>
								</div>
							</div>

							<section className="text-center z-10 container md:mt-12 px-8 mt-4 left-8 right-8 ">
								<h1 className="font-bold md:text-2xl text-lingawa-primary text-2xl leading-10 max-w-3xl mx-auto ">
									Complete your checkout
								</h1>

								{/* <p className="font-medium text-secondary text-lg md:text-lg leading-7 mt-4 max-w-4xl mx-auto ">
									Almost There! Secure Your Spot
								</p> */}
							</section>

							<div
								className={`flex gap-5 container  mt-10 relative z-10 sm:flex-row flex-col ${
									checkout.loading && "hidden"
								} `}
							>
								{checkout?.checkoutSummary && (
									<CheckoutSummary
										details={checkout?.checkoutSummary}
										learningPackage={
											checkout?.paymentDoc?.subscription?.learningPackage
										}
									/>
								)}
								<section className="sm:self-start flex-1">
									<CardInfo
										g_APay={g_APay}
										stripeGateway={stripeGateway}
										cardElementOptions={cardElementOptions}
										stripe={stripe}
										checkout={checkout}
										setCheckout={setCheckout}
										initiatePayment={initiatePayment}
									/>

									<RefundPolicyText />
									{checkout?.paymentDoc?.gateway?.name === "STRIPE" && (
										<div className="flex justify-center items-center mt-4">
											<div className="flex gap-4 border-2 border-[#635AFF] text-[#635AFF] py-2 rounded-xl justify-end items-center mt-4 w-[240px] px-6 pb-2 ">
												<div className="flex items-center gap-3">
													<p className="font-semibold">Powered by</p>
													<img
														src={`${process.env.REACT_APP_ASSET_CDN}/stripe-logo.webp`}
														alt=""
														className="h-8"
													/>
												</div>
											</div>
										</div>
									)}
								</section>
							</div>

							{checkout?.checkoutSummary?.subject && (
								<div className="container mt-10">
									<div
										className="border-[1px] px-4 py-6 flex justify-between border-gray-300 rounded-xl"
										style={{
											backgroundImage: `url("${process.env.REACT_APP_ASSET_CDN}/roadmap-background.png")`,
											backgroundSize: "cover",
											backgroundPosition: "left",
										}}
									>
										<div>
											<p className="text-lingawa-primary text-lg font-semibold">
												View Roadmap & Unlock Lessons
											</p>
											<p className="text-lingawa-primary text-xs">
												See your learning path and track your progress step by
												step
											</p>
										</div>
										<button
											className="py-2 px-4 bg-gray-100 hover:bg-white font-semibold text-lingawa-primary border-[1px] border-lingawa-primary border-l-4 border-b-4 rounded-xl"
											onClick={handleViewRoadmap}
										>
											View Roadmap
										</button>
									</div>
								</div>
							)}
						</div>
					</div>

					<ReactModal
						isOpen={stripeGateway.open}
						style={{
							overlay: {
								zIndex: 1000,
								backgroundColor: "rgba(0, 0, 0, 0.43)",
							},
							content: {
								border: "none",
								background: "transparent",
								height: "93vh",
								width: "96vw",
								margin: 0,
								padding: 0,
								display: "grid",
								placeItems: "center",
							},
						}}
						shouldCloseOnOverlayClick={true}
						shouldCloseOnEsc={true}
					>
						<div
							style={{
								border: "none",
								background: "white",
								height: 400,
								width: 500,
								borderRadius: "1.5rem",
								padding: 20,
							}}
						>
							<form
								onSubmit={initiateStripePayment}
								className="grid gap-3 relative"
								style={{
									height: "100%",
									gridTemplateRows: "auto auto 1fr auto",
								}}
							>
								<div
									className="h-[40px] w-[40px] absolute cursor-pointer"
									style={{
										right: 0,
										borderRadius: "50%",
										display: "grid",
										placeItems: "center",
									}}
									onClick={() => {
										setStripeGateway((p) => ({ ...p, open: false }));
										setCheckout((p: any) => ({
											...p,
											chargeInProgress: false,
										}));
									}}
								>
									<svg
										fill="none"
										strokeWidth={1.5}
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M6 18 18 6M6 6l12 12"
										/>
									</svg>

									{/* <XIcon
								onClick={() => setStripeGateway(false)}
								className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
							/> */}
								</div>

								<div className="font-bold text-[#191c1f] text-[20px]">
									Lingawa
								</div>

								<p className="text-[#191c1f] text-[14px]">
									<span>{checkout?.paymentDoc?.amount?.currency}</span>
									<span>{checkout?.paymentDoc?.amount?.value}</span>
								</p>

								{stripeGateway.paymentRequest && (
									<div className="grid align-items-center">
										<PaymentRequestButtonElement
											options={{ paymentRequest: stripeGateway.paymentRequest }}
										/>
									</div>
								)}

								<div className="grid align-items-center">
									<div className="p-[16px] pt-[20px] rounded-[1rem] bg-[#ebebf2] h-[56px] grid align-items-center">
										<CardElement options={cardElementOptions} />
									</div>
								</div>

								<button
									type="submit"
									disabled={!stripe}
									className="bg-[#4F55F1] h-[52px] text-white rounded-[9999px]"
									style={{
										boxShadow: "rgb(79 85 241 / 0.45) 0 0.1875rem 0.5rem 0",
									}}
								>
									{stripeGateway.success ? (
										<div className="grid place-items-center">
											<CheckCircleIcon className="h-10 w-10" />
										</div>
									) : stripeGateway.loading ? (
										<div className="grid place-items-center">
											<CircularProgress />
										</div>
									) : (
										<>
											<span>
												Pay{" "}
												{Intl.NumberFormat("en-US", {
													style: "currency",
													currency: checkout?.paymentDoc?.amount?.currency,
												}).format(
													parseInt?.(
														checkout?.paymentDoc?.amount?.value as string
													) as number
												)}
											</span>
										</>
									)}
								</button>
							</form>
						</div>
					</ReactModal>
				</>
			)}
		</>
	);
};

const Checkout = () => {
	const [checkout, setCheckout] = useState<CheckoutState>({
		loading: true,
		chargeInProgress: false,
		invalid: false,
		bookingInProgress: false,
		cardDetails: {},
	});
	const [stripePromise, setStripePromise] = useState<any>(null);
	const location = useLocation();
	const searchParams = useMemo(
		() => new URLSearchParams(location.search),
		[location]
	);
	const { invoiceId, subscriptionId } = useMemo(
		() => ({
			invoiceId: searchParams.get("invoiceId"),
			subscriptionId: searchParams.get("subscriptionId"),
		}),
		[searchParams]
	);

	useEffect(() => {
		let id: string | null, billingType: BillingType;

		if (invoiceId) {
			id = invoiceId;
			billingType = BillingType.INVOICE;
		} else {
			id = subscriptionId;
			billingType = BillingType.SUBSCRIPTION;
		}

		setCheckout((p: CheckoutState) => ({ ...p, id, billingType }));
	}, [invoiceId, subscriptionId]);

	useEffect(() => {
		if (checkout.id && checkout.billingType) init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkout.id, checkout.billingType]);

	async function setStripekey(key: string) {
		const stripe = await loadStripe(key);
		if (stripe) setStripePromise(stripe);
	}

	function toggleInvalid() {
		setCheckout((p: CheckoutState) => ({
			...p,
			loading: false,
			invalid: true,
		}));
	}

	const getPaymentHistory = useCallback(async () => {
		try {
			if (checkout.id) {
				let response;

				response = await PaymentApi.getPayment({
					[`${checkout.billingType?.toLowerCase()}Id`]: checkout.id,
				});

				if (!response || !response?.data) toggleInvalid();
				else return response.data;
			}
		} catch (error) {
			toggleInvalid();
			console.log("caught error: ", error);
		}
	}, [checkout.id, checkout.billingType]);

	async function init(): Promise<void> {
		await setStripekey(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);
		const history = await getPaymentHistory();

		const recentPayment = history?.[0];

		if (recentPayment?.state === "COMPLETE") {
			toggleInvalid();
		} else {
			let firstName: string,
				lastName: string,
				numberOfLessons: number,
				lessonFrequency: number,
				pkgRate: string,
				subType: string,
				subject: string,
				selectedTutor: any,
				lessonStartDate: string,
				pkgAmount: number,
				perLessonRate: string,
				region: string,
				influencerDiscountPercentage: number,
				influencerName: string,
				freeTrial: boolean;

			if (checkout.billingType === BillingType.INVOICE) {
				const _inv = recentPayment?.invoice;

				firstName = _inv?.firstname;
				lastName = _inv?.lastname;
				region = _inv?.locationInfo?.countryCode;

				if (_inv?.customPackage) {
					numberOfLessons = _inv?.customPackage?.numberOfLessons;
					pkgRate = _inv?.customPackage?.pkgRate;
					subType = _inv?.customPackage?.type;
					pkgAmount = _inv?.customPackage?.pkgAmount;
				}
			} else {
				const _sub = recentPayment?.subscription;

				region = _sub?.user?.locationInfo?.countryCode;
				firstName = _sub?.user?.firstname;
				lastName = _sub?.user?.lastname;
				numberOfLessons = _sub?.numberOfLessons;
				lessonFrequency = _sub?.lessonFrequency;
				pkgRate = _sub?.pkgRate;
				subType = _sub?.type;
				pkgAmount = _sub?.pkgAmount;
				subject = capitalizeFirstCharacter(_sub?.user?.onboarding?.subject);
				if (recentPayment?.influencerPromo) {
					influencerDiscountPercentage =
						recentPayment?.influencerPromo?.discountPercentage;
					influencerName = recentPayment?.influencerPromo?.influencerName;
				}
				selectedTutor = {
					firstName: _sub?.user?.onboarding?.selectedTutor?.firstname,
					lastName: _sub?.user?.onboarding?.selectedTutor?.lastname?.charAt(0),
				};
				perLessonRate =
					_sub?.learningPackage?.[
						`perLessonRate${recentPayment?.amount?.currency.toUpperCase()}`
					];
				lessonStartDate = moment
					.utc(_sub?.lessonSchedule[0]?.utc)
					.local()
					.format("dddd MMMM Do YYYY, hh:mm A");
				freeTrial = _sub?.freeTrial;
			}

			// if (region?.toUpperCase() === "GB" && USE_REGIONS) {
			// 	setStripekey(process.env.REACT_APP_STRIPE_UK_PUBLISHABLE_KEY as string);
			// } else {
			setStripekey(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);
			// }

			setCheckout((p: CheckoutState) => ({
				...p,
				loading: false,
				freeTrial,
				paymentDoc: {
					...recentPayment,
					amount: {
						...recentPayment?.amount,
						value: recentPayment?.promo?.amount ?? recentPayment?.amount?.value,
					},
				},
				region,
				checkoutSummary: {
					amount: recentPayment?.promo?.amount ?? recentPayment?.amount?.value,
					currency: recentPayment?.amount?.currency,
					firstName,
					lastName,
					promo: recentPayment?.[`${p.billingType?.toLowerCase()}`]?.promo,
					subjects:
						recentPayment?.[`${p.billingType?.toLowerCase()}`]?.subjects,
					tutors: recentPayment?.[`${p.billingType?.toLowerCase()}`]?.tutors,
					pkgRate,
					subType,
					pkgAmount,
					selectedTutor,
					numberOfLessons,
					type: subType,
					subject,
					lessonFrequency,
					perLessonRate,
					lessonStartDate,
					...(influencerDiscountPercentage && {
						influencerDiscountPercentage,
						influencerName,
					}),
				},
			}));
		}
	}

	if (stripePromise == null) {
		return <div />;
	} else
		return (
			<Elements key={2} stripe={stripePromise}>
				<SubCheckout checkout={checkout} setCheckout={setCheckout} />
			</Elements>
		);
};

export default Checkout;
