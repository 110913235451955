import React, { ReactNode } from "react";

interface ModalProps {
  onClose?: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ onClose, children }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
      <div className="relative bg-gradient-to-br from-primary to-gray-800 rounded-lg shadow-xl p-6 w-full max-w-md mx-4">
        {children}
        {onClose && (
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 text-sm font-medium px-3 py-1.5 rounded"
          >
            Close
          </button>
        )}
      </div>
    </div>
  );
};

export default Modal;
