import React from "react";
import clsx from "clsx";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { routes } from "src/Routes";
import { useStateValue } from "src/context/StateProvider";

type ParentMessagesProps = {
  resource: any;
  curriculum: any;
};

export default function DependantResources({
  resource,
  curriculum,
}: ParentMessagesProps) {
  const location = useLocation();
  const [{ user, showMobileMenu }, dispatch] = useStateValue();

  const closeMobileMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const dashboardUrl = `${routes.TUTOR_DASHBOARD_LEARN.url}`;
  const isContained =
    location.pathname.includes(dashboardUrl) &&
    location.state?.curriculum === curriculum;

  return (
    <div>
      {" "}
      <NavLink
        to={`${routes.TUTOR_DASHBOARD_LEARN.url}/${user._id}`}
        state={{ curriculum }}
      >
        <p
          className={clsx("submenu-inactive", {
            "submenu-active": isContained,
          })}
          onClick={closeMobileMenu}
        >
          {curriculum}
        </p>
      </NavLink>
    </div>
  );
}
