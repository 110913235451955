import { differenceInDays, format, formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import assets from "src/assets/assets";
import { routes } from "src/Routes";
import BaseCard, { BaseCardText } from "./BaseCard";

const UpcomingLessonCard = ({ lessons, className }: any) => {
	const navigate = useNavigate();

	const formatDate = (date: string) => {
		return date
			? format(new Date(lessons[0].lessonDate), "EEE d MMM h:mmaaa OOOO")
			: "";
	};

	function timeUntil(date: string): string {
		try {
			const now = new Date();
			const targetDate = new Date(date);
			const daysDifference = differenceInDays(targetDate, now);

			if (daysDifference > 7) {
				const weeks = Math.ceil(daysDifference / 7);
				return `In ${weeks} week${weeks > 1 ? "s" : ""}`;
			}

			if (targetDate) {
				return formatDistanceToNow(targetDate, { addSuffix: true });
			} else {
				return "";
			}
		} catch (e) {
			return "";
		}
	}

	return (
		<div className={className}>
			<div className="flex justify-between pb-3">
				<BaseCardText
					t={`Your Next Live Lesson`}
					md
					bold
					className="text-[#6B0D4A]"
				/>

				<div
					style={{
						fontSize: 10,
						fontWeight: "bold",
						borderRadius: 10,
					}}
					className="bg-red-100 p-[5px] px-[10px]"
				>
					{timeUntil(lessons?.[0]?.lessonDate).replace("about", "")}!
				</div>
			</div>

			<div
				onClick={() => navigate(routes.STUDENT_DASHBOARD_UPCOMING_LESSONS.url)}
				className="cursor-pointer"
			>
				<BaseCard padding={15} className="relative">
					<div className="h-[20%] relative flex justify-center">
						<div className="w-[20%]" />

						<div>
							<BaseCardText
								t={`${lessons?.[0]?.lessonSubject} lesson with ${lessons?.[0]?.tutorName} 😁`}
								md
								bold
								className="text-[#6B0D4A]"
							/>

							<BaseCardText
								t={`${formatDate(lessons?.[0]?.lessonDate)}`}
								sm
								className="text-[#6B0D4A]"
							/>
						</div>
					</div>

					<img
						className="absolute bottom-0 left-0 w-[20%] rounded-bl-[15px] max-w-[100px] object-cover"
						src={assets.dBook}
						alt="corner-book"
					/>
				</BaseCard>
			</div>
		</div>
	);
};

export default UpcomingLessonCard;
