// WrittenAnswerSection.tsx
import React, { useState } from "react";
import { Prompt } from "./types";
import WrittenAnswerPrompt from "../TestYourKnowledge/WrittenAnswerPrompt";

interface WrittenAnswerSectionProps {
  prompts: Prompt[]; // each prompt has type="written-answer"
  onFinish: () => void;
  handleRequestRemoveWord: () => void;
  /**
   * Called every time a prompt is completed (whether correct or not).
   * For example, to update progress in the parent.
   */
  onShowFeedback: (isCorrect: boolean, prompt: Prompt) => void;
  setCurrentWordId?: React.Dispatch<React.SetStateAction<string | null>>;
}

const WrittenAnswerSection: React.FC<WrittenAnswerSectionProps> = ({
  prompts,
  onFinish,
  handleRequestRemoveWord,
  onShowFeedback,
  setCurrentWordId,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [failedQueue, setFailedQueue] = useState<Prompt[]>([]);
  const [beastMode, setBeastMode] = useState<boolean>(false);

  /**
   * Called when a prompt is completed.
   * didKnow=true indicates that the user passed the prompt.
   * didKnow=false indicates a failed answer.
   */
  const handleNext = (didKnow: boolean) => {
    const currentPrompt = prompts[currentIndex];

    // If the user did not pass, add the prompt to the failedQueue.
    if (!didKnow) {
      setTimeout(() => {
        setFailedQueue((prev) => [...prev, currentPrompt]);
      }, 2000);
    }

    // Trigger parent's feedback function.
    onShowFeedback(didKnow, currentPrompt);

    // Move to the next prompt after a brief delay.
    setTimeout(() => {
      setCurrentIndex((prev) => prev + 1);
    }, 2000);
  };

  const onBeastModeToggle = () => {
    setBeastMode((prevMode) => !prevMode);
  };

  // When main prompts are exhausted:
  if (currentIndex >= prompts.length) {
    // If there are any failed prompts, pass them on for indefinite re-queueing.
    if (failedQueue.length > 0) {
      return (
        <ReQueueWrittenAnswers
          queue={failedQueue}
          onDone={onFinish}
          handleRequestRemoveWord={handleRequestRemoveWord}
          onShowFeedback={onShowFeedback}
          setCurrentWordId={setCurrentWordId}
        />
      );
    } else {
      // All prompts have been passed.
      onFinish();
      return null;
    }
  }

  const currentPrompt = prompts[currentIndex];
  setCurrentWordId && setCurrentWordId(currentPrompt.word._id);

  return (
    <div>
      <WrittenAnswerPrompt
        prompt={currentPrompt}
        beastMode={beastMode}
        onBeastModeToggle={onBeastModeToggle}
        handleRequestRemoveWord={handleRequestRemoveWord}
        onNext={handleNext}
      />
    </div>
  );
};

/**
 * ReQueueWrittenAnswers component
 * This component handles the indefinite re-queue for any prompts that were failed.
 * It will keep cycling prompts until the user passes them all.
 */
interface ReQueueProps {
  queue: Prompt[];
  onDone: () => void;
  handleRequestRemoveWord: () => void;
  onShowFeedback: (isCorrect: boolean, prompt: Prompt) => void;
  setCurrentWordId?: React.Dispatch<React.SetStateAction<string | null>>;
}

const ReQueueWrittenAnswers: React.FC<ReQueueProps> = ({
  queue: initialQueue,
  onDone,
  handleRequestRemoveWord,
  onShowFeedback,
  setCurrentWordId,
}) => {
  const [queue, setQueue] = useState<Prompt[]>(initialQueue);
  const [index, setIndex] = useState<number>(0);
  const [beastMode, setBeastMode] = useState<boolean>(false);

  /**
   * Called by each prompt in the re-queue phase.
   * If the user passes, the prompt is removed from the queue.
   * If the user fails, that prompt is moved to the end of the queue.
   * We then reset the index to 0 to ensure the prompt is re-rendered immediately.
   */
  const handleNext = (didKnow: boolean) => {
    const currentPrompt = queue[index];

    // Show feedback for current prompt.
    onShowFeedback(didKnow, currentPrompt);

    if (didKnow) {
      // Remove the prompt since it was passed.
      setTimeout(() => {
        setQueue((prev) => {
          const newQueue = [...prev];
          newQueue.splice(index, 1);
          return newQueue;
        });
      }, 2000);
    } else {
      // Move the failed prompt to the end.
      setTimeout(() => {
        setQueue((prev) => {
          const newQueue = [...prev];
          const item = newQueue[index];
          newQueue.splice(index, 1);
          newQueue.push(item);
          return newQueue;
        });
      }, 2000);
    }
    // Force index to reset to 0 to always render the first item in the updated queue.
    setIndex(0);
  };

  // If the queue is empty, all failed prompts have been passed.
  if (queue.length === 0) {
    onDone();
    return null;
  }

  // If for some reason index is out-of-bounds, reset it to 0.
  if (index >= queue.length) {
    setIndex(0);
    return null;
  }

  // Callback to toggle beast mode
  const onBeastModeToggle = () => {
    setBeastMode((prevMode) => !prevMode);
  };

  const currentPrompt = queue[index];
  setCurrentWordId && setCurrentWordId(currentPrompt.word._id);

  return (
    <div>
      <WrittenAnswerPrompt
        prompt={currentPrompt}
        beastMode={beastMode}
        onBeastModeToggle={onBeastModeToggle}
        onNext={handleNext}
        handleRequestRemoveWord={handleRequestRemoveWord}
      />
    </div>
  );
};

export default WrittenAnswerSection;
