import axios from "axios";
import {
  IEditableStudentProfile,
  IStudentSettings,
} from "src/interfaces/student";

export async function submitBookingForm(
  studentId: any,
  tutorId: any,
  selectedSubjectOption: any,
  selectedLessonLevels: any,
  whoLessonIsFor: any,
  tuitionRegularity: any,
  weeklyFrequency: any,
  lessonSchedule: any,
  introductionText: any,
  lessonPrice: any,
  tutorName: any,
  tutorAvatar: string,
  studentAvatar: string,
  bookingPrice: any,
  studentName: any,
  localPrice: string
): Promise<any | undefined[]> {
  try {
    const data = {
      studentId: studentId,
      tutorId: tutorId,
      lessonName: selectedSubjectOption,
      lessonLevel: selectedLessonLevels,
      whoLessonIsFor: whoLessonIsFor,
      tuitionRegularity: tuitionRegularity,
      weeklyFrequency: weeklyFrequency,
      lessonSchedule: lessonSchedule,
      introductionText: introductionText,
      lessonPrice: lessonPrice,
      tutorName: tutorName,
      tutorAvatar: tutorAvatar,
      studentAvatar: studentAvatar,
      bookingPrice: bookingPrice,
      studentName: studentName,
      localPrice: localPrice,
    };

    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/lesson-booking-request`,
      data
    );

    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getStudentDashboard(
  studentId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/user/dashboard/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getLessonRequests(
  studentId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-lesson-requests/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getLessonRequestsViaAdmin(
  studentId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-lesson-requests-via-admin/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getConfirmedLessons(
  studentId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-confirmed-lessons/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getConfirmedLessonsViaAdmin(
  studentId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-booked-lessons-via-admin/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export function getConfirmedLessonsViaAdminSSE(
  studentId: string | undefined
): EventSource {
  const eventSource = new EventSource(
    `${process.env.REACT_APP_SERVER_URL}/student-confirmed-lessons-via-admin-sse/${studentId}`
  );
  return eventSource;
}

export async function getStudentReportByDateRange(
  studnetId: string,
  startDate: Date,
  endDate: Date
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-report?studentId=${studnetId}&startDate=${startDate}&endDate=${endDate}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getStudentFeedbackByDateRange(
  studnetId: string,
  startDate: Date,
  endDate: Date
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${
        process.env.REACT_APP_SERVER_URL
      }/student-report?studentId=${studnetId}&startDate=${startDate}&endDate=${endDate}&feedback=${true}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getPastConfirmedLessonsViaAdmin(
  studentId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-past-confirmed-lessons-via-admin/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getStudentAccountSettings(
  studentId: string | undefined
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-settings/${studentId}`
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postStudentAccountSettings(
  studentId: string | undefined,
  settings: IStudentSettings
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/student-settings/${studentId}`,
      settings
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getStudentProfileDetails(
  studentId: string | undefined
): Promise<any | undefined> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-profile-details/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postStudentProfileDetails(
  studentId: string | undefined,
  studentDetails: IEditableStudentProfile
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/student-profile-details`,
      studentDetails
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function postStudentAvatar(
  studentId: string | undefined,
  imgData: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/student-info/upload-photo`,
      imgData
    );
    return response;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function createGrade(body: {
  value: string;
  studentId: string;
  curriculumId: string | undefined;
}): Promise<any | undefined[]> {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/student-grades`,
      body
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function updateGrade(body: {
  term: string;
  value: string;
  curriculumId: string;
  studentId: string;
}): Promise<any | undefined[]> {
  try {
    let response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/student-grades`,
      body
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getGrades(
  curriculumId: string,
  studentId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-grades/${studentId}/${curriculumId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getCurriculums(studentId: string) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-get-curriculums/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getLessonRewards(studentId: string) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/user/rewards/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getLeaderboard() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/gamification/leaderboard`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getCowryPouch(studentId: string) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/user/${studentId}/cowry-pouch`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function postMarKLessonRewardsAsRead(studentId: string) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_V2_SERVER_URL}/user/read-rewards/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function getStudentRefferalStatistics(
  referralCode?: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/student-referrer-statistics/${referralCode}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getLessonCount(
  studentId: string,
  subscriptionId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/upcoming-lessons-count/${studentId}/${subscriptionId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getMostRecentLesson(
  studentId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/most-recent-lesson/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}
export async function getLessonProgress(
  studentId: string
): Promise<any | undefined[]> {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_V2_SERVER_URL}/lessons/lesson-resources`,
      {
        params: {
          studentId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
  }
}

export async function updateTopicLastPracticed(
  studentId: string,
  body: any
): Promise<any | undefined[]> {
  try {
    let response = await axios.patch(
      `${process.env.REACT_APP_V2_SERVER_URL}/lessons/lesson-progress/last-practiced/${studentId}`,
      body
    );
    return response.data;
  } catch (error) {
    console.log("caught error: ", error);
    throw error;
  }
}
