import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { StudentApi } from "src/api/student.api";
import { TeacherRefferalApi } from "src/api/teacher-referral.api";
import assets from "src/assets/assets";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";
import { copyToClipboard } from "src/utils/helper/core";
import BaseCard, { BaseCardButton, BaseCardText } from "./BaseCard";

toast.configure();

const GrowTogetherCard = () => {
	const navigate = useNavigate();
	const [teacherProfile, setTeacherProfile] = useState<any>();
	const [{ user }] = useStateValue();

	useEffect(() => {
		const getStudentProfile = async () => {
			let response;
			if (user.type === "teacher_referrer") {
				response = await TeacherRefferalApi.getTeacherRefferalStatistics(
					user._id
				);
			} else {
				response = await StudentApi.getStudentRefferalStatistics(
					user.referralCode
				);
			}
			setTeacherProfile(
				response?.referrerInfo || {
					referralCode: user.referralCode,
				}
			);
		};
		getStudentProfile();
	}, [user._id]);

	return (
		<BaseCard padding={20} className="relative flex flex-col gap-2">
			<BaseCardText
				t="Give a friend a free lesson and get one too!"
				className="text-[#6B0D4A] text-[14px] w-[75%]"
				bold
			/>

			<BaseCardText
				t="Using your referral code"
				className="text-[#6B0D4A] mb-2"
				style={{ fontSize: 15, fontWeight: 600 }}
			/>

			<div className="flex gap-5">
				<div
					onClick={() =>
						copyToClipboard(
							`https://learn.lingawa.com/${routes.CHOOSE_A_LANGUAGE.url}?refCode=${teacherProfile?.referralCode}`,
							toast
						)
					}
					className="cursor-pointer rounded-lg bg-[#46BFE8] text-white flex gap-3 w-[100%] flex justify-center items-center"
				>
					<div>{teacherProfile?.referralCode}</div>

					<div className="rounded-full bg-white hover:bg-gray-200 h-10 w-10 grid place-items-center">
						<img src={assets.copy} className="h-[25px] w-[25px] rounded-lg" />
					</div>
				</div>

				<BaseCardButton
					title={"Learn More"}
					onClick={() => navigate(routes.STUDENT_REFERRAL.url)}
					className="w-[100%]"
				/>
			</div>

			<img
				src={assets.refEdge}
				className="h-[80px] w-[80px] absolute"
				style={{
					top: 20,
					right: 0,
				}}
			/>
		</BaseCard>
	);
};

export default GrowTogetherCard;
