import React, { useState, useEffect } from "react";
import { Prompt } from "./types";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import Tippy from "@tippyjs/react";
import { MdClose } from "react-icons/md";
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { LuBookmark, LuForward } from "react-icons/lu";

interface FlashcardPromptProps {
  prompt: Prompt;
  onNext: (didKnow: boolean) => void;
  setCurrentWordProficiency?: any;
  handleRequestRemoveWord?: any;
}

const FlashcardPrompt: React.FC<FlashcardPromptProps> = ({
  prompt,
  onNext,
  setCurrentWordProficiency,
  handleRequestRemoveWord,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [currentAnimation, setCurrentAnimation] = useState("");
  const [justLoaded, setJustLoaded] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);

  // NEW: Show/hide the “click to flip” hint
  const [showHint, setShowHint] = useState(true);

  useEffect(() => {
    setIsFlipped(false);
    setCurrentAnimation("");
    setHasSelected(false);

    setJustLoaded(true);
    const timer = setTimeout(() => {
      setJustLoaded(false);
    }, 700);
    return () => clearTimeout(timer);
  }, [prompt]);

  console.log("showHint", showHint);

  const flipCard = () => {
    setShowHint(false);
    setIsFlipped(!isFlipped);
  };

  // Auto-play audio when flipping to the back
  useEffect(() => {
    if (isFlipped && prompt.word.audioUrl) {
      const audio = new Audio(prompt.word.audioUrl);
      audio.play().catch((err) => console.error("Audio playback failed", err));
    }
  }, [isFlipped]);

  // If user clicks speaker icon, play audio without flipping
  const playAudio = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (prompt.word.audioUrl) {
      const audio = new Audio(prompt.word.audioUrl);
      audio.play().catch((err) => console.error("Audio playback failed", err));
    }
  };

  const getBorderColor = () => {
    if (currentAnimation === "animate-bounce-once") {
      return "border-4 border-green-400 rounded-2xl";
    }
    if (currentAnimation === "animate-shake") {
      return "border-4 border-red-400 rounded-2xl";
    }
    return "";
  };

  const handleNext = (didKnow: boolean) => {
    setHasSelected(true);

    setCurrentAnimation(didKnow ? "animate-bounce-once" : "animate-shake");

    // Play success audio if correct
    if (didKnow) {
      const successAudio = new Audio(
        `${process.env.REACT_APP_ASSET_CDN}/success.wav`
      );
      successAudio.play().catch(() => {});
    }

    // If correct => increment proficiency in parent's map
    if (didKnow && setCurrentWordProficiency) {
      setCurrentWordProficiency((prevMap: { [key: string]: number }) => ({
        ...prevMap,
        [prompt.word?._id]: (prevMap[prompt.word?._id] ?? 0) + 1,
      }));
    }

    setTimeout(() => {
      setCurrentAnimation("");
      onNext(didKnow);
      setHasSelected(false);
    }, 1500);
  };

  const { word } = prompt;

  return (
    <div className="flex flex-col items-center md:justify-center">
      {/* Card container with relative positioning */}
      <div
        className={`
          card-container relative my-4
          ${currentAnimation}
          ${getBorderColor()}
        `}
        onClick={flipCard}
      >
        <div className={`card ${isFlipped ? "card-flipped" : ""}`}>
          {/* FRONT: English + optional image */}
          <div
            className="card-front p-6 relative"
            style={{
              backgroundImage: `url("${process.env.REACT_APP_ASSET_CDN}/flip-card-BG.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {word.imageUrl ? (
              <div className="grid grid-cols-1 h-full grid-rows-[1fr_1fr]">
                <div className="order-1 flex items-center rounded-2xl justify-center overflow-hidden">
                  <img
                    src={word.imageUrl}
                    alt={word.word}
                    className="object-contain rounded-xl"
                  />
                </div>
                <div className="order-2 flex items-center justify-center pr-2">
                  <p className="text-lingawa-primary font-semibold text-xl text-center">
                    {word.englishTranslation}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-lingawa-primary font-semibold text-xl">
                  {word.englishTranslation}
                </p>
              </div>
            )}
            {/* "click to flip" text is part of the front and will flip with it */}
            {!isFlipped && showHint && (
              <span className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-xs text-gray-400">
                Click to flip
              </span>
            )}
          </div>

          {/* BACK: Native word + optional audio */}
          <div
            className="card-back bg-primary"
            style={{
              backgroundImage: `url("${process.env.REACT_APP_ASSET_CDN}/flip-card-BG2.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {word.audioUrl && (
              <HiMiniSpeakerWave
                className="text-white cursor-pointer absolute top-6 right-6 transition-transform transform hover:scale-110 hover:text-lingawa-teal"
                onClick={playAudio}
                size={25}
              />
            )}
            <p
              className={`text-white font-semibold text-xl ${
                justLoaded ? "animate-fade-in" : ""
              }`}
            >
              {word.word}
            </p>
          </div>
        </div>
      </div>

      {/* Buttons container:
          - On mobile: rendered in normal flow.
          - On large screens: fixed to bottom with offset from the side nav */}
      <div className="w-full lg:fixed lg:bottom-0 lg:left-64 mt-8 md:mt-0 lg:w-[calc(100%-16rem)] lg:bg-white lg:p-6 lg:border-t">
        <div className="flex justify-between">
          {handleRequestRemoveWord && (
            <button
              onClick={handleRequestRemoveWord}
              className="py-2 px-4 rounded-xl flex justify-between gap-4 font-semibold border-[1px] border-b-4 hover:border-green-500 border-l-4 transition-transform transform"
            >
              <p className="hidden md:block">Already know this</p>
              <p>
                <LuForward size={22} />
              </p>
            </button>
          )}

          <div className="space-x-1 md:space-x-2 flex">
            <button
              onClick={() => handleNext(false)}
              disabled={hasSelected}
              className={`bg-[#EBEFFF] border-[1px] border-l-4 border-b-4 border-lingawa-primary md:px-4 px-2 py-2 rounded-xl hover:border-b-[1px] hover:border-l-[1px] hover:border-t-2 hover:border-r-2
              ${hasSelected ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              <div className="flex items-center justify-center gap-2">
                <span className="text-lingawa-primary font-semibold text-sm">
                  Still Learning
                </span>
                <LuBookmark
                  className="text-lingawa-primary hidden md:block"
                  size={18}
                />
              </div>
            </button>

            <button
              onClick={() => handleNext(true)}
              disabled={hasSelected}
              className={`bg-orange-600 border-[1px] border-l-4 border-b-4 border-lingawa-primary px-4 py-2 rounded-xl  
              ${hasSelected ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              <div className="flex items-center justify-center gap-2">
                <span className="text-white font-semibold text-sm">
                  Learned
                </span>
                <FaRegCheckCircle className="text-white hidden md:block" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlashcardPrompt;
