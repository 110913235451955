import React, { useState, useEffect } from "react";

interface TimerProps {
  startTime: number;
  running: boolean;
  mode: "normal" | "beast";
}

const Timer: React.FC<TimerProps> = ({ startTime, running, mode }) => {
  const [time, setTime] = useState<number>(0);

  let timerIcon = "🦉 Normal Mode";
  if (mode === "beast") {
    timerIcon = "🦁 Beast Mode";
  } else if (timerIcon === "normal") {
    timerIcon = "🦉 Normal Mode";
  }

  useEffect(() => {
    if (!running) return;
    const interval = setInterval(() => {
      setTime((Date.now() - startTime) / 1000);
    }, 100);
    return () => clearInterval(interval);
  }, [running, startTime]);

  return (
    <div className="text-lg flex justify-between font-semibold my-2 px-2">
      <div className="text-white font-semibold">{timerIcon}</div>
      <div>{time.toFixed(1)}</div>
    </div>
  );
};

export default Timer;
