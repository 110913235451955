import { Link } from "react-router-dom";
import { routes } from "src/Routes";

const logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-logo-light.png`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-logo-light.png`;
const bannerBackground = `${process.env.REACT_APP_ASSET_CDN}/blackfriday-bannr.png`;

function FixedHeaderWithoutNav() {
  return (
    <div className="top-0 z-50  sticky">
      {/* <div
        className="flex py-2 justify-around"
        style={{
          backgroundImage: `url(${bannerBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <p className="text-white text-center">
          <span className="block sm:inline">
            {" "}
            It's <span className="font-semibold text-lg mr-1 ">Christmas</span>
            season!!!
          </span>

          <span className="font-semibold text-lg ml-1 block sm:inline">
            Enjoy 30% OFF!
          </span>
        </p>
      </div> */}
      <header
        className="
      bg-lingawa-primary py-5"
      >
        <nav className="container bg-lingawa-primary flex justify-between ">
          {/* left */}
          <div
            className="relative lg:flex items-center h-10 
            cursor-pointer my-auto hidden"
            onClick={() => (window.location.href = "http://lingawa.com/")}
          >
            <img src={logo} alt="" width={171} height={135} />
          </div>
          <div
            className="relative flex items-center h-10 
            cursor-pointer my-auto lg:hidden"
            onClick={() => (window.location.href = "http://lingawa.com/")}
          >
            <img src={mobile_logo} alt="" width={160} />
          </div>
          {/* Right */}
          <Link
            to={routes.LOGIN.url}
            className="text-white hover:text-white transition-all duration-300 hover:bg-lingawa-orange rounded-2xl hover:shadow-xl font-bold px-6 py-3 "
          >
            LOGIN
          </Link>
        </nav>
      </header>
    </div>
  );
}

export default FixedHeaderWithoutNav;
