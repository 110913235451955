import { CheckCircleIcon } from "@heroicons/react/solid";
import { CardElement } from "@stripe/react-stripe-js";
import { useRef } from "react";
import CircularProgress from "src/components/Shared/CircularProgress";
import { PaymentGateways } from "src/interfaces/payment";
interface ICardInfo {
	initiatePayment: any;
	stripeGateway: any;
	cardElementOptions: any;
	stripe: any;
	checkout: any;
	setCheckout: any;
	g_APay: boolean;
}
export default function CardInfo({
	initiatePayment,
	stripeGateway,
	cardElementOptions,
	stripe,
	checkout,
	setCheckout,
	g_APay,
}: ICardInfo) {
	const bbb = useRef<any>(null);

	return (
		<div className="rounded-xl border-[2px] font-semibold p-4 cursor-pointer sm:self-start flex-1">
			{g_APay && <p className="mb-4">Choose a payment method</p>}

			{checkout?.paymentDoc?.gateway?.name !== PaymentGateways.STRIPE ? (
				<div className="flex items-center">
					<div
						className="bg-pale-pink-bg rounded-full px-6 py-2 text-secondary "
						onClick={() => {
							setCheckout((p: any) => ({ ...p, chargeInProgress: true }));
							initiatePayment();
						}}
						data-test="d2pRevolutCardButton"
					>
						{checkout?.chargeInProgress ? (
							<CircularProgress />
						) : (
							<span>Card</span>
						)}
					</div>

					<div id="revolut-payment-request"></div>
				</div>
			) : (
				<div className="flex items-center">
					<div
						style={{
							border: "none",
							background: "white",
							width: 500,
							borderRadius: "1.5rem",
							padding: 20,
						}}
					>
						<form
							onSubmit={(e) => initiatePayment(e)}
							className="grid gap-3 relative"
							style={{
								height: "100%",
								gridTemplateRows: "auto auto 1fr auto",
							}}
						>
							<div className="font-bold text-orange-600 text-[20px]">
								Lingawa
							</div>

							<p className="text-lingawa-primary text-[14px]">
								{Intl.NumberFormat("en-US", {
									style: "currency",
									currency: checkout?.paymentDoc?.amount?.currency,
									minimumFractionDigits: 0,
									maximumFractionDigits: 2,
								}).format(checkout?.paymentDoc?.amount?.value)}
							</p>

							<div className="grid align-items-center">
								{checkout?.paymentDoc?.gateway?.name ===
								PaymentGateways.STRIPE ? (
									<div className="p-[16px] pt-[20px] rounded-[1rem] bg-[#ebebf2] h-[56px] grid align-items-center">
										<CardElement options={cardElementOptions} />
									</div>
								) : (
									<div className="p-[16px] rounded-[1rem] bg-[#ebebf2] h-[56px] grid align-items-center w-[460px]">
										<div ref={bbb} id="card-field"></div>
									</div>
								)}
							</div>

							<button
								type="submit"
								disabled={!stripe}
								className="bg-orange-500 hover:bg-orange-600 rounded-xl w-[200px] border-[1px] border-b-4 border-l-4 border-lingawa-primary h-[52px] text-white"
							>
								{stripeGateway.success ? (
									<div className="grid place-items-center">
										<CheckCircleIcon className="h-10 w-10" />
									</div>
								) : stripeGateway.loading ? (
									<div className="grid place-items-center">
										<CircularProgress />
									</div>
								) : (
									<>
										<span>
											Pay{" "}
											{Intl.NumberFormat("en-US", {
												style: "currency",
												currency: checkout?.paymentDoc?.amount?.currency,
												minimumFractionDigits: 0,
												maximumFractionDigits: 2,
											}).format(
												parseFloat?.(
													checkout?.paymentDoc?.amount?.value as string
												) as number
											)}
										</span>
									</>
								)}
							</button>
						</form>
					</div>
				</div>
			)}
		</div>
	);
}
