// StreakPopup.tsx
import React, { useEffect } from "react";

interface StreakPopupProps {
  streakCount: number;
  onClose: () => void; // called after 2s or so
}

const StreakPopup: React.FC<StreakPopupProps> = ({ streakCount, onClose }) => {
  useEffect(() => {
    const playSound = (url: string) => {
      const audio = new Audio(url);
      audio.play();
    };
    playSound(`${process.env.REACT_APP_ASSET_CDN}/streak.wav`);
    const timer = setTimeout(() => {
      onClose();
    }, 2000); // hide after 2 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="fixed top-32 left-0 right-0 flex justify-center z-50">
      <div className="bg-yellow-500 text-white px-6 py-3 rounded shadow-lg animate-bounce">
        <span className="mr-2 text-2xl">🔥</span>
        <span className="text-xl font-bold">{streakCount} in a row!</span>
      </div>
    </div>
  );
};

export default StreakPopup;
