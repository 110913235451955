// ProficiencyBadge.tsx
import React, { useEffect, useState } from "react";
import { getEmojiForStatus } from "src/utils/getEmojiForStatus";

interface ProficiencyBadgeProps {
  wordId?: string | null;
  proficiencyMap?: Record<string, number>;
  setCurrentWordProficiency?: React.Dispatch<React.SetStateAction<number>>;
  setHasStarted?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProficiencyBadge: React.FC<ProficiencyBadgeProps> = ({
  wordId,
  proficiencyMap,
  setCurrentWordProficiency,
  setHasStarted,
}) => {
  // We'll track if we should apply the fade-in class
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    if (setHasStarted) {
      setHasStarted(true);
    }
    if (setCurrentWordProficiency && proficiencyMap && wordId) {
      setCurrentWordProficiency(proficiencyMap[wordId] || 0);
    }
  }, [wordId, proficiencyMap, setCurrentWordProficiency, setHasStarted]);

  // Each time the relevant data changes, quickly toggle off -> on to re-run fade
  useEffect(() => {
    // Turn animation off
    setShouldAnimate(false);

    // Turn animation on in the next tick
    const timer = setTimeout(() => {
      setShouldAnimate(true);
    }, 10);

    return () => clearTimeout(timer);
    // @ts-ignore
  }, [wordId]);

  if (!wordId || !proficiencyMap) return null;

  // ^ you can also watch the entire "proficiencyMap" if you like

  const imageSrc = getEmojiForStatus(proficiencyMap[wordId]);
  const progressPercent = (proficiencyMap[wordId] / 4) * 100;
  const radius = 28;
  const circumference = 2 * Math.PI * radius;
  const dashoffset = circumference - (progressPercent / 100) * circumference;

  return (
    <div
      className={`relative inline-block ${
        shouldAnimate ? "animate-fade-in" : ""
      }`}
    >
      <svg
        className="absolute top-7 left-7 transform -translate-x-1/2 -translate-y-1/2"
        width="70"
        height="70"
      >
        <circle
          className="text-gray-300"
          stroke="currentColor"
          strokeWidth="4"
          fill="transparent"
          r={radius}
          cx="35"
          cy="35"
        />
        <circle
          className="text-orange-600"
          stroke="currentColor"
          strokeWidth="4"
          fill="transparent"
          r={radius}
          cx="35"
          cy="35"
          strokeDasharray={circumference}
          strokeDashoffset={dashoffset}
          strokeLinecap="round"
          transform="rotate(-90 35 35)"
        />
      </svg>
      <img
        src={imageSrc}
        alt="Badge"
        className="w-14 h-14 object-contain rounded-full"
      />
    </div>
  );
};

export default ProficiencyBadge;
