import { format } from "date-fns";
import moment from "moment-timezone";

//function to keep date even with daylight savings
export const incrementDateWithSameLocalTime = (
  startDate: string | Date,
  numberOfWeeks: number,
  timezone: string
) => {
  let initialDate = moment.tz(startDate, timezone);
  let initialHour = initialDate.hour();

  // Create a new date by adding weeks to the initial date
  let newDate = moment.tz(startDate, timezone).add(numberOfWeeks, "weeks");

  // Set the hour of the new date to match the initial hour, preserving local time
  newDate.hour(initialHour);

  return new Date(newDate.format());
};

export const formatDateToNeutral = (dateString: any, timeString: any) => {
  const date = new Date(dateString);
  const time = timeString ? new Date(timeString) : null;

  const formattedDate = format(date, "eeee, dd MMMM");
  const formattedTime = time ? format(time, "hh:mm a") : "";

  return `${formattedDate} at ${formattedTime}`;
};

export const updateLessonSchedulesForDpMoreLessons: any = (
  lessonSchedules: any[],
  mostRecentLessonSchedule: any
) => {
  return lessonSchedules?.map((schedule) => {
    const lastLesson = new Date(mostRecentLessonSchedule?.lessonDate);
    console.log(lastLesson, 'lastlesson')
    // const currentSchedule = new Date(schedule?.date);
    const today = new Date();
    // Adjust the last lesson to the same week as today
    const nextLesson = new Date(today);
    nextLesson.setDate(
      today.getDate() + (lastLesson.getDay() - today.getDay())
    );

    // If the nextLesson is earlier than today, schedule it for next week
    if (nextLesson < today) {
      nextLesson.setDate(nextLesson.getDate() + 7);
    }

    // Preserve the time from the last lesson
    nextLesson.setHours(
      lastLesson.getHours(),
      lastLesson.getMinutes(),
      lastLesson.getSeconds(),
      lastLesson.getMilliseconds()
    );

    return {
      ...schedule,
      date: nextLesson,
      time: nextLesson,
      utc: nextLesson,
    };
  });
};
