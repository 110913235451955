import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useStateValue } from "src/context/StateProvider";
import Logout from "src/views/Authentication/Logout";
import StudentMenu from "../StudentDashboard/SideNav/Menu";
import TutorMenu from "../TutorDashboard/SideNav/Menu";
import MobileContact from "./MobileContact";
import ContactUsModal from "src/components/Shared/ContactUsModal";
import { useState } from "react";

export default function MobileNav({ toggleMenu }: any) {
  const [{ user }] = useStateValue();
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <div className="mt-6">
      <PerfectScrollbar className="px-6">
        {user?.type === "tutor" && (
          <div className="pb-12">
            <TutorMenu />
          </div>
        )}
        {user?.type === "student" && (
          <div className="pb-12">
            <StudentMenu />
          </div>
        )}
        <ContactUsModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
        <div className="space-y-4">
          <MobileContact
            onClick={() => {
              setIsOpen(true);
              toggleMenu();
            }}
          />
          <Logout />
        </div>
      </PerfectScrollbar>
    </div>
  );
}
