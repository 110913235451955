const assets = {
	dClock: require("./homeAssets/clock.png"),
	dRedo: require("./homeAssets/redo.png"),
	dRef: require("./homeAssets/Refferal background.png"),
	dCowry: require("./homeAssets/cowry2.png"),
	dCorner: require("./homeAssets/image.png"),
	dCardC: require("./homeAssets/mid_card.png"),
	dBook: require("./homeAssets/book.png"),
	dLeaderboardGem: require("./homeAssets/slice2.png"),
	dArm: require("./homeAssets/arm.jpeg"),
	dDog: require("./homeAssets/dog.jpeg"),
	dMoney: require("./homeAssets/money.png"),
	refEdge: require("./homeAssets/refEdge.png"),

	sHome: require("./homeAssets/s_home.png"),
	sLearn: require("./homeAssets/s_learn.png"),
	sRewards: require("./homeAssets/s_rewards.png"),
	sNoti: require("./homeAssets/s_noti.png"),
	sLessons: require("./homeAssets/s_lessons.png"),
	sMessages: require("./homeAssets/s_messages.png"),
	sReferral: require("./homeAssets/s_referral.png"),
	gift: require("./homeAssets/gift.png"),
	copy: require("./homeAssets/copy.png"),
	sProfile: require("./homeAssets/s_profile.png"),
};

export default assets;
