import { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { routes } from "src/Routes";

import DependantResources from "./DependantResources";

import { LuBrain } from "react-icons/lu";
import { useStateValue } from "src/context/StateProvider";
import CircularProgress from "src/components/Shared/CircularProgress";
import CircleLoader from "src/components/Shared/CircleLoader";

export default function ParentResources() {
  const location = useLocation();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [{ lessonResources }] = useStateValue();
  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  useEffect(() => {
    if (location.pathname.includes(routes.TUTOR_DASHBOARD_LEARN.url)) {
      setShowProfileMenu(true);
    } else {
      setShowProfileMenu(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        className={clsx("parent-menu-inactive", {
          "parent-menu-active": location.pathname.includes(
            routes.TUTOR_DASHBOARD_LEARN.url
          ),
        })}
        onClick={toggleProfileMenu}
      >
        <div className="flex gap-4">
          <div>
            <LuBrain className="text-lg" />
          </div>
          <div>{routes.TUTOR_DASHBOARD_LEARN.label}</div>
        </div>
        <div>
          <div
            className={clsx({
              hidden: !showProfileMenu,
              block: showProfileMenu,
            })}
          >
            <BiChevronUp size={23} />
          </div>
          <div
            className={clsx({
              block: !showProfileMenu,
              hidden: showProfileMenu,
            })}
          >
            <BiChevronDown size={23} />
          </div>
        </div>
      </div>
      {/* Sub Menu */}
      <div
        className={clsx({
          hidden: !showProfileMenu,
          "block bg-gray-50 rounded-lg py-4 mb-2": showProfileMenu,
        })}
      >
        {lessonResources ? (
          <ul>
            {Object.entries(lessonResources)?.map(
              ([curriculum, resource]: any) => (
                <li key={curriculum}>
                  <DependantResources
                    curriculum={curriculum}
                    resource={resource}
                  />
                </li>
              )
            )}
          </ul>
        ) : (
          <CircleLoader />
        )}
      </div>
    </>
  );
}
